import React, {useEffect, useState}  from "react";
import {
	muiRootStyle,
	textCompleteStyleBlack,
	BLUE_THEME,
	BORDER_BUTTON,
	RED_INFO,
	getCurrentDate
} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {useSelector} from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";


const FilterYear = (props) => {

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

	const [selectedDate, setSelectedDate] = useState();
	const currentMonthDate = moment(getCurrentDate()).toDate();
	
	useEffect(() => {
		if (props?.value) {
			setSelectedDate(props.value);
		} else if (props?.value === null) {
			setSelectedDate(null);
		} else {
			setSelectedDate(currentMonthDate);
		}
	}, [props?.value]);

	const getMonthYear = (newValue) => {
		let date =  selectedDate;
		if (newValue instanceof Date){
			date = newValue;
		}
		const formattedMes = moment(date).format("MM");
		const formattedAno = moment(date).format("YYYY");
		props.handleSubmit(formattedAno, formattedMes, props?.diaVencimento, props?.setState);
	};

	return (
		<Grid container sx={{display: "flex", alignItems: "center", width: props?.width}}>
			<Grid item xs={12}>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
					<DatePicker
						label={props?.label}
						views={["year", "month"]}
						openTo={"month"}
						value={selectedDate || props?.value}
						sx={border}
						onChange={(newValue) => {
							setSelectedDate(newValue);
							getMonthYear(newValue);
						}}
						slots={{
							openPickerIcon: CalendarTodayOutlinedIcon,
						}}
						slotProps={{
							inputAdornment: {position: "start"},
							textField: {
								size: "small",
								inputProps: {"data-testid": props?.testId},
								helperText: null,
								error: props?.inputError,
								sx: {
									...temaEscuro ? textCompleteStyleBlack : muiRootStyle,
									backgroundColor: (temaEscuro && !props.disabled) ? BLUE_THEME : undefined,
									"& .MuiInputLabel-root": {
										color: temaEscuro ? BORDER_BUTTON : undefined,
										fontSize: "15px"
									},
									"& .MuiFormLabel-asterisk": {
										color: props?.isRequired && RED_INFO
									}
								},
								style: {
									width: props?.width,
									...(!props?.disabled && {backgroundColor:temaEscuro && BLUE_THEME})
								},
								InputLabelProps: {
									style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" },
								},
								disabled: props?.disableText
							},
						}}
					/>
				</LocalizationProvider>
			</Grid>
		</Grid>
	);

};

export default React.memo(FilterYear);

const border ={
	"& .css-126wocv-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root fieldset": {
		border: "none"
	},
};