import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {useSelector} from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {BLACK_LABEL_UX, GRAY_LABEL_UX, WHITE_ESCRITA_THEME, BLUE_THEME,YELLOW_BG_UX,
	GRAY_BG_UX, BLACK_SUBITEM_NAVBAR, WHITE_SUBITEM_NAVBAR, BORDER_BLACK_THEME_CARD,} from "../../shared/utils.js";
import "./listItem.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StoreIcon from "@mui/icons-material/Store";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import HistoryIcon from "@mui/icons-material/History";
import NatIcon from "@mui/icons-material/Nat";
import MigracaoModal from "../Modal/MigracaoModal.js";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Groups3Icon from "@mui/icons-material/Groups3";
import InventoryIcon from "@mui/icons-material/Inventory";
import CategoryIcon from "@mui/icons-material/Category";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import { Badge, SvgIcon } from "@mui/material";


const ListItems = (props) => {
    
	const location = useLocation();
	const {open, className, activeButton, setActiveButton, handleChangeClick} = props;

	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const isOperator = useSelector(state => state.AuthReducer.isOperator);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const listCadastros = ["?type=centros_custo", "?type=clientes_fornecedores", "?type=produtos_servicos", "?type=categorias"];
	const listEXM = ["extrato_movimentacoes", "editar_recebimento", "editar_pagamento"];
	const listContas = ["contas", "nova_conta", "detalhamento_conta", "ignorados"];
	const listCertificados = ["certificados", "upload_certificado"];
	const listNotasCompras = ["notas_compras",];
	const indexTour = isAdmin ? 0 : 1;
    
    const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		const path = location.pathname.split("/")[1];
		const search = location.search;
		if (listCadastros.includes(search)) {
			props.setOpenSubCadastros(true);
		} else if (listNotasCompras.includes(path)) {
			props.setOpenSubCompras(true);
		} else if (listEXM.includes(path)) {
			props.setOpenSubFinanceiro(true);
		}
	}, []);

	useEffect(() => {
		let path = location.pathname.replace("/", "");
		let search = location.search;
		if (path === "perfil") {
			setActiveButton("perfil");
		}
		if (path === "boletos") {
			setActiveButton("boletos");
		}
		if (listCertificados.includes(path)) {
			setActiveButton("certificados");
		}
		if (listContas.includes(path)) {
			setActiveButton("contas");
		}
		if (listNotasCompras.includes(path)) {
			setActiveButton("notas_compras");
		}
		if (listEXM.includes(path)) {
			setActiveButton("extrato_movimentacoes");
		}
		if (listCadastros.includes(search)) {
			setActiveButton(search);
		}
	}, [location]);

	const styleOpen = () => {
		if (open) {
			return styles.ListItem;
		} else {
			return styles.ListItemAlt;
		}
	};

	const styleColor = (pathList) => {
		if(temaEscuro===true){
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : WHITE_ESCRITA_THEME};
		}else{
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : GRAY_LABEL_UX};
		}
	};

	const styleSelected = (pathList, isSubItem) => {
		if (isSubItem) {
			if(temaEscuro===true){
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : null};
			}else{
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : null};
			}

		} else {
			if(temaEscuro===true){
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : BLUE_THEME};
			}else{
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : GRAY_BG_UX};
			}
		}
	};

	const styleSelectedMigracao = (pathList) => {
		if(temaEscuro===true){
			return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : BORDER_BLACK_THEME_CARD};
		}else{
			return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : GRAY_BG_UX};
		}
	};

	const styleColorMigracao = (pathList) => {
		if(temaEscuro===true){
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : YELLOW_BG_UX};
		}else{
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : YELLOW_BG_UX};
		}
	};

	const handleClick = (local) => {
		if (local === "cadastros") {
			props.setOpenSubCadastros(!props.openSubCadastros);
		} else if (local === "compras") {
			props.setOpenSubCompras(!props.openSubCompras);
		} else if (local === "financeiro") {
			props.setOpenSubFinanceiro(!props.openSubFinanceiro);
		}
	};

	const handleChangeClickMigracao = () => {
		setOpenModal(true);
	};

	return (
		<React.Fragment>
			{
				(isOperator || isAdmin) && (
					<React.Fragment>
						<MigracaoModal
							openModal={openModal}
							setOpenModal={setOpenModal}
						/>
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelectedMigracao(["migracao", ""]),
								marginBottom: "30px"
							}}
							onClick={() => handleChangeClickMigracao()}
							className={className[0]}
						>
							<ListItemIcon>
								<NatIcon style={{...styleColorMigracao(["migracao", ""])}}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["migracao", ""])}
									>
										Migração
									</Typography>
								}
							/>
						</ListItemButton>
					</React.Fragment>
				)
			}
			<Link to="/dashboard" className="text-link" role="item">
				<ListItemButton
					style={{
						...styleOpen(),
						...styleSelected(["dashboard", ""]),
					}}
					onClick={() => handleChangeClick("dashboard")}
					className={className[0]}
				>
					<ListItemIcon>
						<HomeOutlinedIcon style={styleColor(["dashboard", ""])}/>
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography
								component="span"
								style={styleColor(["dashboard", ""])}
							>
								Resumo
							</Typography>
						}
					/>
				</ListItemButton>
			</Link>
			{/* {
				(isOperator || isApprover || isAdmin) && (
					<Link to={`/boletos?estabelecimento_id=${estabelecimentoId}`} className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["boletos"]),

							}}
							onClick={() => handleChangeClick("boletos")}
							className={className[1]}
						>
							<ListItemIcon>
								<AddCardIcon
									style={styleColor(["boletos"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["boletos"])}
									>
										Boletos
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				)
			} */}
			{
				(isOperator || isAdmin) && (
					<Link to={"certificados"} className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["certificados"]),

							}}
							onClick={() => handleChangeClick("certificados")}
							className={className[1]}
						>
							<ListItemIcon>
								<WorkspacePremiumIcon
									style={styleColor(["certificados"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["certificados"])}
									>
										Certificados
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				)
			}
			{/* {
				(isOperator || isAdmin) && (
					<Link to={"anexo_inteligente"} className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["anexo_inteligente"]),

							}}
							onClick={() => handleChangeClick("anexo_inteligente")}
							className={className[1]}
						>
							<ListItemIcon>
								<Badge 
									badgeContent={
										<SvgIcon viewBox="0 0 24 24" sx={{ fontSize: 20 }}>
											<g>
												<rect fill={BLUE_THEME} height="12" width="12" y="5" x="5" />
											</g>
											<g>
												<path
												d="M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3 c0,1.66,1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3C23,10.34,21.66,9,20,9z M7.5,11.5C7.5,10.67,8.17,10,9,10 s1.5,0.67,1.5,1.5S9.83,13,9,13S7.5,12.33,7.5,11.5z M16,17H8v-2h8V17z M15,13c-0.83,0-1.5-0.67-1.5-1.5S14.17,10,15,10 s1.5,0.67,1.5,1.5S15.83,13,15,13z"
												fill={BLUE_INFO_UX}
												/>
											</g>
										</SvgIcon>
									}
									overlap="circular"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
								>
									<InsertDriveFileIcon
										sx={styleColor(["anexo_inteligente"])}
									/>
								</Badge>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["anexo_inteligente"])}
									>
										Anexo Inteligente
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				)
			} */}
			{
				(isOperator || isAdmin) && (
					<Link to="/change_logs" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["change_logs", ""]),
							}}
							onClick={() => handleChangeClick("change_logs")}
							className={className[0]}
						>
							<ListItemIcon>
								<HistoryIcon style={styleColor(["change_logs", ""])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["change_logs", ""])}
									>
										Histórico
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				)
			}
			<ListItemButton onClick={() => handleClick("cadastros")} style={{color: YELLOW_BG_UX, marginTop: "15px"}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubCadastros ?  <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary="Cadastros" />)
				}
			</ListItemButton>
			<Collapse in={props.openSubCadastros} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
					<Link to="/cadastros?type=categorias" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["?type=categorias"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("?type=categorias")}
						>
							<ListItemIcon>
								<CategoryIcon
									style={styleColor(["?type=categorias"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["?type=categorias"])}
									>
										Categorias
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/cadastros?type=centros_custo" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["?type=centros_custo"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("?type=centros_custo")}
						>
							<ListItemIcon>
								<MonetizationOnIcon
									style={styleColor(["?type=centros_custo"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["?type=centros_custo"])}
									>
										Centros de Custo
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/cadastros?type=clientes_fornecedores" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["?type=clientes_fornecedores"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("?type=clientes_fornecedores")}
						>
							<ListItemIcon>
								<Groups3Icon
									style={styleColor(["?type=clientes_fornecedores"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["?type=clientes_fornecedores"])}
									>
										Cliente/Fornecedor
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/cadastros?type=produtos_servicos" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["?type=produtos_servicos"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("?type=produtos_servicos")}
						>
							<ListItemIcon>
								<InventoryIcon
									style={styleColor(["?type=produtos_servicos"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["?type=produtos_servicos"])}
									>
										Produtos/Serviços
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
			<ListItemButton onClick={() => handleClick("compras")} style={{color: YELLOW_BG_UX}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubCompras ?  <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary="Compras" />)
				}
			</ListItemButton>
			<Collapse in={props.openSubCompras} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
					<Link to="/notas_compras" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["notas_compras"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("notas_compras")}
							className={className[3 - indexTour]}
						>
							<ListItemIcon>
								<ListAltOutlinedIcon
									style={styleColor(["notas_compras"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["notas_compras"])}
									>
										Notas de Compras
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
			<ListItemButton onClick={() => handleClick("financeiro")} sx={{color: YELLOW_BG_UX}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubFinanceiro ? <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary={"Financeiro"} />)
				}
			</ListItemButton>
			<Collapse in={props.openSubFinanceiro} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
				<Link to="/extrato_movimentacoes" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["extrato_movimentacoes"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("extrato_movimentacoes")}
							className={className[9 - indexTour]}
						>
							<ListItemIcon>
								<AccountBalanceIcon
									style={styleColor(["extrato_movimentacoes"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["extrato_movimentacoes"])}
									>
										Extrato
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/contas" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["contas"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("contas")}
							className={className[10 - indexTour]}
						>
							<ListItemIcon>
								<StoreIcon
									style={styleColor(["contas"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["contas"])}
									>
										Contas
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
		</React.Fragment>
	);
};

const styles = {
	ListItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "10px 0px 10px 8px",
		width: "208px",
		height: "44px",
		borderRadius: "50px",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "10px",
	},

	ListItemAlt: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0px 10px 43px",
		gap: "12px",
		width: "40px",
		height: "40px",
		borderRadius: "8px",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "8px",
	},

	Administrativo: {
		width: "133px",
		height: "24px",
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: "500",
		fontSize: "16px",
		lineHeight: "24px",
		color: "#2D3748",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "20px",
	},
	divider: {
		marginLeft: "20px",
		marginRight: "20px",
		borderColor: "unset",
		my: 2
	},
	styleIcon: {
		color: BLACK_LABEL_UX
	},
};

export default ListItems;
