import React, { useState, useEffect } from "react";
import Header from "../../components/Titles/Header";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    GRAY_BG_UX,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    YELLOW_BG_UX,
    BLACK_TABLE,
    GRAY_LABEL_UX_THEME,
    BORDER_TABLE,
    WHITE_ESCRITA_THEME,
    BLACK_LABEL_UX,
    formatterCurrency,
    manualCurrencyFormatter,
    getCurrentDate,
    BORDER_BUTTON,
    isGroupVoxel
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "../../axios";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterEXM from "../../components/Filter/FilterEXM";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import MovimentacoesConta from "../../components/Tabs/MovimentacoesConta";
import ConciliacoesConta from "../../components/Tabs/ConciliacoesConta";
import BB from "./LogoBancos/BB.png";
import {showSnackMessage} from "../../actions/SnackActions";
import Skeleton from "@mui/material/Skeleton";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import DefaultButton from "../../components/Buttons/DefaultButton";
import MovimentacoesContaOther from "../../components/Tabs/MovimentacoesContaOther";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImportOFXModal from "../../components/Modal/ImportOFXModal";
import DetalhaCartaoCreditoKPI from "../../components/Dash/DetalhaCartaoCreditoKPI";
import CommonKPI from "../../components/Dash/CommonKPI";
import InformarPagamentoDrawer from "../../components/Drawers/InformarPagamentoDrawer";
import EditContaModal from "../../components/Modal/EditContaModal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


const DetalhaConta = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [periodo, setPeriodo] = useState("este_mes");
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [customPeriodo, setCustomPeriodo] = useState([null, null]);
    const currentMonthDate = moment(getCurrentDate()).format("YYYY-MM-DD");

    const [selectedContaId, setSelectedContaId] = useState(location?.state?.selectedConta || {label: "", value: 0});
    const [selectedConta, setSelectedConta] = useState(null);
   
    const [selectedContaTipo, setSelectedContaTipo] = useState(location?.state?.tipoContaSelected || null);

    const contaDetails = location?.state?.rowDetails || {};
    const tipoConta = location?.state?.tipoConta || null;
    const bancos = location?.state?.bancos || [];
    const modalidades = location?.state?.modalidades || [];
    const [cartaoEmissores, setCartaoEmissores] = useState(location?.state?.cartaoEmissores || []);
    const [contasPagamento, setContasPagamento] = useState(location?.state?.contasPagamento || []);
    const [bandeiras, setBandeiras] = useState(location?.state?.bandeiras || []);
    const contasCorrente = location?.state?.contasCorrente || [];

    const [contasOptions, setContasOptions] = useState([]);
    const [dataFechamento, setDataFechamento] = useState("");
    const [dataVencimento, setDataVencimento] = useState("");
    const [totalFatura, setTotalFatura] = useState(0);
    const [contaCorrenteVinculada, setContaCorrenteVinculada] = useState(null);
    const [saldoAtual, setSaldoAtual] = useState(0);
    const [valorPendente, setValorPendente] = useState(0);
    const [conciliacoesPendentes, setConciliacoesPendentes] = useState([]);
    const [mesConciliacoesPendentes, setMesConciliacoesPendentes] = useState([]);
    const [openModalOFX, setOpenModalOFX] = useState(false);
    const [openModalEditarConta, setOpenModalEditarConta] = useState(false);

    const [inputApelido, setInputApelido] = useState("");
    const [inputTipoConta, setInputTipoConta] = useState("");
    const [inputBanco, setInputBanco] = useState("");
    const [inputModalidade, setInputModalidade] = useState("");
    const [isContaPadrao, setIsContaPadrao] = useState(false);
    const [isBancoEdit, setIsBancoEdit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [inputSaldoInicial, setInputSaldoInicial] = useState(0.0);
    const [inputCartaoEmissor, setInputCartaoEmissor] = useState("");
    const [inputCartaoContaPagamento, setInputCartaoContaPagamento] = useState("");
    const [inputCartaoBandeira, setInputCartaoBandeira] = useState("");
    const [inputUltimosDigitos, setInputUltimosDigitos] = useState("");
    const [inputDiaVencimento, setInputDiaVencimento] = useState("");
    const [inputDiaFechamento, setInputDiaFechamento] = useState("");
    const [inputContaVinculada, setInputContaVinculada] = useState("");

    const [selectedTab, setSelectedTab] = useState("");
    const [bancoLogo, setBancoLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [conciliacoesPendentesWarning, setConciliacoesPendentesWarning] = useState(true);
    const [reload, setReload] = useState(false);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isLeader = useSelector(state => state.AuthReducer.isLeader);
    const isApprover = useSelector(state => state.AuthReducer.isApprover);

    useEffect(() => {
        if (!location.state || !estabelecimentoId) {
            navigate("/contas");
        }
    }, []);

    useEffect(() => {
        setSelectedTab(selectedContaTipo === "Conta Corrente" ? (isGroupVoxel([isAdmin, isOperator, isLeader, isApprover]) ? "conciliacoes" : "movimentacoes") : "movimentacoes");
    }, [selectedContaTipo]);

    useEffect(() => {
        if (estabelecimentoId !== 0 && periodo !== "custom_periodo" && selectedContaId) {
            getContas();
            getDetalhesConta();
            getValorPendente();
            if (isGroupVoxel([isAdmin, isOperator, isLeader, isApprover])) {
                getConciliacoesPendentes();
            }
        }
    }, [estabelecimentoId, arrowCounter, customPeriodo, periodo, selectedContaId]);

    useEffect(() => {
        if (selectedContaId !== null) {
            for (let conta of location.state.contas) {
                if (conta.id === selectedContaId.value) {
                    setSelectedConta(conta);
                }
            }
            if (selectedContaId.tipo) {
                setSelectedContaTipo(selectedContaId.tipo);
            }
            getSaldoAtual();
            if (isGroupVoxel([isAdmin, isOperator, isLeader, isApprover])) {
                getConciliacoesPendentes();
            }
        }
    }, [selectedContaId]);

    useEffect(() => {
        if (selectedConta) {
            if (selectedConta.banco_formatted === "BB") {
                setBancoLogo(BB);
            }
        }
    }, [selectedConta]);

    useEffect(() => {
        if (selectedContaId) {
            getDetalhesConta(true);
            getValorPendente();
            if (isGroupVoxel([isAdmin, isOperator, isLeader, isApprover])) {
                getConciliacoesPendentes();
            }
            getSaldoAtual();
        }
    }, [reload]);

    const handleOpenModalEditConta = (conta) => {
        setInputApelido(conta.apelido);
        setInputTipoConta(conta.tipo);
        setInputBanco(conta.banco_formatted);
        setInputModalidade(conta.modalidade);
        setIsContaPadrao(conta.conta_padrao);
        setIsBancoEdit(conta.is_banco_edit);
        setInputSaldoInicial(conta.saldo_inicial);
        if (conta.data_competencia) {
            setSelectedDate(conta.data_competencia);
        } else {
            setSelectedDate(currentMonthDate);
        }
    };

    const getContas = () => {
        api.RetrieveContas().then(response => {
            setContasOptions(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const getSaldoAtual = () => {
        let dataRequest = {
            conta_id: selectedContaId.value,
        };
        api.GetSaldoAtual(dataRequest).then(response => {
            setSaldoAtual(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const getValorPendente = () => {        
        let dataRequest = {
            conta_id: selectedContaId.value,
            periodo: periodo,
            plus_minus: arrowCounter,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetValorPendente(dataRequest).then(response => {
            setValorPendente(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const getConciliacoesPendentes = () => {        
        let dataRequest = {
            conta_id: selectedContaId.value,
            periodo: periodo,
            plus_minus: arrowCounter,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetConciliacoesPendentes(dataRequest).then(response => {
            setConciliacoesPendentes(response.data.pendencias);
            setMesConciliacoesPendentes(response.data.ultima_data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };


    const getDetalhesConta = ( isRefresh = false ) => {
        if (!selectedContaId) {
            throwDispatchErrorMessage();
        }
        if(!isRefresh){
            setLoading(true);
        }
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: selectedContaId.value,
            periodo: periodo,
            plus_minus: arrowCounter,
        };
        if (arrowCounter !== 0) {
            dataRequest.plus_minus = arrowCounter;
        }
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetDetalharConta(dataRequest).then(response => {
            let data = response.data;

            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            if (selectedContaTipo === "Cartão de Crédito"){
                setInputApelido(data?.cartao_apelido);
                setSaldoAtual(data.saldo_anterior);
                setInputCartaoContaPagamento(data?.cartao_conta_pagamento);
                setInputCartaoBandeira(data?.cartao_bandeira);
                setInputUltimosDigitos(data?.cartao_ultimos_digitos);
                setInputDiaFechamento(data?.cartao_dia_fechamento);
                setInputDiaVencimento(data?.cartao_dia_vencimento);
                setInputCartaoEmissor(data?.cartao_emissor);
                setBandeiras(data?.bandeiras);
                setContasPagamento(data?.cartao_contas_pagamento);
                setCartaoEmissores(data?.cartao_emissores);
            }
            if (data?.data_fechamento) {
                setDataFechamento(data.data_fechamento);
                setDataVencimento(data.data_vencimento);
                setTotalFatura(data.total_fatura);
                if (data?.cc_vinculada) {
                    setContaCorrenteVinculada(data.cc_vinculada);
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const handleContaPadrao = () => {
        const dataRequest = {
            conta_id: selectedContaId.value,
            estabelecimento_id: estabelecimentoId,
        };
        api.PatchContaPadrao(dataRequest).then(() => {
            dispatch(showSnackMessage({ message: "Esta agora é a sua conta padrão!", severity: "success" }));
        }).catch((error) => {
            dispatch(showSnackMessage({ message: error ? error.response.data : "Algo deu errado! Tente novamente mais tarde", severity: error ? "info" : "error" }));
        });
    };

    const editConta = (tipo) => {
        setOpenModalEditarConta(false);
        setLoading(true);
    
        const formatDate = (date) => date instanceof Date ? moment(date).format("DD-MM-YYYY") : date;
    
        const dataRequest = {
            conta_id: contaDetails.id,
            estabelecimento_id: estabelecimentoId,
            apelido: inputApelido,
            conta_tipo: inputTipoConta,
        };
    
        switch (tipo) {
            case "Conta Corrente":
                Object.assign(dataRequest, {
                    banco_nome: inputBanco,
                    modalidade: inputModalidade,
                    conta_padrao: isContaPadrao,
                    data_competencia: formatDate(selectedDate),
                    saldo_inicial: inputSaldoInicial || 0.0,
                });
                break;
            case "Caixinha":
                Object.assign(dataRequest, {
                    data_competencia: formatDate(selectedDate),
                    saldo_inicial: inputSaldoInicial || 0.0,
                });
                break;
            case "Cartão de Crédito":
                Object.assign(dataRequest, {
                    apelido: inputApelido,
                    cartao_emissor: inputCartaoEmissor,
                    conta_pagamento: inputCartaoContaPagamento.value,
                    bandeira: inputCartaoBandeira.value,
                    ultimos_digitos: inputUltimosDigitos,
                    dia_vencimento: inputDiaVencimento,
                    dia_fechamento: inputDiaFechamento,
                });
                break;
            case "Poupança":
            case "Investimento":
            case "Aplicação Automática":
                Object.assign(dataRequest, {
                    data_competencia: formatDate(selectedDate),
                    saldo_inicial: inputSaldoInicial || 0.0,
                    conta_vinculada: inputContaVinculada,
                    modalidade: inputModalidade,
                    banco_nome: inputBanco,
                });
                break;
            case "Outro tipo de conta":
                Object.assign(dataRequest, {
                    data_competencia: formatDate(selectedDate),
                    saldo_inicial: inputSaldoInicial || 0.0,
                });
                break;
        }
    
        api.EditConta(dataRequest)
            .then(() => {
                setLoading(false);
                dispatch(showSnackMessage({ message: "Conta alterada com sucesso!", severity: "success" }));
                navigate("/contas");
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    dispatch(showSnackMessage({ message: error.response.data, severity: "error" }));
                } else {
                    dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
                }
                setLoading(false);
            });
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const throwDispatchErrorMessage = () => {
        dispatch(showSnackMessage({ message: "Por favor selecione uma conta para realizar esta ação!", severity: "error" }));
    };

    const handleOpenEditarModal = () => {
        setOpenModalEditarConta(true);
        handleOpenModalEditConta(contaDetails);
    };

    const handleDayMonthYear = (ano, mes, dia) => {
        setSelectedDate(`${ano}-${mes}-${dia}`);
    };

    return (
        <React.Fragment>
            <ImportOFXModal
                reload={reload}
                setReload={setReload}
                openModalOFX={openModalOFX}
                setOpenModalOFX={setOpenModalOFX}
                selectedContaId={selectedContaId}
            />
            <EditContaModal 
                openEditModal={openModalEditarConta} 
                setOpenEditModal={setOpenModalEditarConta}
                editConta={editConta}
                contaDetails={contaDetails}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
                tipoConta={tipoConta}
                setInputTipoConta={setInputTipoConta}
                inputTipoConta={inputTipoConta}
                bancos={bancos}
                setInputBanco={setInputBanco}
                inputBanco={inputBanco}
                setInputModalidade={setInputModalidade}
                inputModalidade={inputModalidade}
                modalidades={modalidades}
                isContaPadrao={isContaPadrao}
                setIsContaPadrao={setIsContaPadrao}
                isBancoEdit={isBancoEdit}
                handleDayMonthYear={handleDayMonthYear}
                setInputSaldoInicial={setInputSaldoInicial}
                inputSaldoInicial={inputSaldoInicial}
                inputCartaoEmissor={inputCartaoEmissor}
                setInputCartaoEmissor={setInputCartaoEmissor}
                cartaoEmissores={cartaoEmissores}
                inputCartaoContaPagamento={inputCartaoContaPagamento}
                setInputCartaoContaPagamento={setInputCartaoContaPagamento}
                contasPagamento={contasPagamento}
                inputCartaoBandeira={inputCartaoBandeira}
                setInputCartaoBandeira={setInputCartaoBandeira}
                bandeiras={bandeiras}
                setInputUltimosDigitos={setInputUltimosDigitos}
                inputUltimosDigitos={inputUltimosDigitos}
                setInputDiaFechamento={setInputDiaFechamento}
                setInputDiaVencimento={setInputDiaVencimento}
                inputDiaFechamento={inputDiaFechamento}
                inputDiaVencimento={inputDiaVencimento}
                selectedDate={selectedDate}
                setInputContaVinculada={setInputContaVinculada}
                inputContaVinculada={inputContaVinculada}
                contasCorrente={contasCorrente}
            />
            <div className="main" data-testid="detalhe-conta-container">
                <Header title="Detalhamento" subTitle="Contas" previousPage="/contas" />
                <Paper sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}>
                    {
                        loading ? (
                            [...Array(15).keys()].map((i,d) =>{
                                return(
                                    <Skeleton key={d} height={50}/>
                                );
                            })
                        ) : (
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FilterSingleOption
                                                    placeholder={"Conta"}
                                                    filterOptions={contasOptions}
                                                    selectedOption={selectedContaId}
                                                    setSelectedOption={setSelectedContaId}
                                                    width={"25vw"}
                                                    testId="filter-conta"
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                                                <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                                    <Grid item>
                                                        <FilterEXM
                                                            disableTodoPeriodo
                                                            placeholders={filterPlaceholders}
                                                            periodo={periodo}
                                                            setPeriodo={setPeriodo}
                                                            specificDate={specificDate}
                                                            setSpecificDate={setSpecificDate}
                                                            arrowCounter={arrowCounter}
                                                            setArrowCounter={setArrowCounter}
                                                            callFunction={getDetalhesConta}
                                                        />
                                                        {
                                                            periodo === "custom_periodo" && (
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DateRangePicker
                                                                        value={customPeriodo}
                                                                        onChange={handleDateChange}
                                                                        localeText={{start: "Início", end: "Fim"}}
                                                                        format="DD/MM/YYYY"
                                                                        sx={{
                                                                            width: "330px",
                                                                            marginTop: 2,
                                                                            "& .MuiInputLabel-root": {color: temaEscuro && BORDER_BUTTON}
                                                                        }}
                                                                        slotProps={{
                                                                            textField: {
                                                                                size: "small",
                                                                                sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                                            }
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            )
                                                        }
                                                    </Grid>
                                                    {
                                                        isGroupVoxel([isAdmin, isOperator, isLeader, isApprover]) && (
                                                            <React.Fragment>
                                                                <Grid item>
                                                                    <DefaultButton
                                                                        title="Tornar conta padrão"
                                                                        onClick={selectedContaId ? handleContaPadrao : throwDispatchErrorMessage}
                                                                        testId="btn-conta-padrao"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <DefaultButton
                                                                        startIcon={<UploadFileIcon />}
                                                                        onClick={selectedContaId ? () => setOpenModalOFX(true) : throwDispatchErrorMessage}
                                                                        title="Importar OFX"
                                                                        testId="btn-ofx"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <DefaultButton
                                                                        startIcon={<UploadFileIcon />}
                                                                        // onClick={selectedContaId ? () => setOpenModalEditarConta(true) : throwDispatchErrorMessage}
                                                                        onClick={selectedContaId ? () => handleOpenEditarModal() : throwDispatchErrorMessage}
                                                                        title="Editar conta"
                                                                        testId="btn-editar-conta"
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {selectedContaTipo !== "Cartão de Crédito" ? (
                                        <Grid item xs={12}>
                                            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center"}}>
                                                <Tabs
                                                    value={selectedTab}
                                                    onChange={handleChange}
                                                    TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                                    className="buttonTabs"
                                                >
                                                    {
                                                        selectedContaTipo === "Conta Corrente" && isGroupVoxel([isAdmin, isOperator, isLeader, isApprover]) && (
                                                            <Tab
                                                                value={"conciliacoes"}
                                                                label="Conciliações pendentes"
                                                                sx={{
                                                                    borderRadius: "20px",
                                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                                    backgroundColor: selectedTab === "conciliacoes" ? YELLOW_BG_UX : "transparent",
                                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                        color: BLACK_LABEL_UX,
                                                                    },
                                                                }}
                                                                data-testid="tab-conciliacoes"
                                                            />
                                                        )
                                                    }
                                                    <Tab
                                                        value={"movimentacoes"}
                                                        label="Movimentações"
                                                        sx={{
                                                            borderRadius: "20px",
                                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                            backgroundColor: selectedTab === "movimentacoes" ? YELLOW_BG_UX : "transparent",
                                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                                color: BLACK_LABEL_UX,
                                                            },
                                                        }}
                                                        data-testid="tab-movimentacoes"
                                                    />
                                                </Tabs>
                                                {
                                                    selectedContaTipo === "Conta Corrente" && (
                                                        <div style={{display: "flex", gap: "1rem"}} data-testid="div-saldo-valor">
                                                            <div>
                                                                <p style={{ fontSize: "14px" }}>
                                                                    Saldo atual no VXMF
                                                                </p>
                                                                <p style={{fontSize: "14px", fontWeight: "bold", direction: "rtl", textAlign: "justify"}}>
                                                                    {manualCurrencyFormatter(saldoAtual, 2)}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={{ fontSize: "14px" }}>
                                                                    Valor pendente de conciliação
                                                                </p>
                                                                <p style={{fontSize: "14px", fontWeight: "bold", direction: "rtl", textAlign: "justify"}}>
                                                                    {manualCurrencyFormatter(valorPendente, 2)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            {
                                                isGroupVoxel([isAdmin, isOperator, isLeader, isApprover]) && (
                                                    <Grid container>
                                                        <Grid item>
                                                            <InformarPagamentoDrawer
                                                                contas={contasOptions}
                                                                selectedContaId={selectedContaId}
                                                                contaCorrenteVinculada={contaCorrenteVinculada}
                                                                dataVencimento={dataVencimento}
                                                                valorFatura={totalFatura}
                                                                setLoading={setLoading}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    )}
                                    {selectedContaTipo === "Conta Corrente" &&
                                    conciliacoesPendentes > 0 &&
                                    conciliacoesPendentesWarning && (
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    border: `solid 1px ${YELLOW_BG_UX}`,
                                                    borderLeft: `7px solid ${YELLOW_BG_UX}`,
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <ErrorIcon
                                                        sx={{
                                                            color: YELLOW_BG_UX,
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                    <div>
                                                        Existe(m){" "}
                                                        <span
                                                            style={{
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {conciliacoesPendentes}{" "}
                                                            lançamento(s) bancário(s)
                                                        </span>{" "}
                                                        não conciliado(s) até{" "}
                                                        {mesConciliacoesPendentes}.
                                                    </div>
                                                </div>
                                                <div>
                                                    <CloseIcon
                                                        onClick={() =>
                                                            setConciliacoesPendentesWarning(
                                                                false
                                                            )
                                                        }
                                                        fontSize="small"
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sx={{
                                        border: 0,
                                        borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                        mb: 2.5,
                                        ml: 2
                                    }}/>
                                </Grid>
                                {selectedContaTipo !== "Cartão de Crédito" ? (
                                    <React.Fragment>
                                        {selectedTab === "movimentacoes" && (
                                            (
                                                (selectedContaTipo !== "Conta Corrente" && selectedContaId?.tipo !== "Conta Corrente") ? (
                                                    <MovimentacoesContaOther
                                                        conta={selectedContaId}
                                                        periodo={periodo}
                                                        arrowCounter={arrowCounter}
                                                        customPeriodo={customPeriodo}
                                                        selectedContaTipo={selectedContaTipo}
                                                        apelidoConta={selectedContaId?.label}
                                                        saldoAtual={saldoAtual}
                                                        setTotalFatura={setTotalFatura}
                                                        throwDispatchErrorMessage={throwDispatchErrorMessage}

                                                    />
                                                ) : (
                                                    <MovimentacoesConta
                                                        reload={reload}
                                                        conta={selectedContaId}
                                                        periodo={periodo}
                                                        arrowCounter={arrowCounter}
                                                        customPeriodo={customPeriodo}
                                                        selectedContaTipo={selectedContaTipo}
                                                        bancoLogo={bancoLogo}
                                                        apelidoConta={selectedConta?.apelido}
                                                        saldoConta={saldoAtual}
                                                        setTotalFatura={setTotalFatura}
                                                        isContaCorrente={selectedContaTipo==="Conta Corrente"}
                                                        throwDispatchErrorMessage={throwDispatchErrorMessage}
                                                        getValorPendente={getValorPendente}
                                                        getSaldoAtual={getSaldoAtual}
                                                    />
                                                )
                                            )
                                        )}
                                        {selectedTab === "conciliacoes" && (
                                            <ConciliacoesConta
                                                reload={reload}
                                                bancoLogo={bancoLogo}
                                                conta={selectedContaId}
                                                periodo={periodo}
                                                arrowCounter={arrowCounter}
                                                customPeriodo={customPeriodo}
                                                getValorPendente={getValorPendente}
                                                getSaldoAtual={getSaldoAtual}
                                                getDetalhesConta={getDetalhesConta}
                                                throwDispatchErrorMessage={throwDispatchErrorMessage}
                                            />
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={4}>
                                                    <DetalhaCartaoCreditoKPI
                                                        saldoValor={manualCurrencyFormatter(saldoAtual, 2)}
                                                        dataFechamento={dataFechamento}
                                                        testId="detalha-cartao-credito-kpi"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CommonKPI
                                                        title={"Vencimento"}
                                                        data={dataVencimento}
                                                        testId="detalha-cartao-credito-kpi"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CommonKPI
                                                        title={"Valor da fatura"}
                                                        data={formatterCurrency(totalFatura, 2)}
                                                        testId="detalha-cartao-credito-kpi"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MovimentacoesConta
                                                reload={reload}
                                                conta={selectedContaId}
                                                periodo={periodo}
                                                arrowCounter={arrowCounter}
                                                customPeriodo={customPeriodo}
                                                selectedContaTipo={selectedContaTipo}
                                                bancoLogo={bancoLogo}
                                                apelidoConta={selectedConta?.apelido}
                                                saldoConta={saldoAtual}
                                                setTotalFatura={setTotalFatura}
                                                isCartaoCredito={selectedContaTipo==="Cartão de Crédito"}
                                                isContaCorrente={selectedContaTipo==="Conta Corrente"}
                                                throwDispatchErrorMessage={throwDispatchErrorMessage}
                                                getValorPendente={getValorPendente}
                                                getSaldoAtual={getSaldoAtual}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        )
                    }
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default DetalhaConta;