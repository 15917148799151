import React, { useState } from "react";
import {styleText, smallPaperStyle, GRAY_LABEL_UX} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_THEME_BLACK, BORDER_TABLE, BORDER_PAPER} from "../../shared/utils";
import ModalTitle from "../Titles/ModalTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { Divider, FormControlLabel, Radio } from "@mui/material";
import DefaultButton from "../Buttons/DefaultButton";
import api from "../../axios";


const EditLancamentoRecorrenteModal = ({ openModal, setOpenModal, setAction, lancamento, recorrenciaAction, selectedChoice, setSelectedChoice, setSelectedLancamentoRecorrente, type, setType, tab, getExtratoMovimentacoes }) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [loading, setLoading] = useState(false);

    const handleDeleteRecorrencia = () => {
        setLoading(true);
        
        const dataRequest = {
            lancamento_id: lancamento.id,
            movimentacao_id: lancamento.movimentacao_id,
            tipo: selectedChoice,
            tab: tab,
        };
        
        api.DeleteLancamentoRecorrente(dataRequest).then(() => {
            setLoading(false);
            setOpenModal(false);
            setType(null);
            getExtratoMovimentacoes();
        }).catch(() => {
            setLoading(false);
        });
    };

    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => {
                        setOpenModal(false);
                        setAction(null);
                        setSelectedChoice(null);
                        setType(null);
                    }
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{...smallPaperStyle, minWidth: "45vw", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                    <ModalTitle title={type === "edit" ? "Editar Lançamento Recorrente" : "Excluir Lançamento Recorrente"} setOpenModal={setOpenModal} />
                    <Grid container sx={{marginTop: 2}}>
                        <Grid item xs={12} sx={{".MuiTypography-root": {
                            fontSize: "14px",
                        }}}>
                            <FormControl variant="standard">
                                <FormLabel sx={{...styleText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, mb: 2}}>
                                    {
                                        type === "edit" ? "As alterações neste lançamento devem ser refletidas:" : "Ao confirmar esta ação, quais lançamentos você deseja excluir?"
                                    }
                                </FormLabel>
                                <RadioGroup
                                    name="recorrencia"
                                    value={selectedChoice}
                                    onChange={(e) => setSelectedChoice(e.target.value)}
                                >
                                <FormControlLabel value="unico" control={<Radio />} label="Somente o lançamento atual" />
                                <FormControlLabel value="nao-vencidos" control={<Radio />} label="Lançamento atual e todos os não vencidos que não possuam baixas" />
                                <FormControlLabel value="em-aberto" control={<Radio />} label="Lançamento atual e todos os em aberto (vencidos + a vencer) que não possuam baixas" />
                                <FormControlLabel value="todos" control={<Radio />} label="Todos os lançamentos" />
                                </RadioGroup>
                            </FormControl>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, marginTop: 3, marginBottom: 2}}/>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                            <DefaultButton
                                title={"Cancelar"}
                                onClick={() => {
                                    setOpenModal(false);
                                    setSelectedChoice(null);
                                }
                            }
                            />
                            <DefaultButton
                                disabled={!selectedChoice}
                                loading={loading}
                                title={"Confirmar"}
                                onClick={
                                    () => {
                                        if (type === "edit") {
                                            setSelectedLancamentoRecorrente(lancamento);
                                            recorrenciaAction(true);
                                            setOpenModal(false);
                                        }
                                        else if (type === "delete") {
                                            handleDeleteRecorrencia();
                                        }
                                    }
                                }
                                variant="yellow"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default EditLancamentoRecorrenteModal;