import React, { useEffect, useState } from "react";
import {
    Checkbox,
    Divider,
    Grid,
    Paper,
    Tab,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TableBody,
    TextField, 
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    useMediaQuery,
    Slide} from "@mui/material";
import {
    BLACK_LABEL_UX,
    BLACK_TABLE,
    BLUE_LIGHT_UX_THEME,
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    DASH_KPI_DATA_COLOR,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    styleText,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    format, 
    checkSituacao,
    formatterCurrency,
    GRAY_PDF,
    paperDash,
    dashLeftTitle,
    DASH_KPI_NEGATIVE_COLOR,
    BLUE_INFO_UX,
    DASH_KPI_COLOR,
    getCurrentDate,
    formaPagamentoOptions,
    GREEN_SUCCESS_UX,
    BLACK_TABLE_PERFIL,
    GRAY_STRONG_UX,
    TITLE_SIZE,
    handleDayMonthYear} from "../../../shared/utils";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../axios";
import InfoIcon from "@mui/icons-material/Info";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import InputSearch from "../../../components/Input/InputSearch";
import InputCurrency from "../../../components/Input/InputCurrency";
import Anexos from "../../../components/Tabs/Anexos";
import SaveOptions from "../../../components/Buttons/SaveOptions";
import CreateOptionModal from "../../../components/Modal/CreateOptionModal";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { showSnackMessage } from "../../../actions/SnackActions";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import RateiosTab from "../../../components/Tabs/RateiosTab";
import Decimal from "decimal.js";
import FilterYear from "../../../components/Filter/FilterYear";
import RecorrenciaConfirmationModal from "../../../components/Modal/RecorrenciaConfirmationModal";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditarLancamentoRecorrente = ({ open, setOpen, tab, lancamentoSelected, selectedChoice, setSelectedLancamentoRecorrente, getExtratoMovimentacoes }) => {
    const dispatch = useDispatch();

    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isAprover);

    const [openWarning, setOpenWarning] = useState(true);
    const [lancamento, setLancamento] = useState(lancamentoSelected);
    const [fornecedores, setFornecedores] = useState([]);
    const [dataCompetencia, setDataCompetencia] = useState("");
    const [descricao, setDescricao] = useState("");
    const [hasRateio, setHasRateio] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [centroCusto, setCentroCusto] = useState([]);
    const [dataVencimento, setDataVencimento] = useState();
    const [contas, setContas] = useState([]);
    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [baixasAnteriores, setBaixasAnteriores] = useState([]);
    const [totalPagoAnterior, setTotalPagoAnterior] = useState(0);
    const [valor, setValor] = useState(0);
    const [situacao, setSituacao] = useState(lancamentoSelected?.situacao);
    
    const [valorEmAberto, setValorEmAberto] = useState(0);
    const [valorPago, setValorPago] = useState(0);

    const [rateios, setRateios] = useState([{
        categoria: {"label": null, "value": null},
        valor: 0,
        percentual: 0,
        centro_custo: null,
    }]);

    const [totalPago, setTotalPago] = useState(0);
    const [saldoEmAberto, setSaldoEmAberto] = useState(0);

    const [selectedFornecedor, setSelectedFornecedor] = useState({});
    const [selectedCategoria, setSelectedCategoria] = useState({});
    const [selectedCentroCusto, setSelectedCentroCusto] = useState({});
    const [selectedConta, setSelectedConta] = useState({});
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState("");
    const [hasAgendado, setHasAgendado] = useState(false);
    const [hasPago, setHasPago] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [loadingAnexos, setLoadingAnexos] = useState(true);

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");

    const [selectedTab, setSelectedTab] = useState("observacoes");

    const [selectedPrevisaoPagamento, setSelectedPrevisaoPagamento] = useState(moment(getCurrentDate()).toDate());

    const [desconto, setDesconto] = useState(0);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [totalRateios, setTotalRateios] = useState(0);
    const [diferencaRateios, setDiferencaRateios] = useState(0);

    const [loading, setLoading] = useState(false);
    const viewOnly = !(isAdmin || isOperator || isAprover);

    const [originalFields, setOriginalFields] = useState({
        "Cliente/Fornecedor": "",
        "Data de competência": "",
        "Descrição": "",
        "Valor": "",
        "Rateio": "",
        "Categoria": "",
        "Centro de custo": "",
        "Vencimento": "",
        "Forma de pagamento": "",
        "Conta de pagamento": "",
        "Agendado": "",
        "Observações": "",
    });
    const [updatedFields, setUpdatedFields] = useState([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    useEffect(() => {
        if (selectedTab === "anexo") {
            getAnexos();
        }
    }, [selectedTab]);

    useEffect(() => {
        if (open === false) {
            clearFields();
        }
    }, [open]);

    useEffect(() => {
        const novoTotalRateios = rateios.reduce((acc, rateio) => acc + Math.abs(rateio.valor || 0), 0);
        const novaDiferencaRateios = new Decimal(valor).minus(novoTotalRateios).toDecimalPlaces(2).toNumber();

        setTotalRateios(novoTotalRateios);
        setDiferencaRateios(novaDiferencaRateios);
    }, [rateios, valor]);

    const clearFields = () => {
        setLancamento(null);
        setFornecedores([]);
        setDataCompetencia("");
        setDescricao("");
        setHasRateio(false);
        setCategorias([]);
        setCentroCusto("");
        setDataVencimento("");
        setContas([]);
        setObservacoes("");
        setAnexos([
            {
                forma_anexo: "Arquivo",
                arquivo: null,
                tipo_anexo: "Contrato",
                descricao: "",
            },
        ]);
        setValor(0);
        setSituacao("");
        setValorEmAberto(0);
        setValorPago(0);
        setRateios([{
            categoria: {"label": null, "value": null},
            valor: 0,
            percentual: 0,
        }]);
        setTotalPago(0);
        setSaldoEmAberto(0);
        setSelectedFornecedor("");
        setSelectedCategoria("");
        setSelectedCentroCusto("");
        setSelectedConta("");
        setSelectedFormaPagamento("");
        setHasAgendado(false);
        setHasPago(false);
        setInputError(false);
        setLoadingAnexos(true);
        setSelectedTab("observacoes");
        setSelectedPrevisaoPagamento(moment(getCurrentDate()).toDate());
        setDesconto(0);
        setJuros(0);
        setMulta(0);
    };

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            lancamento_id: lancamento.id,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        }).catch(() => {
            setLoadingAnexos(false);
            dispatch(showSnackMessage({message: "Erro ao buscar anexos", severity: "error"}));
        });
    };
    
    useEffect(() => {
        if (lancamentoSelected !== null && open === true) {
            getLancamentoData();
        }
    }, [lancamentoSelected, open]);

    useEffect(() => {
        if (valorPago <= valor) {
            setValorEmAberto(valor - valorPago);
        }
    }, [valorPago]);

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);
    
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
    
            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });
    
            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
            
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const editLancamento = () => {
        setLoading(true);

        if (dataCompetencia === "" || descricao === "" || valor === 0 || selectedCategoria === "" || selectedConta === "") {
            setInputError(true);
            return;
        }

        let formData = new FormData();

        formData.append("tipo_edicao", selectedChoice);
        formData.append("lancamento_id", lancamentoSelected?.id);
        formData.append("movimentacao_id", lancamento?.id);
        formData.append("situacao", situacao);
        formData.append("estabelecimento_id", estabelecimentoId);
        if (selectedFornecedor)
            formData.append("fornecedor", selectedFornecedor.value);
        formData.append("data_competencia", moment(dataCompetencia).format("DD/MM/YYYY"));
        formData.append("descricao", descricao);
        formData.append("valor", valor);
        formData.append("categoria", selectedCategoria.value);
        if (selectedCentroCusto)
            formData.append("centro_custo", selectedCentroCusto.value);
        formData.append("data_vencimento", moment(dataVencimento).format("DD/MM/YYYY"));
        formData.append("forma_pagamento", selectedFormaPagamento);
        formData.append("conta_pagamento", selectedConta.value);
        formData.append("observacoes", observacoes);
        formData.append("anexos", JSON.stringify(anexos));
        formData.append("agendado", hasAgendado ? hasAgendado : false);
        formData.append("rateio", hasRateio);
        formData.append("valor_pago", valorPago);
        formData.append("pago", hasPago);
        formData.append("valor_em_aberto", new Decimal(valor).minus(totalPagoAnterior).minus(valorPago).toDecimalPlaces(2).toNumber());
        formData.append("codigo_referencia", lancamento?.codigo_referencia);
        formData.append("tipo_movimentacao", lancamento?.tipo_movimentacao);
        formData.append("total_pago", totalPago);
        formData.append("saldo_em_aberto", saldoEmAberto);
        formData.append("state", tab);

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        if (!hasRateio) {
            formData.append("rateios", JSON.stringify([{
                categoria: {"label": selectedCategoria.label, "value": selectedCategoria.value},
                valor: valor,
                percentual: 100,
                centro_custo: null
            }]));
        } else {
            formData.append("rateios", JSON.stringify(rateios));
        }

        if (selectedChoice === "unico") {
            formData.append("data_pagamento", moment(selectedPrevisaoPagamento).format("DD/MM/YYYY"));
            formData.append("desconto", desconto);
            formData.append("juros", juros);
            formData.append("multa", multa);
        }

        api.UpdateLancamentoRecorrente(formData).then(() => {
            setOpen(false);
            setSelectedLancamentoRecorrente(null);
            dispatch(showSnackMessage({message: "Lançamento(s) editado(s) com sucesso", severity: "success"}));
            getExtratoMovimentacoes();
            setLoading(false);
            clearFields();
        }).catch((e) => {
            setLoading(false);
            
            if (e.response.data) {
                dispatch(showSnackMessage({message: e.response.data, severity: "error"}));
            }
            else {
                dispatch(showSnackMessage({message: "Erro ao editar lançamento", severity: "error"}));
            }
        });
    };
        
    const getLancamentoData = () => {
        if (estabelecimentoId !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                lancamento_id: lancamentoSelected.id,
                state: tab,
            };

            if (tab === "visao_competencia") {
                dataRequest["condicao_pagamento"] = lancamentoSelected.condicao_pagamento;
            }

            api.GetInfoMovimentacao(dataRequest).then((response) => {
                let originalFieldsAux = {...originalFields};

                let responseData = response.data;

                if (tab === "movimentacoes") {
                    setTotalPago(responseData.valor_pago);
                    setDesconto(responseData.desconto);
                    setJuros(responseData.juros);
                    setMulta(responseData.multa);
                    setSaldoEmAberto(responseData.total_pagar);
                    setFornecedores(responseData.fornecedores);
                    setCategorias(responseData.categorias);
                    setCentroCusto(responseData.centros_custos);
                    setContas(responseData.contas);
                }
                else {
                    setTotalPago(responseData.valor_parcela_pago);
                    setDesconto(responseData.valor_parcela_desconto);
                    setJuros(responseData.valor_parcela_juros);
                    setMulta(responseData.valor_parcela_multa);
                    setSaldoEmAberto(responseData.valor_parcela_aberto);
                    setFornecedores(responseData.inputs_options.input_fornecedor_cliente);
                    setCategorias(responseData.inputs_options.input_categoria);
                    setCentroCusto(responseData.inputs_options.input_centro_custo);
                    setContas(responseData.inputs_options.input_contas);
                }

                if (tab === "visao_competencia") {
                    setSelectedConta(responseData.conta);
                    setSelectedFornecedor(responseData?.fornecedor_cliente);
                    setSelectedCategoria(responseData.categoria);
                    originalFieldsAux["Conta de pagamento"] = responseData.conta;
                    originalFieldsAux["Cliente/Fornecedor"] = responseData?.fornecedor_cliente;
                    originalFieldsAux["Categoria"] = responseData.categoria;
                }
                else if (tab === "contas_pagar" || tab === "contas_receber") {
                    setTotalPagoAnterior(responseData.valor_parcela_pago);
                    setBaixasAnteriores(responseData.detalhe_contas_pagar.filter((baixa) => !baixa.previsao));
                    setSelectedConta({
                        "label": responseData.conta?.apelido,
                        "value": responseData.conta?.id,
                        "conta_tipo": responseData.conta?.conta_tipo,
                        "dia_vencimento_cartao": responseData.conta?.dia_vencimento_cartao,
                    });
                    setSelectedFornecedor(responseData?.fornecedor_cliente);
                    setSelectedCategoria(responseData.categoria);
                    originalFieldsAux["Conta de pagamento"] = responseData.conta;
                    originalFieldsAux["Cliente/Fornecedor"] = responseData?.fornecedor_cliente;
                    originalFieldsAux["Categoria"] = responseData.categoria;
                }
                else {
                    setTotalPagoAnterior(responseData.valor_pago);
                    setBaixasAnteriores(
                        responseData.baixas.map((baixa) => {
                            if (!baixa.previsao) {
                                return {
                                    forma_pagamento_formatted: baixa.forma_pagamento,
                                    formatted_data_movimentacao: baixa.data_pagamento_aux,
                                    conta__apelido: baixa.conta_aux,
                                    valor: baixa.valor,
                                    juros: baixa.juros,
                                    multa: baixa.multa,
                                    desconto: baixa.desconto,
                                };
                            }
                        })
                    );
                    setSelectedConta(responseData.conta);
                    setSelectedFornecedor(responseData?.fornecedor);
                    setSelectedCategoria(responseData.categoria);
                    originalFieldsAux["Conta de pagamento"] = responseData.conta;
                    originalFieldsAux["Cliente/Fornecedor"] = responseData?.fornecedor;
                    originalFieldsAux["Categoria"] = responseData.categoria;
                }
                
                setDataCompetencia(moment(responseData.movimentacao.data_movimentacao, "DD/MM/YYYY").toDate());
                setDataVencimento(moment(responseData.movimentacao.vencimento, "DD/MM/YYYY").toDate());
                setValor(responseData.movimentacao.valor_total);
                setLancamento(responseData.movimentacao);
                setSelectedCentroCusto(responseData.centro_custo);
                setSelectedFormaPagamento(responseData.movimentacao.forma_pagamento);
                setSelectedPrevisaoPagamento(moment(responseData.movimentacao.data_pagamento, "DD/MM/YYYY").toDate());
                setDescricao(responseData.movimentacao.descricao);
                setHasRateio(responseData.rateado);
                setRateios(responseData.rateios);
                setSelectedFormaPagamento(responseData.movimentacao.forma_pagamento === "null" ? "" : responseData.movimentacao.forma_pagamento);
                setHasPago(responseData.pago);
                setHasAgendado(responseData.agendado);
                setObservacoes(responseData.movimentacao.observacoes);
                if (responseData.movimentacao.valor_total === responseData.valor_parcela_pago) {
                    if (responseData.movimentacao.tipo_movimentacao === "Receita") {
                        setSituacao("Recebido");
                    }
                    else {
                        setSituacao("Pago");
                    }
                }
                else if (responseData.total_pagar !== 0 && moment(responseData.movimentacao.vencimento, "DD/MM/YYYY").isBefore(moment(getCurrentDate()))) {
                    setSituacao("Em Atraso");
                }
                else {
                    setSituacao("Em Aberto");
                }

                originalFieldsAux["Data de competência"] = responseData.movimentacao.data_movimentacao;
                originalFieldsAux["Descrição"] = responseData.movimentacao.descricao;
                originalFieldsAux["Valor"] = responseData.movimentacao.valor_total;
                originalFieldsAux["Rateio"] = responseData.rateado;
                originalFieldsAux["Vencimento"] = responseData.movimentacao.vencimento;
                originalFieldsAux["Centro de custo"] = responseData.centro_custo;
                originalFieldsAux["Forma de pagamento"] = responseData.movimentacao.forma_pagamento;
                originalFieldsAux["Agendado"] = responseData.agendado;
                originalFieldsAux["Observações"] = responseData.movimentacao.observacoes;

                setOriginalFields(originalFieldsAux);

                setLoading(false);
            }).catch(() => {
                setLoading(false);
                dispatch(showSnackMessage({message: "Erro ao buscar informações do lançamento", severity: "error"}));
            });
        }
    };

    const checkChanges = () => {
        let updatedFieldsAux = [];

        originalFields["Cliente/Fornecedor"]?.value !== selectedFornecedor?.value && updatedFieldsAux.push("Cliente/Fornecedor");
        originalFields["Data de competência"] !== moment(dataCompetencia).format("DD/MM/YYYY") && updatedFieldsAux.push("Data de competência");
        originalFields["Descrição"] !== descricao && updatedFieldsAux.push("Descrição");
        originalFields["Valor"] !== valor && updatedFieldsAux.push("Valor");
        originalFields["Rateio"] !== hasRateio && updatedFieldsAux.push("Rateio");
        originalFields["Categoria"]?.value !== selectedCategoria?.value && updatedFieldsAux.push("Categoria");
        originalFields["Centro de custo"]?.value !== selectedCentroCusto?.value && updatedFieldsAux.push("Centro de custo");
        originalFields["Vencimento"] !== moment(dataVencimento).format("DD/MM/YYYY") && updatedFieldsAux.push("Vencimento");
        originalFields["Forma de pagamento"] !== selectedFormaPagamento && updatedFieldsAux.push("Forma de pagamento");
        originalFields["Conta de pagamento"]?.value !== selectedConta?.value && updatedFieldsAux.push("Conta de pagamento");
        originalFields["Agendado"] !== hasAgendado && updatedFieldsAux.push("Agendado");
        originalFields["Observações"] !== observacoes && updatedFieldsAux.push("Observações");

        setUpdatedFields(updatedFieldsAux);

        if (updatedFieldsAux.length > 0) {
            setOpenConfirmationModal(true);
        }
        else {
            editLancamento();
        }
    };

    const getInfoText = () => {
        let infoText = "";
        
        switch (selectedChoice) {
            case "unico":
                infoText = `Você está editando apenas o lançamento referente a competência ${lancamento?.data_movimentacao}`;
                break;
            case "nao-vencidos":
                infoText = `Você está editando o lançamento recorrente referente a competência ${lancamento?.data_movimentacao} e todos os não vencidos que não possuam baixas`;
                break;
            case "em-aberto":
                infoText = `Você está editando o lançamento recorrente referente a competência ${lancamento?.data_movimentacao} e todos em aberto (vencidos + a vencer) que não possuam baixas`;
                break;
            case "todos":
                infoText = "Você está editando todos os lançamento recorrentes. Algumas ações de edição poderão estar bloqueadas caso tenham valores baixados anteriormente, ou que possuam cobranças em aberto.";
                break;
            default:
                infoText = "";
                break;
        }
        return infoText;
    };

    const closeModal = () => {
        setInputError(false);
        setOpen(false);
        setSelectedLancamentoRecorrente(null);
        setLoadingAnexos(true);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                    backgroundImage: "none"
                },
            }}
            data-testid="movimentacao-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? BLUE_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        Editar lançamento
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }} data-testid="exm-container-edit">
                <CreateOptionModal
                    openModal={openCreateModal}
                    setOpenModal={setOpenCreateModal}
                    option={optionCreateModal}
                    getInputsOptions={getLancamentoData}
                />
                <RecorrenciaConfirmationModal
                    openModal={openConfirmationModal}
                    setOpenModal={setOpenConfirmationModal}
                    editLancamento={editLancamento}
                    updatedFields={updatedFields}
                    setUpdatedFields={setUpdatedFields}
                />
                {
                    openWarning && (
                        <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: `1px solid ${BLUE_LIGHT_UX_THEME}`, borderWidth: "1px 1px 1px 6px",
                            margin: `20px ${
                                isLargeScreen ? "120px" : "20px"
                            } 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}>
                            <div style={{display: "flex", gap: "1rem", alignItems: "start", justifyContent: "space-between"}}>
                                <div style={{display: "flex", gap: "1rem", alignItems: "start"}}>
                                    <InfoIcon sx={{color: BLUE_LIGHT_UX_THEME, ml: 1, fontSize: "26px"}} />
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <span style={{fontWeight: "bold", fontSize: "15px", marginBottom: ".5rem"}}>Lançamento Recorrente</span>
                                        <span style={{fontSize: "14px"}}>{getInfoText()}</span>
                                    </div>
                                </div>
                                <CloseIcon
                                    sx={{"cursor": "pointer"}}
                                    onClick={() => setOpenWarning(false)}
                                    fontSize="small"
                                />
                            </div>
                        </Paper>
                    )
                }
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span><p className="title-container-p">Informações do lançamento</p></span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                <Grid item>
                                    <FilterSingleOption
                                        sx={{borderRadius: 0}}
                                        filterOptions={fornecedores}
                                        selectedOption={selectedFornecedor}
                                        setSelectedOption={setSelectedFornecedor}
                                        placeholder={lancamento?.tipo_movimentacao === "Receita" ? "Cliente" : "Fornecedor"}
                                        width={"350px"}
                                        canCreate={!viewOnly}
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                        disabled={viewOnly}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterDayMonthYear
                                        disableMinDate
                                        handleSubmit={handleDayMonthYear}
                                        value={dataCompetencia}
                                        setState={setDataCompetencia}
                                        size={"small"}
                                        width={"250px"}
                                        iconPosition={"end"}
                                        label={"Data de competência"}
                                        isRequired
                                        disabled={viewOnly}
                                        disableText={viewOnly}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setDescricao}
                                        searchValue={descricao}
                                        label={"Descrição"}
                                        width={"575px"}
                                        isRequired
                                        disabled={viewOnly}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputCurrency
                                        title={"Valor"}
                                        valor={valor}
                                        setValor={setValor}
                                        disabled={hasPago || viewOnly}
                                        inputError={inputError}
                                    />
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        data-testid="rateio-checkbox"
                                        checked={hasRateio}
                                        icon={<CircleOutlinedIcon/>}
                                        checkedIcon={<CircleIcon/>}
                                        onChange={(event) => {
                                            setHasRateio(event.target.checked);
                                        }}
                                        disabled={viewOnly}
                                    />
                                    <a style={{
                                        ...styleText,
                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                    }}>Habilitar rateio</a>
                                </Grid>
                                <Grid item>
                                    <FilterSingleOption
                                        sx={{borderRadius: 0}}
                                        filterOptions={categorias}
                                        selectedOption={selectedCategoria}
                                        setSelectedOption={setSelectedCategoria}
                                        placeholder={"Categoria"}
                                        width={"350px"}
                                        disabled={hasRateio || viewOnly}
                                        canCreate={!viewOnly}
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                        isRequired
                                        inputError={inputError}
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterSingleOption
                                        filterOptions={centroCusto}
                                        selectedOption={selectedCentroCusto}
                                        setSelectedOption={setSelectedCentroCusto}
                                        placeholder={"Centro de custo"}
                                        width={"350px"}
                                        canCreate={!viewOnly}
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                        disabled={viewOnly}
                                    />
                                </Grid>
                                {
                                    (situacao !== "Recebido" && situacao !== "Pago") && (
                                        <Grid item xs={12} sx={{display: "flex", gap: "2rem", justifyContent: "end"}}>
                                            <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, textAlign: "end"}}>
                                                <p style={{margin: 0}}>Saldo em aberto</p>
                                                <p style={{margin: 0, fontSize: 27, fontWeight: "bold"}}>{`R$ ${format(valor - totalPagoAnterior)}`}</p>
                                            </span>
                                            {
                                                totalPagoAnterior !== 0 && (
                                                    <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, textAlign: "end"}}>
                                                        <p style={{margin: 0}}>{situacao === "Recebido" ? "Total recebido" : "Total pago"}</p>
                                                        <p style={{margin: 0, fontSize: 27, fontWeight: "bold", color: GREEN_SUCCESS_UX}}>R$ {format(totalPagoAnterior)}</p>
                                                    </span>
                                                )
                                            }
                                        </Grid>
                                    )
                                }
                        </Grid>
                    </Grid>
                </Paper>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{marginTop: 2}} data-testid="condicao-pagamento-title" >
                            <span>
                                <p className="title-container-p">{lancamento?.tipo_movimentacao === "Receita" ? "Condição de recebimento" : "Condição de pagamento"}</p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                        </Grid>
                        {
                            ((selectedChoice === "unico" && valor > totalPagoAnterior) || (valor > totalPago)) ? (
                                <React.Fragment>
                                    <Grid item>
                                        {
                                            selectedConta?.conta_tipo === "Cartão de Crédito" ? (
                                                <FilterYear
                                                    handleSubmit={handleDayMonthYear}
                                                    value={dataVencimento}
                                                    setState={setDataVencimento}
                                                    diaVencimento={selectedConta.dia_vencimento_cartao}
                                                    label={"Vencimento"}
                                                    width={"250px"}
                                                />
                                            ) : (
                                                <FilterDayMonthYear
                                                    disabled={viewOnly ||hasPago || (situacao !== "Em Aberto" && situacao !== "Em Atraso")}
                                                    disableMinDate
                                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                    handleSubmit={handleDayMonthYear}
                                                    value={dataVencimento}
                                                    setState={setDataVencimento}
                                                    size={"small"}
                                                    width={"250px"}
                                                    iconPosition={"end"}
                                                    label={"Vencimento"}
                                                    disableText={viewOnly}
                                                />
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={formaPagamentoOptions}
                                            selectedOption={selectedFormaPagamento}
                                            setSelectedOption={setSelectedFormaPagamento}
                                            placeholder={"Forma de pagamento"}
                                            isRequired
                                            disabled={viewOnly}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={contas}
                                            selectedOption={selectedConta}
                                            setSelectedOption={setSelectedConta}
                                            placeholder="Conta de pagamento"
                                            isRequired={hasPago}
                                            inputError={inputError}
                                            disabled={viewOnly}
                                        />
                                    </Grid>
                                    {
                                        selectedChoice === "unico" && (
                                            <Grid item>
                                                <Checkbox
                                                    checked={hasPago}
                                                    disabled={viewOnly}
                                                    onChange={(event) => {
                                                        setHasPago(event.target.checked);

                                                        if (event.target.checked) {
                                                            setValorPago(valor);
                                                        }
                                                    }}
                                                />
                                                <a style={{
                                                    ...styleText,
                                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                    fontSize: "12px",
                                                }}>{lancamento?.tipo_movimentacao ? "Recebido" : "Pago"}</a>
                                            </Grid>
                                        )
                                    }
                                    {
                                        !hasPago && (
                                            <Grid item>
                                                <Checkbox
                                                    checked={hasAgendado}
                                                    disabled={viewOnly}
                                                    onChange={(event) => {
                                                        setHasAgendado(event.target.checked);
                                                    }}
                                                />
                                                <a style={{
                                                    ...styleText,
                                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                    fontSize: "12px",
                                                }}>{"Agendado"}</a>
                                            </Grid>
                                        )
                                    }
                                    {
                                        selectedChoice === "unico" && (
                                            <React.Fragment>
                                                <Grid item xs={12} sx={{marginTop: 3}}>
                                                    {
                                                        lancamento?.tipo_movimentacao === "Receita" ? (
                                                            <span>
                                                                <p className="title-container-p">{hasPago ? "Informações do recebimento" : "Previsão de recebimento"}</p>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <p className="title-container-p">{hasPago ? "Informações do pagamento" : "Previsão de pagamento"}</p>
                                                            </span>
                                                        )
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                                    {
                                                        hasPago ? (
                                                            <FilterDayMonthYear
                                                                disableMinDate
                                                                handleSubmit={handleDayMonthYear}
                                                                value={selectedPrevisaoPagamento}
                                                                setState={setSelectedPrevisaoPagamento}
                                                                size={"small"}
                                                                width={"250px"}
                                                                iconPosition={"end"}
                                                                label={"Data do pagamento"}
                                                                inputError={inputError}
                                                                disabled={viewOnly}
                                                                disableText={viewOnly}
                                                            />
                                                        ) : (
                                                            selectedConta.conta_tipo === "Cartão de Crédito" ? (
                                                                <FilterYear
                                                                    handleSubmit={handleDayMonthYear}
                                                                    value={selectedPrevisaoPagamento}
                                                                    setState={setSelectedPrevisaoPagamento}
                                                                    diaVencimento={selectedConta.dia_vencimento_cartao}
                                                                    label={"Previsão do pagamento"}
                                                                    width={"250px"}
                                                                />
                                                            ) : (
                                                                <FilterDayMonthYear
                                                                    disableMinDate
                                                                    handleSubmit={handleDayMonthYear}
                                                                    value={selectedPrevisaoPagamento}
                                                                    setState={setSelectedPrevisaoPagamento}
                                                                    size={"small"}
                                                                    width={"250px"}
                                                                    iconPosition={"end"}
                                                                    label={"Previsão do pagamento"}
                                                                    inputError={inputError}
                                                                    disabled={viewOnly}
                                                                    disableText={viewOnly}
                                                                />
                                                            )
                                                        )
                                                    }
                                                    <InputCurrency
                                                        errorMessage="O valor de desconto não pode ser superior ao valor nominal da parcela"
                                                        error={desconto > valor}
                                                        valor={desconto}
                                                        setValor={setDesconto}
                                                        title={"Desconto"}
                                                        required={false}
                                                        disabled={viewOnly}
                                                    />
                                                    <InputCurrency
                                                        valor={juros}
                                                        setValor={setJuros}
                                                        title={"Juros"}
                                                        required={false}
                                                        disabled={viewOnly}
                                                    />
                                                    <InputCurrency
                                                        valor={multa}
                                                        setValor={setMulta}
                                                        title={"Multa"}
                                                        required={false}
                                                        disabled={viewOnly}
                                                    />
                                                    <InputCurrency
                                                        errorMessage="O valor nominal da baixa não pode ser superior ao saldo da parcela"
                                                        error={valorPago > valor}
                                                        disabled={!hasPago || viewOnly}
                                                        valor={hasPago ? valorPago : new Decimal(valor).minus(totalPagoAnterior).toDecimalPlaces(2).toNumber()}
                                                        setValor={hasPago && setValorPago}
                                                        title={hasPago ? "Valor pago" : "Valor a pagar"}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                </React.Fragment>
                            ) : (
                                <Grid item xs={12} sx={{maxHeight: "30vh", mt: 3}}>
                                    <TableContainer>
                                        <Table sx={{".MuiTableCell-root": {
                                                        padding: ".8rem 1rem",
                                                        fontWeight: "400",
                                                        textAlign: "center"
                                                    }}}>
                                            <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                                <TableRow>
                                                    <TableCell>Data</TableCell>
                                                    <TableCell>Parcela</TableCell>
                                                    <TableCell>Conta</TableCell>
                                                    <TableCell>Valor</TableCell>
                                                    <TableCell>Pago</TableCell>
                                                    <TableCell>Em aberto</TableCell>
                                                    <TableCell>Situação</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{lancamento?.data_movimentacao}</TableCell>
                                                    <TableCell>1/1</TableCell>
                                                    <TableCell>{selectedConta.label}</TableCell>
                                                    <TableCell>{formatterCurrency(valor, 2)}</TableCell>
                                                    <TableCell>{formatterCurrency(valorPago, 2)}</TableCell>
                                                    <TableCell>{formatterCurrency(valorEmAberto, 2)}</TableCell>
                                                    <TableCell>{checkSituacao(temaEscuro, situacao)}</TableCell>
                                                </TableRow>
                                                {
                                                    valorEmAberto > 0 && (
                                                        <TableRow>
                                                            <TableCell>{moment(dataVencimento).format("DD/MM/YYYY")}</TableCell>
                                                            <TableCell>1/1</TableCell>
                                                            <TableCell>{selectedConta.label}</TableCell>
                                                            <TableCell>{formatterCurrency(valorEmAberto, 2)}</TableCell>
                                                            <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                            <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                            <TableCell>{checkSituacao(temaEscuro, "Em Aberto")}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                        {
                            (valor !== totalPago && selectedChoice === "unico" && hasPago) && (
                                <React.Fragment>
                                    <Grid item xs={12} sx={{mt: 2}}>
                                        <Paper sx={{ ...paperDash, ...dashLeftTitle, backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none" }}>
                                            <Grid container>
                                                <Grid item xs={6} sx={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                                    <Grid container spacing={1} className="footer_periodo">
                                                        <Grid item xs={12}>
                                                            <span style={{ ...styles.title, ...(temaEscuro && { color: WHITE_ESCRITA_THEME }) }}>Totais</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} sx={{display: "flex", justifyContent: "end", gap: 2}}>
                                                    <Grid container xs={6} spacing={1} className="footer_valor">
                                                        <Grid item xs={6} sx={{ display: "flex", alignItems: "end", flexDirection: "column", gap: 1 }}>
                                                            <span style={{ ...styles.subTitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}>Valor em Aberto (R$)</span>
                                                            <span style={{ ...styles.subTitle, "color": DASH_KPI_NEGATIVE_COLOR }}>{formatterCurrency(new Decimal(valor).minus(totalPagoAnterior).minus(valorPago).toDecimalPlaces(2).toNumber(), 2)}</span>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ display: "flex", alignItems: "end", flexDirection: "column", gap: 1 }}>
                                                            <span style={{ ...styles.subTitle, ...(temaEscuro && { color: WHITE_THEME_BLACK }) }}> {lancamento?.tipo_movimentacao == "Receita" ? "Total a receber (R$)" : "Total a pagar (R$)"}</span>
                                                            <span style={{ ...styles.subTitle, "color": BLUE_INFO_UX }}>{formatterCurrency(new Decimal(valorPago).plus(juros).plus(multa).minus(desconto).toDecimalPlaces(2).toNumber(), 2)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sx={{mt: 2}}>
                                        <Accordion sx={{backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Grid container sx={{display: "flex", justifyContent: "space-between"}}>
                                                    <span style={{fontSize: "18px", fontWeight: "bold"}}>Resumo da baixa</span>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{display: "flex"}}>
                                            <Grid item xs={12} sx={{maxHeight: "30vh", mt: 3}}>
                                                <TableContainer>
                                                    <Table sx={{".MuiTableCell-root": {
                                                                    padding: ".8rem 1rem",
                                                                    fontWeight: "400",
                                                                    textAlign: "center"
                                                                }}}>
                                                        <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                                            <TableRow>
                                                                <TableCell>Data</TableCell>
                                                                <TableCell>Forma de pagamento</TableCell>
                                                                <TableCell>Conta</TableCell>
                                                                <TableCell>Valor R$</TableCell>
                                                                <TableCell>Juros/Multa R$</TableCell>
                                                                <TableCell>Desconto/Tarifas R$</TableCell>
                                                                <TableCell>Situação</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                baixasAnteriores.map((baixa) => {
                                                                    if (baixa)
                                                                        return (
                                                                            <TableRow key={baixa?.id}>
                                                                                <TableCell>{baixa.formatted_data_movimentacao}</TableCell>
                                                                                <TableCell>{baixa.forma_pagamento_formatted}</TableCell>
                                                                                <TableCell>{baixa.conta__apelido}</TableCell>
                                                                                <TableCell>{formatterCurrency(baixa.valor, 2)}</TableCell>
                                                                                <TableCell>{formatterCurrency((baixa.multa + baixa.juros), 2)}</TableCell>
                                                                                <TableCell>{formatterCurrency(baixa.desconto, 2)}</TableCell>
                                                                                <TableCell>{checkSituacao(temaEscuro, lancamento?.tipo_movimentacao === "Receita" ? "Recebido" : "Pago")}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                })
                                                            }
                                                            <TableRow key={lancamento?.id}>
                                                                <TableCell>{moment(selectedPrevisaoPagamento).format("DD/MM/YYYY")}</TableCell>
                                                                <TableCell>{selectedFormaPagamento}</TableCell>
                                                                <TableCell>{selectedConta.label}</TableCell>
                                                                <TableCell>{formatterCurrency(valorPago, 2)}</TableCell>
                                                                <TableCell>{formatterCurrency((multa + juros), 2)}</TableCell>
                                                                <TableCell>{formatterCurrency(desconto, 2)}</TableCell>
                                                                <TableCell>{checkSituacao(temaEscuro, lancamento?.tipo_movimentacao === "Receita" ? "Recebido" : "Pago")}</TableCell>
                                                            </TableRow>
                                                            {
                                                                new Decimal(valor).minus(totalPagoAnterior).minus(valorPago).toDecimalPlaces(2).toNumber() > 0 && (
                                                                    <TableRow key={lancamento?.id}>
                                                                        <TableCell>{moment(dataVencimento).format("DD/MM/YYYY")}</TableCell>
                                                                        <TableCell>{selectedFormaPagamento}</TableCell>
                                                                        <TableCell>{selectedConta.label}</TableCell>
                                                                        <TableCell>{formatterCurrency(new Decimal(valor).minus(totalPagoAnterior).minus(valorPago).toDecimalPlaces(2).toNumber(), 2)}</TableCell>
                                                                        <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                                        <TableCell>{formatterCurrency(0, 2)}</TableCell>
                                                                        <TableCell>{checkSituacao(temaEscuro, "Em Aberto")}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                                <div style={{marginLeft: "46px"}}></div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                        <Grid item xs={8} sx={{backgroundColor: "transparent", marginBottom: "15px", marginTop: 3}}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                className="buttonTabs"
                            >
                                <Tab
                                    data-testid="observacoes-tab"
                                    value={"observacoes"}
                                    label="Observações"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                <Tab
                                    data-testid="anexos-tab"
                                    value={"anexo"}
                                    label="Anexo"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                {
                                    (hasRateio) && (
                                        <Tab
                                            data-testid="categoria-tab"
                                            value={"categoria_info"}
                                            label="Informações de categoria"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        {selectedTab === "observacoes" ? (
                            <Grid item xs={12}>
                                <TextField
                                    data-testid="observacoes-textfield"
                                    label={"Observações"}
                                    id="input-observacoes-textfield"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    maxRows={6}
                                    value={observacoes}
                                    onChange={(event) => {
                                        setObservacoes(event.target.value);
                                    }}
                                    sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                        ) : selectedTab === "anexo" ? (
                            <Anexos
                                loading={loadingAnexos}
                                anexos={anexos}
                                setAnexos={setAnexos}
                                disabled={viewOnly}
                            />
                        ) : hasRateio && (
                            <RateiosTab
                                rateios={rateios}
                                setRateios={setRateios}
                                categoriaOptions={categorias}
                                diferencaRateios={diferencaRateios}
                                valorTotal={valor}
                                totalRateios={totalRateios}
                                disabled={viewOnly}
                            />
                        )}
                    </Grid>
                </Paper>
                <Grid container>
                    <Grid 
                        item
                        xs={12} 
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            gap: 2,
                            margin: `0px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`
                        }}
                    >
                        <DefaultButton
                            title={"Voltar"}
                            onClick={() => closeModal()}
                        />
                        <Grid sx={{display: "flex"}}>
                            <DefaultButton
                                disabled={loading || viewOnly}
                                loading={loading}
                                testId={"salvar-button"}
                                title={"Salvar"}
                                onClick={() => {
                                        if (selectedChoice !== "unico") {
                                            checkChanges();
                                        }
                                        else {
                                            editLancamento();
                                        }
                                    }
                                }
                                borderRadius={{borderRadius: "5px 0 0 5px"}}
                                variant="yellow"
                            />
                            <SaveOptions
                                action={""}
                                cleanStates={""}
                                disabled={viewOnly}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

const styles = {
	title: {
		fontSize: "20px",
		fontWeight: "bold",
		color: DASH_KPI_COLOR
	},
	subTitle: {
		fontSize: "14px",
		fontWeight: "bold",
		color: DASH_KPI_DATA_COLOR
	},
};

export default EditarLancamentoRecorrente;