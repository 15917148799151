import React, { useEffect, useState }  from "react";
import {smallPaperStyle, RED_ERROR_UX, GRAY_BG_UX, checkCustomPeriodoIsValid} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {BLUE_THEME} from "../../shared/utils";
import Divider from "@mui/material/Divider";
import ModalTitle from "../Titles/ModalTitle";
import DefaultButton from "../Buttons/DefaultButton";
import FilterEXM from "../Filter/FilterEXM";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { TextField } from "@mui/material";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";


const EnviarNotasContadorModal = (props) => {
    const {openModal, setOpenModal} = props;

    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [periodo, setPeriodo] = useState("este_mes");
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [emailContador, setEmailContador] = useState("");
    const [notasIds, setNotasIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const [customPeriodo, setCustomPeriodo] = useState([null, null]);

    useEffect(() => {
        if (openModal && periodo === "custom_periodo" && checkCustomPeriodoIsValid(customPeriodo)) {
            getNotasTotal();
        }
        else if (openModal && periodo !== "custom_periodo") {
            getNotasTotal();
        }
    }, [openModal, periodo, arrowCounter, specificDate, customPeriodo]);



    const clearFields = () => {
        setPeriodo("este_mes");
        setSpecificDate("");
        setArrowCounter(0);
        setEmailContador("");
        setCustomPeriodo([null, null]);
        setOpenModal(false);
    };

    const getNotasTotal = () => {
        const dataRequest = {
            periodo: periodo,
        };
        if (arrowCounter !== 0) {
            dataRequest.plus_minus = arrowCounter;
        }
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }

        api.GetNotasTotal(dataRequest).then((response) => {
            let data = response.data;
            setFilterPlaceholders(data.filter_placeholders);
            setNotasIds(data.notas_ids);
            setSpecificDate(data?.specific_date || "");
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Erro ao buscar notas do período selecionado, tente novamente mais tarde.", severity: "error" }));
        });

    };

    const sendToContador = () => {
        setLoading(true);

        const dataRequest = {
            notas_ids: notasIds,
            email_contador: emailContador
        };

        api.SendToContador(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Notas enviadas com sucesso ao contador.", severity: "success" }));
            clearFields();
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Erro ao enviar notas ao contador, tente novamente mais tarde.", severity: "error" }));
            setLoading(false);
        });
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => clearFields()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="notas-contador-modal"
            >
                <Paper sx={{...smallPaperStyle, top: "30%", maxWidth: "620px", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                    <ModalTitle title={"Enviar Notas Fiscais ao contador"} setOpenModal={clearFields}/>
                    <Grid container>
                        <Grid item xs={12} sx={{display: "flex", gap: 2, alignItems: "center", justifyContent: "center"}}>
                            <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                                <FilterEXM
                                    placeholders={filterPlaceholders}
                                    periodo={periodo}
                                    setPeriodo={setPeriodo}
                                    specificDate={specificDate}
                                    setSpecificDate={setSpecificDate}
                                    arrowCounter={arrowCounter}
                                    setArrowCounter={setArrowCounter}
                                    callFunction={getNotasTotal}
                                    disableButton
                                />
                                {
                                    periodo === "custom_periodo" && (
                                        <DateRangePicker
                                            value={customPeriodo}
                                            onChange={(newValue) => setCustomPeriodo(newValue)}
                                            localeText={{ start: "Início", end: "Fim" }}
                                            sx={{width: "330px", marginTop: 1}}
                                            format="DD/MM/YYYY"
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                }
                                            }}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                                <TextField
                                    size="small"
                                    label="Email do seu contador"
                                    value={emailContador}
                                    onChange={(e) => setEmailContador(e.target.value)}
                                    sx={{width: "280px"}}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sx={{marginTop: ".5rem"}}>
                            {
                                notasIds.length === 0 && (
                                    <span style={{color: RED_ERROR_UX}}>Você não possui notas nesse mês</span>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                sx={{mt: "2rem"}}
                            />
                            <Grid container sx={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-between", marginTop: "1rem"}}>
                                <DefaultButton
                                    onClick={() => clearFields()}
                                    title={"Cancelar"}
                                />
                                <DefaultButton
                                    variant="yellow"
                                    onClick={() => sendToContador(false)}
                                    title={"Enviar notas"}
                                    loading={loading}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default EnviarNotasContadorModal;