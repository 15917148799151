import React  from "react";
import {smallPaperStyle, LINE_TABLE, YELLOW_BG_UX, BLUE_LIGHT_UX_THEME, paperStyle} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {BLUE_THEME} from "../../shared/utils";
import ModalTitle from "../Titles/ModalTitle";
import EditContaVencimentoLote from "./EditContaVencimentoLote";
import DeleteWarning from "./DeleteWarning";
import InfoIcon from "@mui/icons-material/Info";
import { showSnackMessage } from "../../actions/SnackActions";
import UpdateWarning from "./UpdateWarning";


const WarningModal = (props) => {
    const {openModal, setOpenModal, type, exmItems, tab, getExtratoMovimentacoes} = props;
    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const getTitle = () => {
        if (tab === "movimentacoes" && type === "vencimento") {
            return "Editar data de previsão do(s) lançamento(s)";
        }
        else if (tab !== "movimentacoes" && type === "vencimento") {
            return "Alterar vencimento e/ou previsão";
        }
        else if (type === "excluir") {
            return "Excluir lançamento(s)";
        }
        else if (type === "agendado") {
            return "Definir parcelas como agendadas";
        }
        else if (type === "nao_agendado") {
            return "Desmarcar parcelas como agendadas";
        }
        else if (type === "pago") {
            return "Definir lançamento(s) como quitado(s)";
        }
        else if (type === "em_aberto") {
            return "Definir lançamento(s) como em aberto";
        }
        else {
            return "Alterar conta de parcela(s)";
        }
    };

    const getWarningTitle = () => {
        if (tab === "movimentacoes" && type === "vencimento") {
            return "Edição da previsão de pagamento de múltiplos lançamentos";
        }
        else if (type === "excluir") {
            return "Esta ação pode causar divergências entre os saldos do VXMF e banco";
        }
        else if (type === "agendado" || type === "nao_agendado") {
            return "Atenção";
        }
        else if (type === "pago") {
            return "Data da baixa";
        }
        else if (type === "em_aberto") {
            return "Saldo VXMF";
        }
        else {
            return "Edição de vencimento e/ou previsão em lote";
        }
    };

    const getWarningMessage = () => {
        if (tab === "movimentacoes" && type === "vencimento") {
            return <span>Esta edição será realizada apenas para lançamentos com situação <strong>Em Aberto</strong></span>;
        }
        else if (type === "excluir") {
            return <span>Além disso, caso algum lançamento selecionado possua pagamento ou recebimento parcial, seu valor final será alterado.</span>;
        }
        else if (type === "agendado") {
            return <span>A ação de <strong>marcar como agendado</strong> apenas define a parcela como agendada. Você ainda precisa agendar o pagamento no seu banco. A baixa só será feita no VXMF quando for marcado, manualmente, como pago ou pela conciliação.</span>;
        }
        else if (type === "nao_agendado") {
            return <span>A ação de <strong>desmarcar como agendado</strong> apenas desmarca o agendamento no VXMF. Você ainda precisa desfazer o agendamento no seu banco.</span>;
        }
        else if (type === "pago") {
            return <span>Os registros serão quitados com a informação apresentada na coluna Data, salvo lançamentos com data futura, que serão quitados com a data de hoje.</span>;
        }
        else if (type === "em_aberto") {
            return <span>A remoção das baixas nos registros irá impactar no saldo VXMF apresentado para suas respectivas contas, causando possíveis divergências entre Saldo VXMF e saldo conta bancária</span>;
        }
        else {
            return <span>O vencimento será alterado <strong>somente para parcelas em aberto</strong>, enquanto a previsão será alterada para parcelas em aberto ou recebido parcial.</span>;
        }
    };

    return(
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
        >
            <Paper sx={{...smallPaperStyle, maxWidth: "50vw", width: "40vw", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                <Grid item xs={12}>
                    <ModalTitle title={getTitle()} setOpenModal={setOpenModal} />
                </Grid>
                {
                    type !== "conta" && (
                        <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: "1px solid", borderColor: (type !== "conta" && type !== "vencimento" && type !== "pago") ? YELLOW_BG_UX : BLUE_LIGHT_UX_THEME, borderWidth: "1px 1px 1px 6px", mb: 2, mt: 2}}>
                            <div style={{display: "flex", gap: "1rem", alignItems: "start", justifyContent: "space-between"}}>
                                <div style={{display: "flex", gap: "1rem", alignItems: "start"}}>
                                    <InfoIcon sx={{color: (type !== "conta" && type !== "vencimento" && type !== "pago") ? YELLOW_BG_UX : BLUE_LIGHT_UX_THEME, ml: 1, fontSize: "26px"}} />
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <span style={{fontWeight: "bold", fontSize: "15px", marginBottom: ".5rem"}}><p className="title-container-p">{getWarningTitle()}</p></span>
                                        <span style={{fontSize: "14px"}}><p className="item-container-label">{getWarningMessage()}</p></span>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    )
                }
                {
                    (type === "conta" || type === "vencimento") ? (
                        <EditContaVencimentoLote
                            setOpenModal={setOpenModal}
                            tab={tab}
                            temaEscuro={temaEscuro}
                            type={type}
                            contas={props?.contas}
                            dispatch={dispatch}
                            exmItems={props?.exmItems}
                            getExtratoMovimentacoes={getExtratoMovimentacoes}
                            showSnackMessage={showSnackMessage}
                        />
                    ) : type === "excluir" ? (
                        <DeleteWarning
                            setOpenModal={setOpenModal}
                            warningMessage={getWarningMessage()}
                            temaEscuro={temaEscuro}
                            dispatch={dispatch}
                            showSnackMessage={showSnackMessage}
                            exmItems={exmItems}
                            tab={tab}
                            getExtratoMovimentacoes={getExtratoMovimentacoes}
                        />
                    ) : (
                        <UpdateWarning
                            setOpenModal={setOpenModal}
                            warningMessage={getWarningMessage()}
                            temaEscuro={temaEscuro}
                            dispatch={dispatch}
                            showSnackMessage={showSnackMessage}
                            exmItems={exmItems}
                            tab={tab}
                            getExtratoMovimentacoes={getExtratoMovimentacoes}
                            type={type}
                        />
                    )
                }
            </Paper>
        </Modal>
    );                  
};

export default WarningModal;