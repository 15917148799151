import React from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
    BLUE_THEME,
    RED_INFO,
    GRAY_PDF,
    formatterCurrency,
} from "../../shared/utils";
import { useSelector } from "react-redux";
import FilterSingleOption from "../Filter/FilterSingleOption";
import InputCurrency from "../Input/InputCurrency";
import DefaultButton from "../Buttons/DefaultButton";
import InputSearch from "../Input/InputSearch";
import Decimal from "decimal.js";


export default function RateiosTab(props) {

    const {rateios, setRateios, valorTotal, diferencaRateios, categoriaOptions, totalRateios} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const handleRateioChange = (index, field, newValue) => {
        setRateios((prevRateios) => {
            return prevRateios.map((rateio, i) => {
                if (i === index) {
                    const updatedRateio = { ...rateio, [field]: newValue };
    
                    if (field === "valor") {
                        updatedRateio.percentual = valorTotal > 0 ? ((newValue / valorTotal) * 100).toFixed(2) : 0;
                    }
                    if (field === "categoria") {
                        updatedRateio.categoria["label"] = newValue.label;
                        updatedRateio.categoria["value"] = newValue.value;
                    }
    
                    return updatedRateio;
                }
                return rateio;
            });
        });
    };

    const handleAddRateio = () => {
        setRateios((prevRateios) => [
            ...prevRateios,
            { categoria: {"label": null, "value": null}, valor: 0, percentual: 0, centro_custo: null }
        ]);
    };

    const handleRemoveRateio = (indexToRemove) => {
        setRateios((prevRateios) =>
            prevRateios.filter((_, index) => index !== indexToRemove)
        );
    };

    return (
        <Grid item xs={12}>
            <TableContainer>
                <Table>
                    <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bold", fontSize: 15}}>
                                Categoria
                            </TableCell>
                            <TableCell  sx={{fontWeight: "bold", fontSize: 15}}>
                                Valor
                            </TableCell>
                            <TableCell  sx={{fontWeight: "bold", fontSize: 15}}>
                                Porcentagem
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold", fontSize: 15, textAlign: "center" }}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rateios.map((rateio, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <FilterSingleOption
                                        filterOptions={categoriaOptions}
                                        selectedOption={rateio.categoria.label}
                                        setSelectedOption={(newValue) => handleRateioChange(index, "categoria", newValue)}
                                        placeholder="Categoria"
                                        width={"250px"}
                                        isRequired
                                        disabled={props?.disabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputCurrency
                                        valor={Math.abs(rateio.valor)}
                                        setValor={(newValue) => handleRateioChange(index, "valor", newValue)}
                                        title="Valor"
                                        disabled={props?.disabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputSearch
                                        disabled
                                        functionProps={() => {}}
                                        searchValue={`${new Decimal(Math.abs(rateio.valor)).dividedBy(valorTotal).times(100).toDecimalPlaces(2).toNumber().toFixed(2)} %`}
                                        setSearchValue={null}
                                        label="Porcentagem"
                                        isRequired
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                    <IconButton disabled={props?.disabled} onClick={() => handleRemoveRateio(index)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {diferencaRateios !== 0 && (
                            <TableRow>
                                <TableCell colSpan={3} style={{ color: RED_INFO, textAlign: "center", fontWeight: "bold" }}>
                                    Atenção: A soma dos valores ({formatterCurrency(totalRateios, 2)}) não corresponde ao valor do lançamento ({valorTotal})!
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <div style={{marginTop: 10}}>
                        <DefaultButton
                            onClick={() => handleAddRateio()}
                            title="Adicionar linha"
                            size="small"
                            startIcon={<AddIcon />}
                            disabled={props?.disabled}
                        />
                    </div>
                </Table>
            </TableContainer>
        </Grid>
    );
}