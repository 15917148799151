import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import {
    BLUE_THEME,
    BORDER_BUTTON,
    translateAutocomplete,
    GRAY_BG_UX,
    textCompleteStyleBlack,
    textCompleteStyle,
    YELLOW_BG_UX,
    COLOR_LABEL_INPUT,
    RED_INFO
} from "../../shared/utils";
import BancoBrasil from "../../containers/Contas/LogoBancos/BB.svg";
import Bradesco from "../../containers/Contas/LogoBancos/Bradesco.png";
import BTG from "../../containers/Contas/LogoBancos/Btg.png";
import CaixaEconomica from "../../containers/Contas/LogoBancos/Caixa Economica.svg";
import Inter from "../../containers/Contas/LogoBancos/Inter.png";
import Itau from "../../containers/Contas/LogoBancos/Itau.png";
import VXMF from "../../containers/Contas/LogoBancos/VXMF.png";
import Santander from "../../containers/Contas/LogoBancos/Santander.png";
import Sicoob from "../../containers/Contas/LogoBancos/Sicoob.png";
import Stone from "../../containers/Contas/LogoBancos/Stone.svg";


const FilterSingleOption = (props) => {
    const {filterOptions, selectedOption, setSelectedOption, setOpenCreateModal, setOptionCreateModal, inputError, isRequired} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const defaultBorderRadius = 4;
    const stylePaper = {
		backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
		color: temaEscuro ? "#FFF" : "#4A5568",
		fontWeight: "500"
	};

    const handleCreate = () => {
        setOpenCreateModal(true);
        if (props?.placeholder === "Fornecedor") {
            setOptionCreateModal("fornecedor");
        } else if (props?.placeholder === "Categoria de despesa" || props?.placeholder === "Categoria") {
            setOptionCreateModal("categoria");
        } else if (props?.placeholder === "Produto/Serviço") {
            setOptionCreateModal("produtoServico");
        } else if (props?.placeholder === "Cliente") {
            setOptionCreateModal("cliente");
        } else if (props?.placeholder === "Centro de custo") {
            setOptionCreateModal("centroCusto");
        } else if (props?.placeholder === "Configuração de repetição"){
            setOptionCreateModal("configRepeticao");
        }
    };

     const renderBanco = (bancoNome) => {
        const bancos = {
            "Banco do Brasil": BancoBrasil,
            "BANCO_BRASIL": BancoBrasil,
            "Bradesco": Bradesco,
            "BRADESCO": Bradesco,
            "BTG_PACTUAL": BTG,
            "CAIXA_ECONOMICA": CaixaEconomica,
            "Inter": Inter,
            "INTER": Inter,
            "Itau": Itau,
            "ITAU": Itau,
            "NAO_BANCO": VXMF,
            "OUTROS": VXMF,
            "Santander": Santander,
            "SANTANDER": Santander,
            "Sicoob": Sicoob,
            "SICOOB": Sicoob,
            "STONE": Stone,
        };

        if (bancos[bancoNome]) {
            return <img src={bancos[bancoNome]} alt={bancoNome} style={{height: 25, width: 26, objectFit: "contain"}} />;
        }

        return (
            <div
                style={{
                    height: "40px",
                    padding: "0px 2.25px",
                    backgroundColor: YELLOW_BG_UX,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "10px",
                    color: "black",
                    borderRadius: "5px",
                }}
            >
                {bancoNome.toUpperCase()}
            </div>
        );
    };

    return(
        <Grid container sx={{display: "flex", flexWrap: "nowrap", width: props?.width, justifyContent: props?.justifyContent, marginRight: props?.canCreate && "2rem"}}>
            <Grid item>
                <Autocomplete
                    noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js
                    id="checkboxes-tags-demo"
                    size={"small"}
                    data-testid={props?.testId ? props.testId : "filter-with-tag"}
                    options={filterOptions ? filterOptions : []}
                    slotProps={{
                        paper: {style: stylePaper}
                    }}
                    fullWidth
                    style={{
                        width: props?.width || "250px",
                        ...(!props?.disabled && {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}),
                    }}
                    disabled={props?.disabled}
                    value={selectedOption}
                    disableClearable={props?.disableClearable}
                    onChange={(event, newValue) => setSelectedOption(newValue)}
                    renderOption={(_props, option) => (
                        <li {..._props} key={option?.value ? option.value : option}>
                            {props?.hasBancoLogo && (
                                renderBanco(option.banco, 25, 26)
                            )}
                            ‎ {option?.label ?? option}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                ...(temaEscuro ? textCompleteStyleBlack : textCompleteStyle),
                                "& label": { color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_BUTTON },
                                "& .MuiFormLabel-asterisk": {
                                    color: RED_INFO,
                                }
                            }}
                            label={props?.placeholder}
                            error={inputError}
                            InputLabelProps={{
                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                            }}
                            placeholder={props?.placeholder}
                            required={isRequired}
                        />
                    )}
                />
            </Grid>
            {props?.canCreate && (
                <Grid item>
                    <AddCircleOutlineOutlinedIcon
                        onClick={() => handleCreate()}
                        sx={{
                            ...styles.styleArrowButton,
                            borderTopRightRadius: defaultBorderRadius,
                            borderBottomRightRadius: defaultBorderRadius,
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            border: `1px solid ${temaEscuro ? "#616169" : "#C3C3C3"}`,
                        }}
                        data-testid="create-option"
                    />
                </Grid>
            )}

        </Grid>

    );
};

export default React.memo(FilterSingleOption);

const styles = {
    styleArrowButton: {
        color: YELLOW_BG_UX,
        fontWeight: "600",
        boxShadow: "none",
        height: "21.5px",
        p: 1,
        cursor: "pointer",
        fontSize: "15px",
    }
};
