import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    YELLOW_BG_UX,
    BLUE_INFO_UX,
    YELLOW_INFO_UX,
    BORDER_BUTTON,
    GRAY_LABEL_UX,
    RED_INFO,
    WHITE_TABLE,
    GRAY_BG_UX,
    manualCurrencyFormatter,
    GRAY_BG_BODY,
    BLACK_LABEL_UX,
    WHITE_ESCRITA_THEME,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    checkCustomPeriodoIsValid,
} from "../../../shared/utils";
import Bradesco from "../../Contas/LogoBancos/Bradesco.png";
import Santander from "../../Contas/LogoBancos/Santander.png";
import Sicoob from "../../Contas/LogoBancos/Sicoob.png";
import Itau from "../../Contas/LogoBancos/Itau.png";
import BancoBrasil from "../../Contas/LogoBancos/BB.png";
import Inter from "../../Contas/LogoBancos/Inter.png";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import InputSearch from "../../../components/Input/InputSearch";
import FilterEXM from "../../../components/Filter/FilterEXM";
import DefaultButton from "../../../components/Buttons/DefaultButton.js";
import DataGridWithServerPagination from "../../../components/DataGrid/DataGridWithServerPagination.js";
import FilterWithTag from "../../../components/Filter/FilterWithTag.js";
import RevisarValores from "../RevisarValores/RevisarValores.js";
import { showSnackMessage } from "../../../actions/SnackActions";
import api from "../../../axios";
import OptionsNovaEXM from "../../../components/Buttons/OptionsNovaEXM.js";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption.js";
import Decimal from "decimal.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BuscarLancamentos = ({
    lancamento,
    conta,
    contas,
    openModalBuscarLanc,
    setOpenModalBuscarLanc,
    getConciliacoesConta,
}) => {
    const isLargeScreen = useMediaQuery("(min-width:1640px)");

    const [openModalRevisarVal, setOpenModalRevisarVal] = useState(false);
    const [valorSelecionado, setValorSelecionado] = useState(0);
    const [diferenca, setDiferenca] = useState(0);

    const dispatch = useDispatch();
    const estabelecimentoId = useSelector((state) => state.StoreReducer.estabelecimentoId);
    const estabelecimentos = useSelector((state) => state.StoreReducer.estabelecimentos);
    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);

    const calculateCounter = (data_lancamento) => {
        const formatted_date = data_lancamento.split("/");
        let data = new Date(
            formatted_date[2],
            formatted_date[1] - 1,
            formatted_date[0]
        );
        let today = new Date();

        return Math.floor((data - today) / (1000 * 60 * 60 * 24 * 7));
    };

    const [pageState, setPageState] = useState({
        data: [],
        total: 0,
        page: 1,
        pageSize: 25,
    });
    const [loading, setLoading] = useState(false);
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [periodo, setPeriodo] = useState("esta_semana");
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(
        calculateCounter(lancamento.data_lancamento)
    );
    const [customPeriodo, setCustomPeriodo] = useState([null, null]);
    // eslint-disable-next-line
    const [contasOptions, setContasOptions] = useState(
        [{ label: "(Em branco)", value: 0 }, ...contas.map(_conta => {
            return { "label": _conta.label, "value": _conta.value };
        })]
    );
    // eslint-disable-next-line
    const [previousContas, setPreviousContas] = useState([conta]);
    const [contasSelected, setContasSelected] = useState([conta.value]);
    const [estabelecimentoSelected, setEstabelecimentoSelected] = useState(estabelecimentos.filter((estabelecimento) => estabelecimento.value === estabelecimentoId)[0]);
    const [inputCategoria, setInputCategoria] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [inputFornecedor, setInputFornecedor] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedItem, setSelectedItem] = useState([]);
    const [rowsSelected, setRowsSelected] = useState([]);

    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);
    const openLancamento = useSelector(state => state.StoreReducer.openLancamento);

    useEffect(() => {
        const isCustomPeriodoValid = checkCustomPeriodoIsValid(customPeriodo);
        const shouldFetchMovimentacoes =
            estabelecimentoSelected !== 0 &&
            ((periodo === "custom_periodo" && isCustomPeriodoValid) ||
                periodo !== "custom_periodo");

        if (shouldFetchMovimentacoes) {
            getBuscarLancamentos();
        }
    }, [
        estabelecimentoSelected,
        arrowCounter,
        periodo,
        customPeriodo,
        pageState.page,
        pageState.pageSize,
    ]);

    useEffect(() => {
        let total = new Decimal(0);
        rowsSelected.forEach((item) => {
            total = total.plus(item.valor_total).toDecimalPlaces(2);
        });
        setValorSelecionado(total.toNumber());
        setDiferenca(
            new Decimal(lancamento.valor_lancamento).minus(total).toDecimalPlaces(2).toNumber()
        );
    }, [rowsSelected]);

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoSelected]);

    useEffect(() => {
        if (openLancamento === "") {
            getBuscarLancamentos();
        }
    }, [openLancamento]);

    const getInputsOptions = () => {
        if (estabelecimentoSelected !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoSelected,
                papel_pessoa: lancamento.valor_lancamento > 0 ? "Cliente" : "Fornecedor",
            };
            api.GetFornecedores(dataRequest).then(response => {
                setFornecedores(response.data);
                setLoading(false);

            });
            api.GetCategorias({...dataRequest, "tipo": lancamento.valor_lancamento > 0 ? "Despesa" : "Receita"}).then(response => {
                setCategorias(response.data);
                setLoading(false);
            });
            api.RetrieveContas({ estabelecimento_id: estabelecimentoSelected.value }).then(response => {
                const contas = [{ label: "(Em branco)", value: 0 }, ...response.data.map(conta => {
                    return { "label": conta.label, "value": conta.value };
                })];
                setContasOptions(contas);
                setContasSelected(estabelecimentoSelected.value === estabelecimentoId ? [conta.value] : contas.map(conta => conta.value));
                setPreviousContas(estabelecimentoSelected.value === estabelecimentoId ? [conta] : contas);
            });
        }
    };

    const getBuscarLancamentos = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoSelected.value,
            periodo: periodo,
            plus_minus: arrowCounter,
            search: searchValue,
            contas: contasOptions ? contasSelected : [conta.value],
            fornecedor: inputFornecedor,
            categoria: inputCategoria,
            situacao_conciliacao: "Não Conciliado",
            tipo_movimentacao: [
                "Transferência",
                lancamento.valor_lancamento > 0 ? "Receita" : "Despesa",
            ],
            selecionados: rowsSelected.map((item) => item.id),
            page_size: pageState.pageSize,
            page: pageState.page,
            tipo_extrato: "movimentacoes",
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1
                }/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1
                }/${end_date.getFullYear()}`;
        }
        api.PostBuscarLancamentos(dataRequest)
            .then((response) => {
                let data = response.data;

                setFilterPlaceholders(data.filter_placeholders);
                setSpecificDate(data?.specific_date || "");
                setPageState((old) => ({
                    ...old,
                    total: data.total_movimentacoes,
                    data: data.movimentacoes,
                }));
                setLoading(false);
                if (data.movimentacoes.length === 0) {
                    dispatch(
                        showSnackMessage({
                            message: "Nenhum registro encontrado",
                            severity: "info",
                        })
                    );
                }
            })
            .catch(() => {
                setLoading(false);
                dispatch(
                    showSnackMessage({
                        message: "Algo deu errado! Tente novamente mais tarde",
                        severity: "error",
                    })
                );
            });
    };

    const renderBanco = (bancoNome) => {
        const bancos = {
            "Banco do Brasil": BancoBrasil,
            Inter: Inter,
            Bradesco: Bradesco,
            Santander: Santander,
            Sicoob: Sicoob,
            Itaú: Itau,
        };

        if (bancos[bancoNome]) {
            return (
                <img
                    src={bancos[bancoNome]}
                    alt={bancoNome}
                    style={{ height: 70, width: 71, borderRadius: "5px" }}
                />
            );
        }

        return (
            <div
                style={{
                    height: 70,
                    width: 71,
                    backgroundColor: YELLOW_BG_UX,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                    borderRadius: "5px",
                }}
            >
                {bancoNome.toUpperCase()}
            </div>
        );
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const handleConciliar = (extratoId, movimentacoesIds) => {
        setLoading(true);
        let dataRequest = {
            extrato_id: extratoId,
            movimentacoes_ids: movimentacoesIds,
            conta: conta,
        };
        api.conciliarLancamentos(dataRequest).then(() => {
            dispatch(
                showSnackMessage({
                    message: "Conciliação realizada com sucesso!",
                    severity: "success",
                })
            );
            setOpenModalBuscarLanc(false);
            getConciliacoesConta();
        });
    };

    const columns = [
        {
            field: "formatted_vencimento",
            headerName: "Vencimento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "descricao",
            headerName: "Descrição",
            flex: 5,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                const categorias = params.row.categoria_nome;
                const recorrencia = params.row.recorrencia;
                const descricao = recorrencia
                    ? `${recorrencia} - ${params.row.descricao}`
                    : params.row.descricao;
                const fornecedor = params.row.fornecedor__nome;
                let title = descricao;
                title += categorias ? `\nCategoria: ${categorias}` : "";
                title += fornecedor ? `\nFornecedor: ${fornecedor}` : "";
                title += recorrencia ? `\nRecorrência: ${recorrencia}` : "";

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            justifyContent: "center",
                            height: "100%",
                        }}
                        title={title}
                    >
                        <Stack alignItems="center" direction="row">
                            {recorrencia && <RepeatIcon fontSize="small" />}
                            <Typography fontWeight="normal" fontSize="15px">
                                {descricao}
                            </Typography>
                        </Stack>
                        <Typography fontSize="15px">
                            <span
                                style={{
                                    backgroundColor: GRAY_LABEL_UX,
                                    padding: "3px 10px",
                                    borderRadius: "3px",
                                    color: temaEscuro
                                        ? LINE_TABLE
                                        : WHITE_TABLE,
                                    fontWeight: "500",
                                }}
                            >
                                {categorias.length > 1
                                    ? `${categorias.length} categorias`
                                    : categorias}
                            </span>
                            <span
                                style={{
                                    fontSize: "12px",
                                    color: BORDER_BUTTON,
                                }}
                            >
                                {fornecedor ? ` ${fornecedor}` : ""}
                            </span>
                        </Typography>
                    </div>
                );
            },
        },
        {
            field: "conta__apelido",
            headerName: "Conta",
            flex: 2,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "situacao",
            headerName: "Situação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span
                        style={{
                            color:
                                params.row.situacao === "Em Atraso" && RED_INFO,
                            fontWeight:
                                params.row.situacao === "Em Atraso"
                                    ? "bold"
                                    : "normal",
                        }}
                    >
                        {params.row.situacao}
                    </span>
                );
            },
        },
        {
            field: "valor_total",
            headerName: "Valor (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span>
                        {manualCurrencyFormatter(params.row.valor_total, 2)}
                    </span>
                );
            },
        },
    ];

    return (
        <Dialog
            fullScreen
            open={openModalBuscarLanc}
            onClose={() => setOpenModalBuscarLanc(false)}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_BODY,
                },
            }}
            data-testid="dialog-buscar-lancamentos"
        >
            {openModalRevisarVal && (
                <RevisarValores
                    lancamento={lancamento}
                    openModalRevisarVal={openModalRevisarVal}
                    setOpenModalRevisarVal={setOpenModalRevisarVal}
                    rowsSelected={rowsSelected}
                    handleConciliar={handleConciliar}
                    pageState={pageState}
                    setRowsSelected={setRowsSelected}
                />
            )}
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{
                            color: temaEscuro
                                ? WHITE_ESCRITA_THEME
                                : GRAY_STRONG_UX,
                            fontSize: TITLE_SIZE,
                            fontWeight: "bold",
                            lineHeight: 1,
                            flex: 1,
                        }}
                    >
                        Escolha os lançamentos para conciliar com o valor do banco
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => setOpenModalBuscarLanc(false)}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    flexWrap: "nowrap",
                                }}
                            >
                                <Grid item>
                                    {renderBanco(lancamento?.banco_nome)}
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        sx={{
                                            display: "flex",
                                            gap: 1,
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                display: "flex",
                                                gap: 1,
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                fontWeight="bold"
                                                fontSize="17px"
                                            >
                                                {lancamento?.valor_lancamento > 0 ? "Recebimento" : "Pagamento"}
                                                {` importado de ${manualCurrencyFormatter(lancamento.valor_lancamento, 2)}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                display: "flex",
                                                gap: 0.1,
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography
                                                fontWeight="normal"
                                                fontSize="14px"
                                            >
                                                Data do lançamento no extrato:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {
                                                        lancamento?.data_lancamento
                                                    }
                                                </span>
                                            </Typography>
                                            <Typography
                                                fontWeight="normal"
                                                fontSize="14px"
                                            >
                                                Descrição:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {lancamento?.descricao}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `0 ${isLargeScreen ? "120px" : "20px"} 120px ${isLargeScreen ? "120px" : "20px"
                            }`,
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} sx={{ marginY: 2 }}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    flexWrap: "wrap",
                                }}
                            >
                                <Grid item>
                                    <FilterEXM
                                        placeholders={filterPlaceholders}
                                        periodo={periodo}
                                        setPeriodo={setPeriodo}
                                        specificDate={specificDate}
                                        setSpecificDate={setSpecificDate}
                                        arrowCounter={arrowCounter}
                                        setArrowCounter={setArrowCounter}
                                        isIgnorado
                                    />
                                    {periodo === "custom_periodo" && (
                                        <DateRangePicker
                                            value={customPeriodo}
                                            onChange={handleDateChange}
                                            localeText={{
                                                start: "Início",
                                                end: "Fim",
                                            }}
                                            format="DD/MM/YYYY"
                                            sx={{
                                                width: "330px",
                                                marginTop: 2,
                                                "& .MuiInputLabel-root": {
                                                    color:
                                                        temaEscuro &&
                                                        BORDER_BUTTON,
                                                },
                                            }}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    sx: {
                                                        backgroundColor:
                                                            temaEscuro
                                                                ? BLUE_THEME
                                                                : GRAY_BG_UX,
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <FilterWithTag
                                        placeholderProp="Conta"
                                        options={contasOptions}
                                        setOptions={setContasSelected}
                                        previousValue={previousContas}
                                        limitSelection={2}
                                        limitTagsOnFocus={true}
                                        widthSetting={"330px"}
                                        optionFilter
                                    />
                                </Grid>
                                {estabelecimentos.length > 1 && 
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={estabelecimentos}
                                            selectedOption={estabelecimentoSelected}
                                            setSelectedOption={setEstabelecimentoSelected}
                                            placeholder={"Estabelecimento"}
                                            width={"250px"}
                                        />
                                    </Grid>
                                }
                                <Grid item>
                                    <FilterWithTag
                                        placeholderProp="Categoria"
                                        options={categorias}
                                        setOptions={setInputCategoria}
                                        previousValue={null}
                                        widthSetting={"250px"}
                                        limitSelection={2}
                                        limitTagsOnFocus={true}
                                        optionFilter
                                    />
                                </Grid>
                                <Grid item>
                                    <FilterWithTag
                                        placeholderProp="Fornecedor"
                                        options={fornecedores}
                                        setOptions={setInputFornecedor}
                                        previousValue={null}
                                        widthSetting={"250px"}
                                        limitSelection={2}
                                        limitTagsOnFocus={true}
                                        optionFilter
                                    />
                                </Grid>
                                <Grid item>
                                    <InputSearch
                                        periodo={periodo}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        functionProps={() =>
                                            getBuscarLancamentos()
                                        }
                                        className="input-text"
                                        EXM
                                    />
                                </Grid>
                                <Grid item>
                                    <DefaultButton
                                        title="Filtrar"
                                        onClick={() =>
                                            getBuscarLancamentos()
                                        }
                                    />
                                </Grid>
                                <Grid item sx={{ marginLeft: "auto" }}>
                                    <OptionsNovaEXM />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ maxHeight: 700 }}>
                            <DataGridWithServerPagination
                                isLoading={loading}
                                data={pageState.data}
                                rowCount={pageState.total}
                                checked={isAdmin || isOperator || isAprover}
                                columns={columns}
                                customRowSelectionModelChange={(item) => {
                                    if (isAdmin || isOperator || isAprover) {
                                        setSelectedItem(item);
                                    }
                                }}
                                exmItems={selectedItem}
                                getExtratoMovimentacoes={
                                    getBuscarLancamentos
                                }
                                setRowsSelected={setRowsSelected}
                                rowHeight={70}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: pageState.pageSize,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderTop: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    top: "auto",
                    bottom: 0,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                }}
            >
                <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <DefaultButton
                        title="cancelar"
                        testId="close-dialog"
                        onClick={() => setOpenModalBuscarLanc(false)}
                    />
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                Valor do lançamento bancário {"("}R${")"}
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    direction: "rtl",
                                    textAlign: "justify",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                {manualCurrencyFormatter(
                                    lancamento.valor_lancamento,
                                    2
                                )}
                            </p>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                Valor selecionado {"("}R${")"}
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    direction: "rtl",
                                    textAlign: "justify",
                                    color: BLUE_INFO_UX,
                                }}
                            >
                                {manualCurrencyFormatter(valorSelecionado, 2)}
                            </p>
                        </div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    color: !temaEscuro && GRAY_LABEL_UX,
                                }}
                            >
                                Diferença {"("}R${")"}
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    direction: "rtl",
                                    textAlign: "justify",
                                    color: YELLOW_INFO_UX,
                                }}
                            >
                                {manualCurrencyFormatter(diferenca, 2)}
                            </p>
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <DefaultButton
                            title={`Revisar valores (${rowsSelected.length})`}
                            onClick={() => setOpenModalRevisarVal(true)}
                            testId="btn-revisar-valores"
                            variant="blue"
                        />
                        <DefaultButton
                            title={"Conciliar"}
                            disabled={
                                valorSelecionado !== lancamento.valor_lancamento
                            }
                            tooltip={
                                valorSelecionado !== lancamento.valor_lancamento
                                    ? "O valor selecionado deve ser igual ao valor do lançamento bancário"
                                    : ""
                            }
                            onClick={() =>
                                handleConciliar(
                                    lancamento.id,
                                    rowsSelected.map(
                                        (movimentacao) => movimentacao.id
                                    )
                                )
                            }
                            variant="yellow"
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

export default BuscarLancamentos;
