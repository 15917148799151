import React, { useEffect }  from "react";
import {
    smallPaperStyle,
    BORDER_TABLE,
    BLUE_THEME, COLOR_LABEL_INPUT, BORDER_BUTTON, BORDER_PAPER,
} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import InputNumber from "../Input/InputNumber";
import InputSearch from "../Input/InputSearch";
import DefaultButton from "../Buttons/DefaultButton";
import Divider from "@mui/material/Divider";
import InputCurrency from "../Input/InputCurrency";
import moment from "moment";
import FilterYear from "../Filter/FilterYear";


const ParcelasModal = (props) => {
    const {
        openModal, setOpenModal, parcelas,
        setParcelas, formaPagamento, valorPagamento,
        condicaoPagamento, descricao, setSelectedVencimento,
        selectedConta, vencimento
    } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        let initialParcelas = [];
        for (let i = 0; i < condicaoPagamento; i++) {
            initialParcelas.push({
                data_vencimento: moment(vencimento).add(i, "months").toDate(),
                valor: valorPagamento / condicaoPagamento,
                percentual: (valorPagamento / condicaoPagamento * 100 / valorPagamento).toFixed(2),
                descricao: descricao + ` ${i + 1}/${condicaoPagamento}`,
            });
        }
        setParcelas(initialParcelas);
    }, [valorPagamento, condicaoPagamento, descricao, vencimento]);
    

    const updateNextParcelas = (idx, restoValorPagamento, updatedParcelas) => {
        for (let i = idx + 1; i < parcelas.length; i++) {
            let valorParcela = (restoValorPagamento / (parcelas.length - i)).toFixed(2);
            updatedParcelas.map((parcela, idx_aux) => {
                if (idx_aux === i) {
                    parcela.valor = valorParcela;
                    parcela.percentual = (parseFloat(valorParcela) * 100 / valorPagamento).toFixed(2);
                }
                return parcela;
            });
            restoValorPagamento -= parseFloat(valorParcela);
        }
        setParcelas(updatedParcelas);
    };

    const handleDayMonthYear = (ano, mes, dia, idx) => {
        setParcelas(parcelas.map((parcela, idx_aux) => {
            if (idx_aux === idx) {
                parcela.data_vencimento = moment(`${ano}-${mes}-${dia}`).toDate();
            }
            return parcela;
        }));
        if (idx === 0) {
            setSelectedVencimento(moment(`${ano}-${mes}-${dia}`).toDate());
        }
    };

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper data-testid="editar-parcelas-container" sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, pt: 3, width: "50%", maxWidth: null, maxHeight: "90vh", overflowY: "auto"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span><p className="title-container-p">Editar parcelas</p></span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                        </Grid>
                        <Grid item sx={{mb: 10}}>
                            <Grid container spacing={3}>
                                <Grid item sx={{mr: "200px"}}>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <span><p className="item-container-label" style={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_BUTTON}}>Forma de pagamento</p></span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span><p className="item-container-p">{formaPagamento}</p></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <span><p className="item-container-label" style={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_BUTTON}}>Valor a pagar (R$)</p></span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span><p className="item-container-p">{valorPagamento}</p></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <span><p className="item-container-label" style={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_BUTTON}}>Condição de pagamento</p></span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span><p className="item-container-p">{condicaoPagamento}</p></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <span><p className="title-container-p">Parcelas</p></span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                        </Grid>
                        <Grid item xs={12}>
                           <Grid container spacing={2}>
                               {
                                   parcelas.map((parcela, idx) => {
                                       return (
                                           <React.Fragment key={idx}>
                                               <Grid item xs={12}>
                                                   <Grid container spacing={2}>
                                                       <Grid item sx={{mt: 1.25}}>
                                                           <a style={{textAlign: "center"}}>{idx + 1}/{condicaoPagamento}</a>
                                                       </Grid>
                                                       <Grid item>
                                                           {
                                                               selectedConta?.tipo === "Cartão de Crédito" ? (
                                                                   <FilterYear
                                                                       handleSubmit={handleDayMonthYear}
                                                                       diaVencimento={selectedConta?.dia_vencimento_cartao}
                                                                       value={parcela.data_vencimento}
                                                                       setState={idx}
                                                                       label={"Vencimento"}
                                                                       width={"250px"}
                                                                   />
                                                               ) : (
                                                                  <FilterDayMonthYear
                                                                      style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                                      handleSubmit={handleDayMonthYear}
                                                                      size={"small"}
                                                                      width={"155px"}
                                                                      iconPosition={"end"}
                                                                      setState={idx}
                                                                      value={parcela.data_vencimento}
                                                                  />
                                                               )
                                                           }
                                                       </Grid>
                                                       <Grid item>
                                                           <InputCurrency
                                                               valor={parcela.valor}
                                                               setValor={(value) => {
                                                                    let updatedParcelas = [...parcelas];
                                                                    let informedValues = 0.0;

                                                                    for (let i = 0; i === idx; i++) {
                                                                        informedValues += parseFloat(parcelas[i].valor);
                                                                    }

                                                                    informedValues += parseFloat(value);

                                                                    updatedParcelas.map((parcela_aux, idx_aux) => {
                                                                        if (idx_aux === idx) {
                                                                            parcela_aux.valor = parseFloat(value);
                                                                            parcela_aux.percentual = (parseFloat(value) * 100 / valorPagamento).toFixed(2);
                                                                        }
                                                                        return parcela_aux;
                                                                    });

                                                                    updateNextParcelas(idx, valorPagamento - informedValues, updatedParcelas);
                                                               }}
                                                               width={"150px"}
                                                               disabled={idx === (parcelas.length - 1)}
                                                               title={"Valor"}
                                                               error={parcela?.error}
                                                           />
                                                       </Grid>
                                                       <Grid item>
                                                           <InputNumber
                                                               label={"Percentual"}
                                                               value={parcela.percentual}
                                                               setValue={(value) => {
                                                                    let updatedParcelas = [...parcelas];
                                                                    let informedValues = 0.0;

                                                                    for (let i = 0; i < idx; i++) {
                                                                        informedValues += parseFloat(parcelas[i].valor);
                                                                    }

                                                                    informedValues += parseFloat(valorPagamento * (value / 100));

                                                                    updatedParcelas.map((parcela_aux, idx_aux) => {
                                                                        if (idx_aux === idx) {
                                                                            parcela_aux.percentual = parseFloat(value).toFixed(2);
                                                                            parcela_aux.valor = parseFloat((valorPagamento * value / 100).toFixed(2));
                                                                        }
                                                                        return parcela_aux;
                                                                    }
                                                                    );
                                                                    updateNextParcelas(idx, valorPagamento - informedValues, updatedParcelas);
                                                               }}
                                                               width={"150px"}
                                                               disabled={idx === (parcelas.length - 1)}
                                                               error={parcela?.error}
                                                           />
                                                       </Grid>
                                                       <Grid item>
                                                           <InputSearch
                                                                label="Descrição"
                                                                setSearchValue={(value) => (setParcelas(parcelas.map((parcela_aux, idx_aux) => {
                                                                   if (idx_aux === idx) {
                                                                       parcela_aux.descricao = value;
                                                                   }
                                                                   return parcela_aux;
                                                                })))}
                                                                searchValue={parcela.descricao}
                                                                width={"350px"}
                                                           />
                                                       </Grid>
                                                   </Grid>
                                               </Grid>
                                           </React.Fragment>
                                       );
                                   })
                               }
                           </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 4}}>
                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                <Grid item>
                                    <DefaultButton
                                        testId={"cancel-button"}
                                        onClick={() => setOpenModal(false)}
                                        title={"Cancelar"}
                                    />
                                </Grid>
                                <Grid item>
                                    <DefaultButton
                                        onClick={() => setOpenModal(false)}
                                        title={"Concluir"}
                                        variant="yellow"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default ParcelasModal;