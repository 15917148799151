import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { BLUE_THEME, GRAY_BG_UX, BORDER_BUTTON, RED_INFO, BLUE_INFO_UX } from "../../shared/utils";
import LaunchIcon from "@mui/icons-material/Launch";

const InputSearch = (props) => {
    const { functionProps, searchValue, setSearchValue, inputError, EXM, isRequired, isCompra = false, detalheCompras = null } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [inputValue, setInputValue] = useState(searchValue);

    useEffect(() => {
        setInputValue(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (EXM) {
            setSearchValue(inputValue);
        }
    }, [inputValue]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            functionProps();
        }
    };

    return (
        <Box>
            <TextField
                required={isRequired}
                label={props?.label || "Pesquisar"}
                id="input-with-icon-textfield"
                className={props.className}
                variant="outlined"
                disabled={props.disabled}
                value={inputValue ? inputValue : ""}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
                data-testid={props?.testId ? props?.testId : "input-search"}
                onBlur={() => setSearchValue(inputValue)}
                sx={{
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                    width: props?.width || "250px",
                    padding: props?.padding,
                    "& .MuiFormLabel-asterisk": {
                        color: RED_INFO,
                    },
                    "& .MuiOutlinedInput-root": {
                        "&.MuiInputBase-root fieldset": {
                            borderColor: isCompra && BLUE_INFO_UX,
                        },
                    },
                }}
                size="small"
                error={inputError}
                onKeyDown={handleKeyDown}
                InputProps={props?.callFunction ? {
                    endAdornment: (
                        <InputAdornment sx={{cursor: "pointer"}} position="end">
                            <SearchIcon onClick={() => functionProps()} />
                        </InputAdornment>
                    ),
                } : isCompra && {
                    endAdornment: (
                        <InputAdornment position="end">
                            <LaunchIcon sx={{ color: BLUE_INFO_UX, cursor: "pointer" }} onClick={() => detalheCompras()} />
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                }}
            />
        </Box>
    );
};

export default React.memo(InputSearch);