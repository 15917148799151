import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
    BLUE_THEME,
    BORDER_BUTTON,
    BORDER_TABLE,
    GRAY_BG_UX,
    handleDayMonthYear,
    manualCurrencyFormatter,
    WHITE_ESCRITA_THEME,
} from "../../shared/utils";
import FilterSingleOption from "../Filter/FilterSingleOption";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import TextField from "@mui/material/TextField";
import moment from "moment";
import InputCurrency from "../Input/InputCurrency";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AnexoUploaderModal from "../Modal/AnexoUploaderModal";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import DefaultButton from "../Buttons/DefaultButton";

export default function InformarPagamentoDrawer(props) {
    const dispatch = useDispatch();

    const {contas, selectedContaId, contaCorrenteVinculada, dataVencimento, valorFatura, setLoading} = props;
    const [open, setOpen] = useState(false);
    const [contaOrigem, setContaOrigem] = useState(contaCorrenteVinculada);
    const [contaDestino, setContaDestino] = useState(selectedContaId);
    const [descricaoTransferencia, setDescricaoTransferencia] = useState(`Pagamento fatura ${manualCurrencyFormatter(Math.abs(valorFatura))}`);
    const [dataTransferencia, setDataTransferencia] = useState(moment(dataVencimento, "DD/MM/YYYY").toDate());
    const [valorTransferencia, setValorTransferencia] = useState(Math.abs(valorFatura));
    const [openUploaderModal, setOpenUploaderModal] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (valorFatura != undefined) {
            setValorTransferencia(Math.abs(valorFatura));
            setDescricaoTransferencia(`Pagamento fatura ${manualCurrencyFormatter(Math.abs(valorFatura))}`);
        }
    }, [valorFatura]);

    const checkData = () => {
        if (contaOrigem === "" || contaDestino === ""){
            setError(true);
            setErrorMessage("As contas de origem e destino são obrigatórias");
        }
        else if (contaOrigem === contaDestino){
            setError(true);
            setErrorMessage("As contas de origem e destino não podem ser iguais");
        }
        else if (valorTransferencia <= 0){
            setError(true);
            setErrorMessage("O valor da transferência deve ser maior que zero");
        }
        else if (dataTransferencia === ""){
            setError(true);
            setErrorMessage("A data de transferência é obrigatória");
        }
        else {
            setError(false);
            setErrorMessage("");
            postTranferencia();
        }
    };

    const postTranferencia = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("conta_origem", contaOrigem.label);
        formData.append("conta_destino", contaDestino.label);
        formData.append("valor", valorTransferencia);
        formData.append("data_transferencia", moment(dataTransferencia).format("DD/MM/YYYY"));
        formData.append("descricao", descricaoTransferencia);

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        api.PostTransferencia(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Transferência criada com sucesso!", severity: "success" }));
            setOpen(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: error ? error.response.data : "Algo deu errado! Tente novamente mais tarde", severity: error ? "info" : "error" }));
        });
    };

    return (
        <React.Fragment>
            <AnexoUploaderModal
                openModal={openUploaderModal}
                setOpenModal={setOpenUploaderModal}
                setAnexos={setAnexos}
                changesList={[]}
                setChangesList={() => {}}
                anexos={anexos}
                setPrevAnexos={() => {}}
            />
            <DefaultButton
                testId="informar-pagamento"
                title="Informar Pagamento"
                onClick={() => setOpen(true)}
                variant="yellow"
            />
            <Drawer
                anchor={"right"}
                open={open}
                onClose={() => setOpen(false)}
                transitionDuration={400}
            >
                <Grid container rowSpacing={3} sx={{display: "flex", width: 500}} data-testid="nova-transferencia-informar-pagamento">
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{display: "flex", alignItems: "center", ml: 1}}>
                            <Grid item>
                                <IconButton onClick={() => setOpen(false)}><KeyboardBackspaceIcon/></IconButton>
                            </Grid>
                            <Grid item>
                                <h2>Nova transferência entre contas</h2>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{
                            border: 0,
                            borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                            // mb: 2.5,
                            ml: 2,
                            mr: 2
                        }}/>
                    </Grid>
                    <Grid item xs={12} sx={{ml: 2}}>
                        <FilterSingleOption
                            isRequired
                            filterOptions={contas}
                            selectedOption={contaOrigem}
                            setSelectedOption={setContaOrigem}
                            placeholder={"Conta de origem"}
                            width={"400px"}
                            testId="filter-conta-origem"
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ml: 2}}>
                        <FilterSingleOption
                            isRequired
                            filterOptions={contas}
                            selectedOption={contaDestino}
                            setSelectedOption={setContaDestino}
                            placeholder={"Conta de destino"}
                            width={"400px"}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ml: 2}}>
                        <TextField
                            label={"Descrição *"}
                            value={descricaoTransferencia}
                            onChange={(event) => setDescricaoTransferencia(event.target.value)}
                            sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, width: "400px"}}
                            size={"small"}
                            InputLabelProps={{
                                style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ml: 2}}>
                        <FilterDayMonthYear
                            handleSubmit={handleDayMonthYear}
                            value={dataTransferencia}
                            setState={setDataTransferencia}
                            size={"small"}
                            width={"400px"}
                            iconPosition={"end"}
                            label={"Data da transferência"}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ml: 2}}>
                        <InputCurrency
                            valor={valorTransferencia}
                            setValor={setValorTransferencia}
                            title={"Valor da transferência"}
                            width={"400px"}
                            error={error}
                            dataTestId="input-valor-transferencia"
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ml: 2}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <DefaultButton
                                    testId="button-anexar-certificado"
                                    title="Arquivo"
                                    onClick={() => setOpenUploaderModal(true)}
                                    startIcon={<AttachFileOutlinedIcon/>}
                                />
                            </Grid>
                            {anexos.length > 0 && anexos.map((anexo, index) => (
                                <Grid item xs={12} key={index}>
                                    <a>{anexo.arquivo?.name}</a>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{
                        border: 0,
                        borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                        ml: 2,
                        mr: 2
                    }}/>
                    {error && <Grid item xs={12} sx={{ml: 2}}> <a style={{color: "red"}}>{errorMessage}</a> </Grid>}
                    <Grid item xs={12} sx={{ml: 2}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <DefaultButton title="Cancelar" onClick={() => setOpen(false)}/>
                            </Grid>
                            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", pr: 2}}>
                                <DefaultButton
                                    title="Salvar"
                                    onClick={() => checkData()}
                                    testId="btn-confirmar-transferencia"
                                    variant="yellow"
                                    disabled={contaOrigem === null || contaDestino === null}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment>
    );
}