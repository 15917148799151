import React, {useEffect, useState}  from "react";
import Button from "@mui/material/Button";
import {
	styleButton,
	muiRootStyle,
	textCompleteStyleBlack,
	WHITE_THEME_BLACK,
	BLUE_THEME,
	GRAY_LABEL_UX,
	BORDER_BUTTON,
	getCurrentDate,
	getFullMonthDate,
	WHITE_ESCRITA_THEME,
	RED_INFO,
	DARK_YELLOW_TEXT_UX,
	LIGHT_YELLOW_TEXT_UX
} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {useSelector} from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { format } from "date-fns";


const FilterDayMonthYear = (props) => {

	const {handleSubmit, optionFilter, width, isConta, isContaPoupanca, isEditConta, inputError, gerarCompra, isRequired} = props;

	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	
	const [selectedDate, setSelectedDate] = useState();
	const currentMonthDate = isConta ? moment(getFullMonthDate()).toDate() : moment(getCurrentDate()).toDate();

	const [disableMinDate, setDisableMinDate] = useState(false);

	useEffect(() => {
		if (props?.value) {
			setSelectedDate(props.value);
		} else if (props?.value === null) {
			setSelectedDate(null);
		} else {
			setSelectedDate(currentMonthDate);
		}
	}, [props?.value]);

	useEffect(() => {
		if (isContaPoupanca || isEditConta || props?.disableMinDate) {
			setDisableMinDate(true);
		}
	}, []);

	useEffect(() => {
		if (props?.forceRerender) {
			setSelectedDate(props.value);
		}
	}, [props.value]);

	const getDayMonthYear = (newValue) => {
		let date =  selectedDate;
		if (newValue instanceof Date){
			date = newValue;
		}
		const formattedDia = moment(date).format("DD");
		const formattedMes = moment(date).format("MM");
		const formattedAno = moment(date).format("YYYY");
		handleSubmit(formattedAno, formattedMes, formattedDia, props?.setState);
	};

	return (
		<Grid container sx={{display: "flex", alignItems: "center", width: width}} >
			<Grid item xs={12}>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
					<DatePicker
						label={props?.label}
						views={["year", "month", "day"]}
						dayOfWeekFormatter={(weekday) => {
							return `${format(weekday, "EEE", { locale: ptBR }).slice(0, 3).toLocaleUpperCase()}`;
						}}
						shouldDisableDate={props?.shouldDisableDate}
						minDate={disableMinDate ? null : gerarCompra ? moment(currentMonthDate).subtract(3, "months").toDate() : currentMonthDate}
						maxDate={isConta && new Date()}
						value={selectedDate || props?.value}
						sx={border}
						onChange={(newValue) => {
							setSelectedDate(newValue);
							if (!optionFilter){
								getDayMonthYear(newValue);
							}
						}}
                        disabled={props?.disabled}
						slots={{
							openPickerIcon: CalendarTodayOutlinedIcon,
						}}
						slotProps={{
							inputAdornment: {position: props?.iconPosition ? props.iconPosition : "start"},
							textField: { 
								size: "small",
								inputProps: {"data-testid": props?.testId},
								helperText: null,
								error: inputError,
								sx: {
									...temaEscuro ? textCompleteStyleBlack : muiRootStyle,
									backgroundColor: (temaEscuro && !props.disabled) ? BLUE_THEME : undefined,
									"& .MuiInputLabel-root": {
										color: temaEscuro ? BORDER_BUTTON : undefined,
										fontSize: "15px"
									},
									"& .MuiFormLabel-asterisk": {
										color: (isConta || isContaPoupanca || isRequired) && RED_INFO
									}
								},
								style: {
									width: props?.width,
									...(!props?.disabled && {backgroundColor:temaEscuro && BLUE_THEME})
								},
								InputLabelProps: {
									style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" },
								},
                                disabled: props?.disableText
							},
							layout: {
								sx: {
									"& .MuiDayCalendar-weekDayLabel": {
										color: temaEscuro ? DARK_YELLOW_TEXT_UX : LIGHT_YELLOW_TEXT_UX,
									},
								},
							},
						}}
					/>
				</LocalizationProvider>
				{
					isConta && (
						<span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 300, fontSize: "12px"}}>Informe uma data até {moment(getCurrentDate()).format("DD/MM/YYYY")} com formato dd/mm/aaaa. </span>
					)
				}
			</Grid>
			{
				optionFilter &&
				(
					<Grid item xs={7}>
						<Button
							variant="contained"
							data-testid="button-filter-year"
							onClick={getDayMonthYear}
							startIcon={<FilterListOutlinedIcon/>}
							style={{...styleButton, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, borderColor:temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}}
						>
							Filtrar
						</Button>
					</Grid>
				)
			}
		</Grid>
	);

};

export default React.memo(FilterDayMonthYear);

const border ={
	"& .css-126wocv-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root fieldset": {
		border: "none"
	}
};