import React, {useState, useEffect} from "react";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    YELLOW_BG_UX,
    GRAY_BG_UX,
    GRAY_LABEL_UX_THEME,
    format,
    DASH_KPI_DATA_COLOR,
    BLACK_TABLE,
    RED_INFO,
    BLACK_LABEL_UX,
    handleDayMonthYear, formatterCurrency,
    DARK_GREEN_BG_UX,
    DARK_GREEN_TEXT_UX,
    DARK_YELLOW_BG_UX,
    LIGHT_YELLOW_BG_UX,
    DARK_YELLOW_TEXT_UX,
    LIGHT_YELLOW_TEXT_UX,
    GRAY_LABEL_UX,
    BLUE_INFO_UX,
    DASH_KPI_POSITIVE_COLOR,
    DARK_BLUE_BG_UX,
    DARK_BLUE_TEXT_UX,
    LIGHT_GREEN_BG_UX,
    LIGHT_BLUE_BG_UX,
    LIGHT_GREEN_TEXT_UX,
    LIGHT_BLUE_TEXT_UX,
    BLACK_TABLE_PERFIL,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    getInputsOptions
} from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../../../axios";
import Anexos from "../../../components/Tabs/Anexos";
import {showSnackMessage} from "../../../actions/SnackActions";
import InputSearch from "../../../components/Input/InputSearch";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import Paper from "@mui/material/Paper";
import { Skeleton } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DataGrid from "../../../components/DataGrid/DataGrid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RateiosTab from "../../../components/Tabs/RateiosTab";
import CreateOptionModal from "../../../components/Modal/CreateOptionModal";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditarVisaoCompetencia = (props) => {

    const { idMovimentacao, recorrencia, tipoLancamento, open, setOpen, recalculatePagePrevious, condicaoPagamento } = props;

    const nav = useNavigate();
    const dispatch = useDispatch();
    const id_movimentacao = idMovimentacao;
    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isAprover);

    const [fornecedor, setFornecedor] = useState("");
    const [cliente, setCliente] = useState("");
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [selectedDataCompetencia, setSelectedDataCompetencia] = useState("");
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [valorTotal, setValorTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [tipoSelected, setTipoSelected] = useState("");
    const [selectedVencimento, setSelectedVencimento] = useState("");
    const [inputError, setInputError] = useState(false);
    const [data, setData] = useState([]);
    const [dataRecorrencia, setDataRecorrencia] = useState([]);
    const [valorParcelaAberto, setValorParcelaAberto] = useState(0);
    const [valorParcelaPago, setValorParcelaPago] = useState(0);
    const [valorMulta, setValorMulta] = useState(0);
    const [valorJuros, setValorJuros] = useState(0);
    const [valorDesconto, setValorDesconto] = useState(0);
    const [valorParcelaMulta, setValorParcelaMulta] = useState(0);
    const [valorParcelaJuros, setValorParcelaJuros] = useState(0);
    const [valorParcelaDesconto, setValorParcelaDesconto] = useState(0);
    const [strRecorrencia, setStrRecorrencia] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [dataCondicaoPagamento, setDataCondicaoPagamento] = useState([]);
    const [valorTotalPagoParcelas, setValorTotalPagoParcelas] = useState(0);
    const [valorTotalPago, setValorTotalPago] = useState(0);
    const [valorDescontoTotal, setValorDescontoTotal] = useState(0);
    const [valorJurosTotal, setValorJurosTotal] = useState(0);
    const [valorMultaTotal, setValorMultaTotal] = useState(0);
    const [valorParcelaVencida, setValorParcelaVencida] = useState(0);
    const [valorTotalVencida, setValorTotalVencida] = useState(0);
    const [valorMultaVencida, setValorMultaVencida] = useState(0);
    const [valorJurosVencido, setValorJurosVencida] = useState(0);
    const [valorDescontoVencido, setValorDescontoVencida] = useState(0);
    const [valorTotalPagar, setValorTotalPagar] = useState(0);
    const [valorParcelaPagar, setValorParcelaPagar] = useState(0);
    const [valorMultaPagar, setValorMultaPagar] = useState(0);
    const [valorJurosPagar, setValorJurosPagar] = useState(0);
    const [valorDescontoPagar, setValorDescontoPagar] = useState(0);
    const [valorParcelaAbertoCondicao, setValorParcelaAbertoCondicao] = useState(0);
    const [valorTotalAberto, setValorTotalAberto] = useState(0);
    const [valorMultaAberto, setValorMultaAberto] = useState(0);
    const [valorDescontoAberto, setValorDescontoAberto] = useState(0);
    const [valorJurosAberto, setValorJurosAberto] = useState(0);
    const [isCompra, setIsCompra] = useState(false);
    const [totalRateios, setTotalRateios] = useState(0);
    const [diferencaRateios, setDiferencaRateios] = useState(0);
    const [movimentacaoId, setMovimentacaoId] = useState("");

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");

    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [fornecedorOptions, setFornecedorOptions] = useState([]);
    const [clientesOptions, setClientesOptions] = useState([]);

    const [loadingAnexos, setLoadingAnexos] = useState(true);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [rateios, setRateios] = useState([{
        categoria: {"label": null, "value": null},
        valor: 0,
        percentual: 0,
        centro_custo: null,
    }]);

    const [previousRateios, setPreviousRateios] = useState([{
        categoria: {"label": null, "value": null},
        valor: 0,
        percentual: 0,
        centro_custo: null,
    }]);

    const viewOnly = !(isAdmin || isOperator || isAprover);


    useEffect(() => {
         if (open) {
             getInfoLancamento();
         }
     }, [open]);

    useEffect(() => {
        if (selectedTab === "anexo") {
            getAnexos();
        }
    }, [selectedTab]);

    useEffect(() => {
        if (open === false) {
            setSelectedTab("observacoes");
        }
    }, [open]);

    useEffect(() => {
        const novoTotalRateios = rateios.reduce((acc, rateio) => acc + Math.abs(rateio.valor || 0), 0);
        const novaDiferencaRateios = parseFloat((valorTotal - novoTotalRateios).toFixed(2));

        setTotalRateios(novoTotalRateios);
        setDiferencaRateios(novaDiferencaRateios);
    }, [rateios]);

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            lancamento_id: id_movimentacao,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        }).catch(() => {
            setLoadingAnexos(false);
            dispatch(showSnackMessage({message: "Erro ao buscar anexos", severity: "error"}));
        });
    };

    const getInfoLancamento = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            lancamento_id: id_movimentacao,
            state: "visao_competencia",
            condicao_pagamento: condicaoPagamento,
        };
        api.GetInfoMovimentacao(dataRequest).then((response) => {
            const data = response.data;
            setFornecedor(data.fornecedor_cliente);
            setCliente(data.fornecedor_cliente);
            setTipoSelected(data.tipo_despesa_receita);
            setSelectedDataCompetencia(moment(data.data_competencia, "DD-MM-YYYY").toDate());
            setSelectedCategoria(data.categoria);
            setSelectedCentroCusto(data.centro_custo);
            setCodigoReferencia(data.codigo_referencia);
            setSelectedVencimento(moment(data.vencimento, "DD-MM-YYYY").toDate());
            setDescricao(data.descricao);
            setFornecedorOptions(data.inputs_options.input_fornecedor_cliente);
            setClientesOptions(data.inputs_options.input_fornecedor_cliente);
            setCategoriaOptions(data.inputs_options.input_categoria);
            setCentroCustoOptions(data.inputs_options.input_centro_custo);
            setValorTotal(data.valor_total);
            setData(data.detalhe_contas_pagar);
            setValorParcelaAberto(data.valor_parcela_aberto);
            setValorParcelaPago(data.valor_parcela_pago);
            setValorMulta(data.valor_multa);
            setValorJuros(data.valor_juros);
            setValorDesconto(data.valor_desconto);
            setValorParcelaMulta(data.valor_parcela_multa);
            setValorParcelaJuros(data.valor_parcela_juros);
            setValorParcelaDesconto(data.valor_parcela_desconto);
            setStrRecorrencia(data.recorrencia?.str_recorrencia);
            setDataRecorrencia(data.recorrencia?.datagrid_data_recorrencia);
            setDataCondicaoPagamento(data.condicao_pagamento?.data_parcelas);
            setValorTotalPago(data.condicao_pagamento?.valor_pago);
            setValorTotalPagoParcelas(data.condicao_pagamento?.valor_pago_parcelas);
            setValorDescontoTotal(data.condicao_pagamento?.valor_desconto_total);
            setValorMultaTotal(data.condicao_pagamento?.valor_multa_total);
            setValorJurosTotal(data.condicao_pagamento?.valor_juros_total);
            setValorTotalVencida(data.condicao_pagamento?.valor_total_vencido);
            setValorTotalPagar(data.condicao_pagamento?.valor_total_a_pagar);
            setValorParcelaVencida(data.condicao_pagamento?.valor_parcelas_vencidas);
            setValorMultaVencida(data.condicao_pagamento?.valor_multa_vencida);
            setValorJurosVencida(data.condicao_pagamento?.valor_juros_vencido);
            setValorDescontoVencida(data.condicao_pagamento?.valor_desconto_vencido);
            setValorParcelaPagar(data.condicao_pagamento?.valor_parcelas_pagar);
            setValorMultaPagar(data.condicao_pagamento?.valor_a_pagar_multa);
            setValorJurosPagar(data.condicao_pagamento?.valor_a_pagar_juros);
            setValorDescontoPagar(data.condicao_pagamento?.valor_a_pagar_desconto);
            setValorParcelaAbertoCondicao(data.condicao_pagamento?.valor_parcelas_aberto);
            setValorTotalAberto(data.condicao_pagamento?.valor_total_aberto);
            setValorMultaAberto(data.condicao_pagamento?.valor_multa_aberto);
            setValorDescontoAberto(data.condicao_pagamento?.valor_desconto_aberto);
            setValorJurosAberto(data.condicao_pagamento?.valor_juros_aberto);
            setRateios(data.rateios);
            setPreviousRateios(data.rateios);
            setMovimentacaoId(data.movimentacao.id);
            if (data.item_compra) {
                setIsCompra(true);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
     };

     const processAnexosData = (anexos_data) => {
         if (anexos_data.length === 0) {
             setAnexos([
                 {
                     forma_anexo: "Arquivo",
                     arquivo: null,
                     tipo_anexo: "Contrato",
                     descricao: "",
                 }
             ]);
             return;
         }

         let anexos_list = [];

         for (let i = 0; i < anexos_data.length; i++) {
             const byteCharacters = atob(anexos_data[i].arquivo);
             const byteNumbers = new Array(byteCharacters.length);
    
             for (let i = 0; i < byteCharacters.length; i++) {
                 byteNumbers[i] = byteCharacters.charCodeAt(i);
             }
    
             const byteArray = new Uint8Array(byteNumbers);
    
             const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });
    
             let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
            
             anexos_list.push({
                 forma_anexo: anexos_data[i].forma_anexo,
                 arquivo: file,
                 tipo_anexo: anexos_data[i].tipo_anexo,
                 descricao: anexos_data[i].descricao,
             });
         }

         setAnexos(anexos_list);
    };

    const handleEditPagarReceber = () => {
        const isInvalid =
            (tipoLancamento === "Despesa" && !fornecedor) ||
            (tipoLancamento === "Receita" && !cliente) ||
            !selectedDataCompetencia ||
            !selectedCategoria ||
            !codigoReferencia ||
            (!condicaoPagamento && !selectedVencimento) ||
            !descricao;

        if (isInvalid) {
            setInputError(true);
            return;
        }

        setLoading(true);
        setLoadingAnexos(true);

        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("movimentacao_id", id_movimentacao);
        formData.append(
            "fornecedor",
            tipoLancamento === "Despesa"
                ? fornecedor?.value ?? null
                : cliente?.value ?? null
        );
        formData.append("data_compra", moment(selectedDataCompetencia).format("DD/MM/YYYY"));
        formData.append("categoria_movimentacao", selectedCategoria.value);
        formData.append("codigo_referencia", codigoReferencia);
        if (condicaoPagamento === 1) {
            formData.append("vencimento_movimentacao", moment(selectedVencimento).format("DD/MM/YYYY"));
        }
        formData.append("descricao_movimentacao", descricao);
        formData.append("centro_custo", selectedCentroCusto?.value || null);
        formData.append("tipo_movimentacao", "visao_competencia");

        formData.append("observacoes", observacoes);
        formData.append("anexos", JSON.stringify(anexos));

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        if (previousRateios !== rateios) {
            if (rateios.length === 0) {
                formData.append("rateios", JSON.stringify([{
                    categoria: {"label": selectedCategoria.label, "value": selectedCategoria.value},
                    valor: (valorTotal + valorJuros + valorMulta) - valorDesconto,
                    percentual: 100,
                    centro_custo: null
                }]));
            } else {
                formData.append("rateios", JSON.stringify(rateios));
            }
        }
         
        api.EditarMovimentacao(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Lançamento editado com sucesso!", severity: "success"}));
            closeModal();
            recalculatePagePrevious();
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const closeModal = () => {
        setInputError(false);
        setOpen(false);
        setObservacoes("");
    };

    const getBackgroudColor = (situacao) => {
        let color;
        if (situacao === "Pago" || situacao === "Recebido") {
            color = temaEscuro ? DARK_GREEN_BG_UX : LIGHT_GREEN_BG_UX;
        } else if (situacao === "Em Aberto") {
            color = temaEscuro ? DARK_YELLOW_BG_UX : LIGHT_YELLOW_BG_UX;
        } else {
            color = temaEscuro ? DARK_BLUE_BG_UX : LIGHT_BLUE_BG_UX;
        }
        return color;
    };

    const getColor = (situacao) => {
        let color;
        if (situacao === "Pago" || situacao === "Recebido") {
            color = temaEscuro ? DARK_GREEN_TEXT_UX : LIGHT_GREEN_TEXT_UX;
        } else if (situacao === "Em Aberto") {
            color = temaEscuro ? DARK_YELLOW_TEXT_UX : LIGHT_YELLOW_TEXT_UX;
        } else {
            color = temaEscuro ? DARK_BLUE_TEXT_UX : LIGHT_BLUE_TEXT_UX;
        }
        return color;
    };

    const getTextTitle = () => {
        return tipoLancamento === "Despesa" ? "Informações detalhadas do pagamento" : "Informações detalhadas do recebimento";
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const columns = [
        {
            field: "formatted_data_movimentacao",
            headerName: "Data",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "forma_pagamento_formatted",
            headerName: "Forma de Pagamento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "conta__apelido",
            headerName: "Conta",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor",
            headerName: "Valor (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.valor < 0 && RED_INFO,
                        fontWeight: params.row.valor < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.valor, 2)}
                </span>

                );
            }
        },
        {
            field: "furos_multa",
            headerName: "Juros/Multa (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.furos_multa < 0 && RED_INFO,
                        fontWeight: params.row.furos_multa < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.juros_multa, 2)}
                </span>

                );
            }
        },
        {
            field: "desconto",
            headerName: "Desconto (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.desconto < 0 && RED_INFO,
                        fontWeight: params.row.desconto < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.desconto, 2)}
                </span>

                );
            }
        },
        {
            field: "situacao",
            headerName: "Situação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                    }}>
                        <Typography
                            style={{
                                backgroundColor: getBackgroudColor(params.row.situacao),
                                color: getColor(params.row.situacao),
                                borderRadius: "5px",
                                padding: "3px 10px",
                                fontSize: "15px",
                                textAlign: "center",
                                width: "50%",
                            }}
                        >
                            {params.row.situacao}
                        </Typography>
                    </div>
                );
            }
        },
    ];

    const columnsCondicaoPagamento = [
        {
            field: "data",
            headerName: "Data",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "numero_total",
            headerName: "Parcela",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "forma_pagamento",
            headerName: "Forma de pagamento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "conta",
            headerName: "Conta",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor_parcela",
            headerName: "Valor (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span>
                        {formatterCurrency(params.row.valor_parcela, 2)}
                    </span>
                );
            }
        },
        {
            field: "valor_pago",
            headerName: "Pago (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span>
                        {formatterCurrency(params.row.valor_pago, 2)}
                    </span>
                );
            }
        },
        {
            field: "valor_pendente",
            headerName: "Em aberto (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span>
                        {formatterCurrency(params.row.valor_pendente, 2)}
                    </span>
                );
            }
        },
        {
            field: "situacao",
            headerName: "Situacao",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                    }}>
                        <Typography
                            style={{
                                backgroundColor: getBackgroudColor(params.row.situacao),
                                color: getColor(params.row.situacao),
                                borderRadius: "5px",
                                padding: "3px 10px",
                                fontSize: "15px",
                                textAlign: "center",
                                width: "50%",
                            }}
                        >
                            {params.row.situacao}
                        </Typography>
                    </div>
                );
            }
        },
    ];

    const columnsRecorrencia = [
        {
            field: "data_competencia_formatted",
            headerName: "Data de criação",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "data_vencimento_formatted",
            headerName: "Data de vencimento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "descricao",
            headerName: "Descrição",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor_total",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
        }
    ];

    const getHeaderTitle = () => {
        return tipoLancamento === "Despesa" ? `Editar despesa parcelada em ${condicaoPagamento}x` : `Editar receita parcelada em ${condicaoPagamento}x`;
    };

    const handleOpenModalEditCompra = () => {
        const tabAux = "compras-tab";
        nav("/notas_compras", { state:
            {detalhe: 
                { 
                    movimentacaoId: movimentacaoId,
                    tab: tabAux
                } 
            } 
        });
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                    backgroundImage: "none"
                },
            }}
            data-testid="movimentacao-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? BLUE_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        {getHeaderTitle()}
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }} data-testid="exm-container-editar-visao-competencia">
                <CreateOptionModal
                    openModal={openCreateModal}
                    setOpenModal={setOpenCreateModal}
                    option={optionCreateModal}
                    getInputsOptions={() => getInputsOptions(tipoSelected, setLoading, tipoSelected == "Despesa" ? setFornecedorOptions : setClientesOptions, setCategoriaOptions, setCentroCustoOptions, () => {})}
                />
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    {loading ? (
                        [...Array(5).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span><p className="title-container-p">Informações do lançamento</p></span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}} data-testid="grid-inputs">
                                <FilterSingleOption
                                    filterOptions={tipoLancamento === "Despesa" ? fornecedorOptions : clientesOptions}
                                    selectedOption={tipoLancamento === "Despesa" ? fornecedor : cliente}
                                    setSelectedOption={tipoLancamento === "Despesa" ? setFornecedor: setCliente}
                                    placeholder={tipoLancamento === "Despesa" ? "Fornecedor" : "Cliente"}
                                    width={"250px"}
                                    inputError={inputError}
                                    canCreate={!viewOnly}
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                    disabled={viewOnly}
                                />
                                <FilterSingleOption
                                    selectedOption={tipoSelected}
                                    placeholder={tipoLancamento === "Despesa" ? "Tipo de despesa" : "Tipo de receita"}
                                    width={"250px"}
                                    disabled
                                />
                                <FilterDayMonthYear
                                    handleSubmit={handleDayMonthYear}
                                    value={selectedDataCompetencia}
                                    setState={setSelectedDataCompetencia}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data competência"}
                                    inputError={inputError}
                                    disableMinDate
                                    disabled={viewOnly}
                                    disableText={viewOnly}
                                />
                                <FilterSingleOption
                                    filterOptions={categoriaOptions}
                                    selectedOption={selectedCategoria}
                                    setSelectedOption={setSelectedCategoria}
                                    placeholder="Categoria"
                                    width={"250px"}
                                    inputError={inputError}
                                    canCreate={!viewOnly}
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                    disabled={viewOnly}
                                />
                                <FilterSingleOption
                                    filterOptions={centroCustoOptions}
                                    selectedOption={selectedCentroCusto}
                                    setSelectedOption={setSelectedCentroCusto}
                                    placeholder="Centro de custo"
                                    width={"250px"}
                                    canCreate={!viewOnly}
                                    setOpenCreateModal={setOpenCreateModal}
                                    setOptionCreateModal={setOptionCreateModal}
                                    disabled={viewOnly}
                                />
                                <InputSearch
                                    functionProps={() => {}}
                                    setSearchValue={setCodigoReferencia}
                                    searchValue={codigoReferencia}
                                    label={"Código de referência"}
                                    width={"325px"}
                                    testId="codigo-input"
                                    inputError={inputError}
                                    detalheCompras={handleOpenModalEditCompra}
                                    isCompra={isCompra}
                                    disabled={viewOnly}
                                />
                                {
                                    condicaoPagamento === 1 && (
                                        <FilterDayMonthYear
                                            handleSubmit={handleDayMonthYear}
                                            value={selectedVencimento}
                                            setState={setSelectedVencimento}
                                            size={"small"}
                                            width={"250px"}
                                            iconPosition={"end"}
                                            label={"Vencimento"}
                                            inputError={inputError}
                                            disableMinDate
                                            disabled={viewOnly}
                                            disableText={viewOnly}
                                        />
                                    )
                                }
                                <InputSearch
                                    functionProps={() => {}}
                                    setSearchValue={setDescricao}
                                    searchValue={descricao}
                                    label={"Descrição"}
                                    width={"325px"}
                                    testId="descricao-input"
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", alignItems: "end", gap: 2, flexDirection: "column"}} data-testid="grid-valor-total">
                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                    <p style={{margin: 0}}>Valor total</p>
                                    <p style={{margin: 0, fontSize: 27, fontWeight: "bold"}}>R$ {format(valorTotal)}</p>
                                </span>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                {
                    recorrencia && (
                        <Paper
                            sx={{
                                ...paperStyle,
                                backgroundColor: temaEscuro
                                    ? PAPER_PADDING_THEME
                                    : LINE_TABLE,
                                backgroundImage: temaEscuro && "none",
                                border: temaEscuro
                                    ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                    : `1px solid ${BORDER_PAPER}`,
                                margin: `20px ${
                                    isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                            }}
                            data-testid="container-recorrencia"
                        >
                            {loading ? (
                                [...Array(5).keys()].map((i,d) =>{
                                    return(
                                        <Skeleton key={d} height={50}/>
                                    );
                                })
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <span><p className="title-container-p">Informações de recorrência</p></span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                    </Grid>
                                    <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 20, flexWrap: "wrap"}}>
                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, display: "flex", gap: 6, flexDirection: "column"}}>
                                            <p className="item-container-p" style={{fontSize: 14}}>Lançamento recorrente?</p>
                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 15}}>{recorrencia}</p>
                                        </span>
                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, display: "flex", gap: 6, flexDirection: "column"}}>
                                            <p className="item-container-p" style={{fontSize: 14}}>Configuração de recorrência</p>
                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 15}}>{strRecorrencia}</p>
                                        </span>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    )
                }
                {
                    condicaoPagamento > 1 && (
                        <Paper
                            sx={{
                                ...paperStyle,
                                backgroundColor: temaEscuro
                                    ? PAPER_PADDING_THEME
                                    : LINE_TABLE,
                                backgroundImage: temaEscuro && "none",
                                border: temaEscuro
                                    ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                    : `1px solid ${BORDER_PAPER}`,
                                margin: `20px ${
                                    isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                            }}
                            data-testid="container-condicao-pagamento"
                        >
                            {loading ? (
                                [...Array(5).keys()].map((i,d) =>{
                                    return(
                                        <Skeleton key={d} height={50}/>
                                    );
                                })
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <span><p className="title-container-p">Condição de pagamento</p></span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                    </Grid>
                                    <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 20, flexWrap: "wrap"}}>
                                        <DataGrid
                                            rows={(row) => row.id}
                                            columns={columnsCondicaoPagamento}
                                            data={dataCondicaoPagamento}
                                            disableRowSelectionOnClick
                                            NoneFooter
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ backgroundColor: "transparent", flex: 1}} expanded={expanded === "panel1"} onChange={handleChange("panel1")}> 
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                                sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                            >
                                                <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 4, marginRight: 2}}>
                                                    <Grid item>
                                                        {
                                                            tipoLancamento === "Despesa" ? (
                                                                <span><p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX}}>Pago</p></span>
                                                            ) : (
                                                                <span><p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX}}>Recebido</p></span>      
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                            <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total pago (R$)</p>
                                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: DASH_KPI_POSITIVE_COLOR}}>{format(valorTotalPago)}</p>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                                <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                                    <TableBody sx={{
                                                        ".MuiTableCell-root": {
                                                            borderBottom: "none",
                                                            padding: "0 0 .5rem 4rem"
                                                        }}}
                                                    >
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcela (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorTotalPagoParcelas)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Desconto (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorDescontoTotal)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Juros (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorJurosTotal)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Multas (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorMultaTotal)}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ backgroundColor: "transparent", flex: 1}} expanded={expanded === "panel2"} onChange={handleChange("panel2")}> 
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                                sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                            >
                                                <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 4, marginRight: 2}}>
                                                    <Grid item>
                                                        <span><p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX}}>Em aberto</p></span>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: 4, marginRight: 2}}>
                                                            <Grid item>
                                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                                    <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total a pagar (R$)</p>
                                                                    <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: DASH_KPI_POSITIVE_COLOR}}>{format(valorTotalPagar)}</p>
                                                                </span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{fontSize: 25, fontWeight: "bold", color: !temaEscuro && GRAY_BG_UX}}>+</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                                    <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total em atraso (R$)</p>
                                                                    <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: RED_INFO}}>{format(valorTotalVencida)}</p>
                                                                </span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{fontSize: 25, fontWeight: "bold", color: !temaEscuro && GRAY_BG_UX}}>=</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                                    <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total em aberto (R$)</p>
                                                                    <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: BLUE_INFO_UX}}>{format(valorTotalAberto)}</p>
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                                <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                                    <TableBody sx={{
                                                        ".MuiTableCell-root": {
                                                            borderBottom: "none",
                                                            padding: "0 0 .5rem 10.5rem"
                                                        }}}
                                                    >
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcela (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaPagar)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaVencida)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaAbertoCondicao)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Desconto (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorDescontoPagar)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorDescontoVencido)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorDescontoAberto)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Juros (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorJurosPagar)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorJurosVencido)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorJurosAberto)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Multas (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorMultaPagar)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorMultaVencida)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorMultaAberto)}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    )
                }
                {
                    condicaoPagamento === 1 && (
                        <Paper
                            sx={{
                                ...paperStyle,
                                backgroundColor: temaEscuro
                                    ? PAPER_PADDING_THEME
                                    : LINE_TABLE,
                                backgroundImage: temaEscuro && "none",
                                border: temaEscuro
                                    ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                    : `1px solid ${BORDER_PAPER}`,
                                margin: `20px ${
                                    isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                            }}
                            data-testid="detalhes-lancamento"
                        >
                            {loading ? (
                                [...Array(5).keys()].map((i,d) =>{
                                    return(
                                        <Skeleton key={d} height={50}/>
                                    );
                                })
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <span><p className="title-container-p">{getTextTitle()}</p></span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataGrid
                                            rows={(row) => row.id}
                                            columns={columns}
                                            data={data}
                                            disableRowSelectionOnClick
                                            NoneFooter
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ backgroundColor: "transparent", flex: 1}} expanded={expanded === "panel1"} onChange={handleChange("panel1")}> 
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                                sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                            >
                                                <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: 4, marginRight: 2}}>
                                                    <Grid item>
                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                            <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Valor em aberto (R$)</p>
                                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: RED_INFO}}>{format(valorParcelaAberto)}</p>
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                            <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX}}>Valor pago (R$)</p>
                                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: BLUE_INFO_UX}}>{format(valorParcelaPago)}</p>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                                <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                                    <TableBody sx={{
                                                        ".MuiTableCell-root": {
                                                            borderBottom: "none",
                                                            padding: "0 0 .5rem 6.5rem"
                                                        }}}
                                                    >
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcela (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaAberto)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaPago + valorMulta + valorJuros - valorDesconto)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Desconto (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaDesconto)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorDesconto)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Juros (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaJuros)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorJuros)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Multas (R$)</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaMulta)}</TableCell>
                                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorMulta)}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {
                                        recorrencia && (
                                            <Grid item xs={12} data-testid="accordion-recorrencia">
                                                <Accordion sx={{ backgroundColor: "transparent", flex: 1 }} expanded={expanded === "panel2"} onChange={handleChange("panel2")}> 
                                                    <AccordionSummary
                                                        expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                                        sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right"}}
                                                    >
                                                        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: 4, marginRight: 2}}>
                                                            <Grid item>
                                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR}}>
                                                                    <p className="title-container-p">Recebido</p>
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME, height: 500}}>
                                                        <DataGrid
                                                            rows={(row) => row.id}
                                                            columns={columnsRecorrencia}
                                                            data={dataRecorrencia}
                                                            disableRowSelectionOnClick
                                                            NoneFooter
                                                            compact
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )
                                    }
                                </Grid> 
                            )}
                        </Paper>
                    )
                }
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                    data-testid="btns-observacoes-anexos"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px"}}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                className="buttonTabs"
                            >
                                <Tab
                                    value={"observacoes"}
                                    label="Observações"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": {
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                <Tab
                                    value={"anexo"}
                                    label="Anexo"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": {
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                {
                                    (rateios.length >= 1 && rateios[0].categoria !== null) && (
                                        <Tab
                                            data-testid="categoria-tab"
                                            value={"categoria_info"}
                                            label="Informações de categoria"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        {selectedTab === "observacoes" ? (
                            loading ? (
                                <Skeleton
                                    sx={{height: "140px", width: "100%", marginLeft: ".8rem"}}
                                />
                            ) : (
                                <Grid item xs={12}>
                                    <TextField
                                        data-testid="observacoes-textfield"
                                        label={"Observações"}
                                        id="input-observacoes-textfield"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        maxRows={6}
                                        value={observacoes}
                                        onChange={(event) => {
                                            setObservacoes(event.target.value);
                                        }}
                                        sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                        disabled={viewOnly}
                                    />
                                </Grid>
                            )
                        ) : selectedTab === "anexo" ? (
                            <Anexos
                                loading={loadingAnexos}
                                anexos={anexos}
                                setAnexos={setAnexos}
                                disabled={viewOnly}
                            />
                        ) : (
                            <RateiosTab
                                rateios={rateios}
                                setRateios={setRateios}
                                categoriaOptions={categoriaOptions}
                                diferencaRateios={diferencaRateios}
                                valorTotal={valorTotal}
                                totalRateios={totalRateios}
                                disabled={viewOnly}
                            />
                        )}
                    </Grid>
                </Paper>
                <Grid container>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2, margin: `0px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`}}>
                        <DefaultButton
                            onClick={() => closeModal()}
                            title="Cancelar"
                        />
                        <DefaultButton
                            title={"Salvar"}
                            testId="btn-salvar"
                            onClick={() => handleEditPagarReceber()}
                            disabled={loading || viewOnly}
                            loading={loading}
                            variant="yellow"
                        />
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default React.memo(EditarVisaoCompetencia);