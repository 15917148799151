import React, {useEffect} from "react";
import {
    DataGridPremium,
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridApiRef,
    useGridSelector,
} from "@mui/x-data-grid-premium";
import {ptBR} from "@mui/x-data-grid-premium/locales";
import {
    BLUE_THEME,
    GRAY_STRONG_UX,
    INFO_THEME,
    LINE_TABLE,
    PERFIL_THEME,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK
} from "../../shared/utils";
import {useSelector} from "react-redux";
import MuiPagination from "@mui/material/Pagination";


export default function DataGridWithServerPagination(props) {

    const {
        row,
        data,
        columns,
        pagination,
        page,
        pageSize,
        onPageChange,
        onPageSizeChange,
        rowCount,
        isRowSelectable,
        checked,
        customRowSelectionModelChange,
        exmItems = 0,
        isEXM = false,
        pageSizeOptions,
        setRowsSelected,
        onRowClick,
        initialState,
        rowHeight = 90,
        isLoading,
    } = props;

    const apiRef = useGridApiRef();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    function Pagination({className}) {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="primary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(newPage - 1);
                }}
            />
        );
    }

    function CustomPagination(props) {

        return (
            <React.Fragment>
                <GridPagination ActionsComponent={Pagination} {...props} sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "60px",
                    width: "100%"
                }} labelDisplayedRows={({from, to, count}) => {
                    from = page * pageSize + from;
                    to = Math.min(page * pageSize + to, count);
                    return `${from}-${to} de ${count}`;
                }}/>
            </React.Fragment>
        );
    }

    useEffect(() => {
        if (exmItems) {
            const selectedRows = exmItems.map(item => apiRef.current.getRow(item));
            setRowsSelected(prevRows => {
                // Array com as linhas já selecionadas
                const newRows = [...prevRows];
                // Adiciona novas linhas selecionadas
                selectedRows.forEach(row => {
                    if (!newRows.some(existingRow => existingRow?.id === row?.id)) {
                        newRows.push(row);
                    }
                });
                // Remove as linhas desmarcadas
                return newRows.filter(existingRow => selectedRows.some(row => row?.id === existingRow?.id));
            });
        }
    }, [exmItems]);


    return (
        <DataGridPremium
            rowSelectionModel={props?.rowSelectionModel}
            data-testid="data-grid-serve-with-pagination"
            sx={{
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                    backgroundColor: "transparent"
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: "16px",
                    fontWeight: "500",
                    color: temaEscuro ? LINE_TABLE : GRAY_STRONG_UX
                },
                "& ::-webkit-scrollbar-thumb": {
                    backgroundColor: temaEscuro ? PERFIL_THEME : WHITE_ESCRITA_THEME
                },
                "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
                    backgroundColor: "transparent"
                },
                ".MuiDataGrid-cell:focus": {
                outline: "none"
                },
                "& .MuiDataGrid-row:hover": {
                cursor: "pointer"
                },
                fontSize: "14px", fontWeight: 400, borderRadius: 2, borderColor: temaEscuro && INFO_THEME
            }}
            style={{
                "--DataGrid-containerBackground": temaEscuro && BLUE_THEME,
                "--DataGrid-pinnedBackground": temaEscuro && BLUE_THEME,
                backgroundColor: temaEscuro && BLUE_THEME,
                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_STRONG_UX
            }}
            loading={isLoading}
            rowHeight={rowHeight}
            localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                footerRowSelected: () => "",
            }}
            checkboxSelection={checked}
            onRowSelectionModelChange={customRowSelectionModelChange}
            apiRef={apiRef}
            rows={data}
            rowCount={rowCount}
            getRowId={row}
            columns={columns}
            onCellClick={(params) => {
                if (params.colDef.field !== "__check__" && onRowClick) {
                    onRowClick(params);
                }
            }}
            pagination={pagination}
            paginationMode="server"
            onPaginationModelChange={(newModel) => {
                if (newModel.pageSize !== pageSize) {
                    onPageSizeChange(newModel.pageSize);
                }
                if (newModel.page !== page) {
                    onPageChange(newModel.page);
                }
            }}
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            isRowSelectable={isRowSelectable}
            disableRowSelectionOnClick={isEXM}
            pageSizeOptions={pageSizeOptions}
            slots={{
                pagination: CustomPagination,
            }}
            initialState={initialState}
        />
    );
}