import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "./DefaultButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import EdicaoLoteModal from "../Modal/EdicaoLoteModal";
import { Tooltip } from "@mui/material";


export default function OptionsEXMSelectedItems(props) {

    const {data, exmItems, getExtratoMovimentacoes, tab, disabled} = props;

    const [canEditConta, setCanEditConta] = useState(false);
    const [canEditPrevisao, setCanEditPrevisao] = useState(false);
    const [canEditCategoria, setCanEditCategoria] = useState(false);
    const [canSetToPago, setCanSetToPago] = useState(false);
    const [canSetToAgendado, setCanSetToAgendado] = useState(false);
    const [canSetToNaoAgendado, setCanSetToNaoAgendado] = useState(false);
    const [canSetToEmAberto, setCanSetToEmAberto] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [movimentacaoType, setMovimentacaoType] = useState("");
    const [openEdicaoModal, setOpenEdicaoModal] = useState(false);
    
    useEffect(() => {
        let selectedItems = data.filter(item => exmItems.includes(item.id));
        if (selectedItems.length !== 0) {
            setMovimentacaoType(selectedItems[0].tipo_movimentacao);
            
            let canEditConta = selectedItems.every(item => (item.situacao !== "Transferido") && (item.conciliado !== true) && (!item.categoria_nome.includes("Saldo inicial")));
            setCanEditConta(canEditConta);
            
            let canEditPrevisao = selectedItems.every(item => (item.situacao !== "Transferido") && (item.conciliado !== true) && (!item.categoria_nome.includes("Saldo inicial")) && (item.situacao !== "Pago") && (item.situacao !== "Recebido"));
            setCanEditPrevisao(canEditPrevisao);

            let canEditCategoria = selectedItems.every(item => item.categoria_nome.length <= 1);
            setCanEditCategoria(canEditCategoria);
            
            let canSetToPago = selectedItems.every(
                item => (
                    (item.situacao !== "Transferido") &&
                    (item.situacao !== "Pago") &&
                    (item.situacao !== "Recebido") &&
                    (item.conta__apelido !== null)
                )
            );
            setCanSetToPago(canSetToPago);

            let canSetToAgendado = selectedItems.every(item => (item.situacao !== "Transferido") && (item.situacao !== "Recebido") && !item.agendado);
            setCanSetToAgendado(canSetToAgendado);

            let canSetToEmAberto = selectedItems.every(item => (item.situacao !== "Transferido") && (item.situacao !== "Em Aberto") && (item.situacao !== "Em Atraso"));
            setCanSetToEmAberto(canSetToEmAberto);

            let canSetToNaoAgendado = selectedItems.every(item => (item.situacao !== "Transferido") && item.agendado);
            setCanSetToNaoAgendado(canSetToNaoAgendado);

            let canDelete = selectedItems.every(item => !item.item_compra);
            setCanDelete(canDelete);
        }
    }, [exmItems]);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);


    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <DefaultButton
                title={exmItems.length > 1 ? "Ações em lote" : "Ações"}
                startIcon={moreVertIcon}
                onClick={handleClick}
                testId="options-menu-acoes"
                className={props.className}
                disabled={disabled}
                size="small"
            />
                <EdicaoLoteModal
                    openModal={openEdicaoModal}
                    setOpenModal={setOpenEdicaoModal}
                    exmItems={exmItems}
                    getExtratoMovimentacoes={getExtratoMovimentacoes}
                    movimentacaoType={movimentacaoType}
                    isEXM
                    selectedTab={tab}
                    canEditCategoria={canEditCategoria}
                />
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <React.Fragment>
                        {
                            tab !== "visao_competencia" && (
                                <React.Fragment>
                                    {
                                        !canSetToPago ? (
                                            <Tooltip
                                                title="Selecione apenas lançamentos em aberto e com conta informada."
                                                arrow
                                                sx={{
                                                    "& .MuiPopper-root": {
                                                        backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <MenuItem
                                                        disabled
                                                        onClick={() => props?.handleEditEmLoteModal("pago")}
                                                    >
                                                        {tab !== "contas_receber" ? ("Marcar como pago") : ("Marcar como recebido")}
                                                    </MenuItem>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <MenuItem
                                                onClick={() => props?.handleEditEmLoteModal("pago")}
                                            >
                                                {tab !== "contas_receber" ? ("Marcar como pago") : ("Marcar como recebido")}
                                            </MenuItem>
                                        )
                                    }
                                    {
                                        !canSetToEmAberto ? (
                                            <Tooltip
                                                title="Selecione apenas lançamentos Pagos, Recebidos e Conciliados."
                                                arrow
                                                sx={{
                                                    "& .MuiPopper-root": {
                                                        backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <MenuItem
                                                        disabled
                                                        onClick={() => props?.handleEditEmLoteModal("em_aberto")}
                                                    >
                                                        Marcar como em aberto
                                                    </MenuItem>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <MenuItem
                                                onClick={() => props?.handleEditEmLoteModal("em_aberto")}
                                            >
                                                Marcar como em aberto
                                            </MenuItem>
                                        )
                                    }
                                    {
                                        tab !== "contas_receber" && (
                                            <React.Fragment>
                                                {
                                                    !canSetToAgendado ? (
                                                        <Tooltip
                                                            title="Selecione apenas lançamentos Em Aberto e Não Agendados."
                                                            arrow
                                                            sx={{
                                                                "& .MuiPopper-root": {
                                                                    backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                                }
                                                            }}
                                                        >
                                                            <span>
                                                                <MenuItem
                                                                    disabled
                                                                    onClick={() => props?.handleEditEmLoteModal("agendado")}
                                                                >
                                                                    Marcar como agendado
                                                                </MenuItem>
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <MenuItem
                                                            onClick={() => props?.handleEditEmLoteModal("agendado")}
                                                        >
                                                            Marcar como agendado
                                                        </MenuItem>
                                                    )
                                                }
                                                {
                                                    !canSetToNaoAgendado ? (
                                                        <Tooltip
                                                            title="Selecione apenas lançamentos marcados como agendado."
                                                            arrow
                                                            sx={{
                                                                "& .MuiPopper-root": {
                                                                    backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                                }
                                                            }}
                                                        >
                                                            <span>
                                                                <MenuItem
                                                                    disabled
                                                                    onClick={() => props?.handleEditEmLoteModal("nao_agendado")}
                                                                >
                                                                    Desmarcar como agendado
                                                                </MenuItem>
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <MenuItem
                                                            onClick={() => props?.handleEditEmLoteModal("nao_agendado")}
                                                        >
                                                            Desmarcar como agendado
                                                        </MenuItem>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    {
                                        !canEditPrevisao ? (
                                            <Tooltip
                                                title="Apenas movimentações não conciliadas, não transferidas e Em Aberto ou Em Atraso podem ser alteradas."
                                                arrow
                                                sx={{
                                                    "& .MuiPopper-root": {
                                                        backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <MenuItem
                                                        disabled
                                                        onClick={() => props?.handleEditEmLoteModal("vencimento")}
                                                    >
                                                        {tab === "movimentacoes" ? "Alterar data prevista" : "Alterar vencimento e/ou previsão"}
                                                    </MenuItem>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <MenuItem
                                                onClick={() => props?.handleEditEmLoteModal("vencimento")}
                                            >
                                                {tab === "movimentacoes" ? "Alterar data prevista" : "Alterar vencimento e/ou previsão"}
                                            </MenuItem>
                                        )
                                    }
                                    {
                                        !canEditConta ? (
                                            <Tooltip
                                                title="Apenas movimentações não conciliadas e não transferidas podem ser alteradas."
                                                arrow
                                                sx={{
                                                    "& .MuiPopper-root": {
                                                        backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <MenuItem
                                                        disabled
                                                        onClick={() => props?.handleEditEmLoteModal("conta")}
                                                    >
                                                        Alterar conta
                                                    </MenuItem>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <MenuItem
                                                onClick={() => props?.handleEditEmLoteModal("conta")}
                                            >
                                                Alterar conta
                                            </MenuItem>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                        {
                            !(["movimentacoes"].includes(tab)) && (
                                <React.Fragment>
                                    <MenuItem
                                        onClick={() => {
                                            setOpenEdicaoModal(true);
                                            setAnchorEl(null);
                                        }}
                                    >
                                        Editar
                                    </MenuItem>
                                    {
                                        canDelete && (
                                            <MenuItem
                                                onClick={() => {
                                                    props?.handleEditEmLoteModal("excluir");
                                                    setAnchorEl(null);
                                                }}
                                            >
                                                Excluir
                                            </MenuItem>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                </Menu>
        </div>
    );
}