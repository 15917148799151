import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    BLACK_TABLE,
    BLUE_THEME,
    GRAY_PDF,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    getCurrentDate,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    styleText,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    BLACK_LABEL_UX,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    handleDayMonthYear,
    formaPagamentoOptions,
    BLACK_TABLE_PERFIL,
    DARK_GREEN_TEXT_UX,
    LIGHT_GREEN_TEXT_UX,
    DARK_GREEN_BG_UX,
    LIGHT_GREEN_BG_UX,
    manualCurrencyFormatter,
    GREEN_SUCCESS_UX,
    format,
    checkSituacao,
    formatterCurrency
} from "../../../shared/utils";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import api from "../../../axios";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import moment from "moment";
import InputSearch from "../../../components/Input/InputSearch";
import Anexos from "../../../components/Tabs/Anexos";
import CreateOptionModal from "../../../components/Modal/CreateOptionModal";
import ParcelasModal from "../../../components/Modal/ParcelasModal";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import { showSnackMessage } from "../../../actions/SnackActions";
import InputCurrency from "../../../components/Input/InputCurrency";
import SaveOptions from "../../../components/Buttons/SaveOptions";
import RateiosTab from "../../../components/Tabs/RateiosTab";
import { changeOpenLancamento } from "../../../actions/StoreActions";
import Decimal from "decimal.js";
import FilterYear from "../../../components/Filter/FilterYear";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditarLancamento = (props) => {
    const { movimentacaoId, open, setOpen, callback, recalculatePagePrevious } = props;

    const dispatch = useDispatch();

    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector((state) => state.StoreReducer.estabelecimentoId);

    const isLargeScreen = useMediaQuery("(min-width:1440px)");
    const currentMonthDate = moment(getCurrentDate()).toDate();
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");

    const [clienteOptions, setClientesOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [contasOptions, setContasOptions] = useState([]);

    const [selectedFornecedor, setSelectedFornecedor] = useState(null);
    const [selectedDataCompetencia, setSelectedDataCompetencia] = useState(currentMonthDate);
    const [descricaoMovimentacao, setDescricaoMovimentacao] = useState("");
    const [valorMovimentacao, setValorMovimentacao] = useState(0);
    const [valorOriginalMovimentacao, setValorOriginalMovimentacao] = useState(0);
    const [valorPago, setValorPago] = useState(0);
    const [valorEmAberto, setValorEmAberto] = useState(0);

    const [rateios, setRateios] = useState([
        {
            categoria: null,
            valor: 0,
            percentual: 0,
        },
    ]);
    const [totalRateios, setTotalRateios] = useState(0);
    const [diferencaRateios, setDiferencaRateios] = useState(0);

    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");

    const [configRecorrencia, setConfigRecorrencia] = useState([
        { label: "Diário: A cada 10 dia(s), 3 vez(es)", value: "diario-10-3" },
        { label: "Mensal: A cada 1 mês(es), 10 vez(es)", value: "mensal-1-10" },
        { label: "Diário: A cada 1 dia(s), 37 vez(es)", value: "diario-1-37" },
    ]);
    const optionsParcelamento = ["À vista", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

    const [idMovimentacaoReal, setIdMovimentacaoReal] = useState(null);
    const [selectedParcelamento, setSelectedParcelamento] = useState("À vista");
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [infoParcelas, setInfoParcelas] = useState([{
        data_vencimento: null,
        parcela: "0/0",
        conta: "",
        forma_pagamento: "",
        valor: 0,
        valor_pago: 0,
        valor_em_aberto: 0,
        situacao: "",
        observacoes: "",
    }]);
    const [parcelas, setParcelas] = useState([{
        data_vencimento: null,
        valor: 0,
        percentual: 0,
        descricao: "",
    }]);
    const [valorAPagar, setValorAPagar] = useState(0);
    const [valorPrevisao, setValorPrevisao] = useState(0);
    const [jurosPrevisao, setJurosPrevisao] = useState(0);
    const [multaPrevisao, setMultaPrevisao] = useState(0);
    const [descontoPrevisao, setDescontoPrevisao] = useState(0);

    const [selectedDataVencimento, setSelectedDataVencimento] = useState(currentMonthDate);
    const [selectedDataPagamento, setSelectedDataPagamento] = useState(currentMonthDate);
    const [selectedConta, setSelectedConta] = useState(null);

    const [baixasAnteriores, setBaixasAnteriores] = useState([]);
    const [tipoMovimentacao, setTipoMovimentacao] = useState("");
    const [isAgendado, setIsAgendado] = useState(false);
    const [isPago, setIsPago] = useState(false);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [loadingAnexos, setLoadingAnexos] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (movimentacaoId) {
            getInputsOptions();
            getMovimentacaoInfo();
        }
    }, [movimentacaoId]);

    useEffect(() => {
        if (valorMovimentacao) {
            setValorEmAberto(new Decimal(valorMovimentacao).minus(valorPago).toDecimalPlaces(2).toNumber());
        }
    }, [valorMovimentacao, valorPago]);

    useEffect(() => {
        const novoTotalRateios = rateios.reduce((acc, rateio) => acc + Math.abs(rateio.valor || 0), 0);
        const novaDiferencaRateios = new Decimal(valorMovimentacao).minus(novoTotalRateios).toDecimalPlaces(2).toNumber();

        setTotalRateios(novoTotalRateios);
        setDiferencaRateios(novaDiferencaRateios);
    }, [rateios, valorMovimentacao]);

    useEffect(() => {
        if (valorMovimentacao) {
            setValorAPagar(new Decimal(valorMovimentacao).minus(valorPago).plus(jurosPrevisao).plus(multaPrevisao).minus(descontoPrevisao).toDecimalPlaces(2).toNumber());
        }
    }, [valorMovimentacao, valorOriginalMovimentacao, valorPrevisao, jurosPrevisao, multaPrevisao, descontoPrevisao]);

    useEffect(() => {
        setSelectedDataPagamento(selectedDataVencimento);
    }, [selectedParcelamento, valorMovimentacao, selectedDataVencimento]);

    useEffect(() => {
        if (isPago) {
            setValorPrevisao(new Decimal(valorMovimentacao).minus(valorPago).toDecimalPlaces(2).toNumber());
        }
    }, [isPago]);

    useEffect(() => {
        if (tipoMovimentacao) {
            api.GetFornecedores({ estabelecimento_id: estabelecimentoId, papel_pessoa: tipoMovimentacao === "Receita" ? "Cliente" : "Fornecedor" }).then((response) => {
                setClientesOptions(response.data);
                setLoading(false);
            });
        }
    }, [tipoMovimentacao]);

    useEffect(() => {
        if (tipoMovimentacao) {
            api.GetCategorias({ estabelecimento_id: estabelecimentoId, tipo: tipoMovimentacao }).then((response) => {
                setCategoriaOptions(response.data);
                setLoading(false);
            });
        }
    }, [tipoMovimentacao]);

    useEffect(() => {
        if (contasOptions.length > 0 && selectedConta) {
            setSelectedConta(contasOptions.find(option => option.value === selectedConta.value));
        }
    }, [contasOptions, selectedConta]);

    const getInputsOptions = () => {
        if (estabelecimentoId !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
            };
            api.GetCentroCustos(dataRequest).then((response) => {
                setCentroCustoOptions(response.data);
                setLoading(false);
            });
            api.RetrieveContas(dataRequest).then((response) => {
                setContasOptions(response.data);
                setLoading(false);
            });
        }
    };

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            lancamento_id: movimentacaoId,
            tab: "contas_pagar",
        };
        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        }).catch(() => {
            setLoadingAnexos(false);
            dispatch(showSnackMessage({ message: "Erro ao buscar anexos", severity: "error" }));
        });
    };

    const getMovimentacaoInfo = () => {
        setLoading(true);
        getAnexos();
        const dataRequest = {
            id: movimentacaoId,
            tipo_id: "parcela",
        };
        api.GetMovimentacao(dataRequest)
            .then((response) => {
                const data = response.data;
                setIdMovimentacaoReal(data.movimentacao_id);
                setSelectedFornecedor(data.fornecedor);
                setSelectedDataCompetencia(moment(data.data_competencia, "DD/MM/YYYY").toDate());
                setSelectedDataVencimento(moment(data.data_vencimento, "DD/MM/YYYY").toDate());
                setDescricaoMovimentacao(data.descricao);
                setObservacoes(data.observacoes);
                setValorMovimentacao(data.valor_total);
                setValorOriginalMovimentacao(data.valor_total);
                setValorPago(data.valor_pago);
                setRateios(
                    data.categorias.map((rateio) => {
                        return {
                            categoria: rateio.categoria,
                            valor: Math.abs(rateio.valor),
                            percentual: new Decimal(rateio.percentual).times(100).toDecimalPlaces(2).toNumber(),
                        };
                    })
                );
                setSelectedCentroCusto(data.centro_custo);
                setSelectedConta(data.parcelas[0].conta);
                setSelectedFormaPagamento(data.parcelas[0].forma_pagamento);
                setCodigoReferencia(data.codigo_referencia);
                setInfoParcelas(data.parcelas);
                setSelectedParcelamento(data.parcelamento);
                setParcelas(
                    data.parcelas.map((parcela) => {
                        return {
                            data_vencimento: moment(parcela.data_vencimento, "DD/MM/YYYY").toDate(),
                            valor: parcela.valor,
                            percentual: parcela.percentual,
                            descricao: parcela.descricao,
                        };
                    })
                );
                setTipoMovimentacao(data.tipo_movimentacao);
                setBaixasAnteriores(data.baixas);
                if (!data.pago) {
                    setValorPrevisao(data.previsao_pagamento?.valor || 0);
                    setJurosPrevisao(data.previsao_pagamento?.juros || 0);
                    setDescontoPrevisao(data.previsao_pagamento?.desconto || 0);
                    setMultaPrevisao(data.previsao_pagamento?.multa || 0);
                }
                setLoading(false);
            })
            .catch(() => {
                dispatch(
                    showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" })
                );
                setLoading(false);
            });
    };

    const postEditMovimentacao = (_closeModal = false) => {
        setLoading(true);
        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("movimentacao_id", idMovimentacaoReal);
        formData.append("fornecedor_id", selectedFornecedor?.value || 0);
        formData.append("data_competencia", moment(selectedDataCompetencia).format("DD-MM-YYYY"));
        formData.append("descricao", descricaoMovimentacao);
        formData.append("valor_total", valorMovimentacao);
        formData.append("rateios", JSON.stringify(rateios));
        formData.append("centro_custo_id", selectedCentroCusto?.value || 0);
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("parcelamento", selectedParcelamento);
        formData.append("parcelas", JSON.stringify(parcelas.map((parcela) => {
            return {
                data_vencimento: moment(parcela.data_vencimento).format("DD-MM-YYYY"),
                valor: parcela.valor,
                percentual: parcela.percentual,
                descricao: parcela.descricao,
            };
        })));
        formData.append("data_previsao", moment(selectedDataPagamento).format("DD-MM-YYYY"));
        formData.append("valor_previsao", isPago ? valorPrevisao : new Decimal(valorAPagar).plus(descontoPrevisao).minus(jurosPrevisao).minus(multaPrevisao).toDecimalPlaces(2).toNumber());
        formData.append("juros_previsao", jurosPrevisao);
        formData.append("multa_previsao", multaPrevisao);
        formData.append("desconto_previsao", descontoPrevisao);
        formData.append("valor_a_pagar", valorAPagar);
        formData.append("pago", isPago);
        formData.append("data_vencimento", moment(selectedDataVencimento).format("DD-MM-YYYY"));
        formData.append("forma_pagamento", selectedFormaPagamento);
        formData.append("conta_id", selectedConta.value);
        formData.append("agendado", isAgendado);
        formData.append("observacoes", observacoes);
        if (anexos[0].arquivo != null) {
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
        formData.append("tipo_movimentacao", tipoMovimentacao);

        if (valorPago === 0) {
            api.RecreateMovimentacao(formData)
                .then(() => {
                    setLoading(false);
                    dispatch(
                        showSnackMessage({ message: `${tipoMovimentacao} atualizado(a) com sucesso!`, severity: "success" })
                    );
                    if (_closeModal) {
                        closeModal();
                    }
                    recalculatePagePrevious();
                    callback();
                })
                .catch((err) => {
                    setLoading(false);
                    
                    if (err.response.data.observacoes !== undefined) {
                        dispatch(
                            showSnackMessage({ message: err.response.data.observacoes, severity: "error" })
                        );
                    }
                    else {
                        dispatch(
                            showSnackMessage({ message: err.response.data.message, severity: "error" })
                        );
                    }
                    
                });
            return;
        }

        api.UpdateMovimentacao(formData)
            .then(() => {
                setLoading(false);
                dispatch(
                    showSnackMessage({ message: `${tipoMovimentacao} atualizado(a) com sucesso!`, severity: "success" })
                );
                if (_closeModal) {
                    closeModal();
                }
                recalculatePagePrevious();
                callback();
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    showSnackMessage({ message: err.response.data.message, severity: "error" })
                );
            });
    };

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });

            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
        
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const checkDisabled = () => {
        return (
            loading ||
            !selectedDataCompetencia ||
            !descricaoMovimentacao ||
            rateios.length === 0 ||
            rateios.some((rateio) => !rateio.categoria || rateio.valor === 0) ||
            valorMovimentacao === 0 ||
            !selectedConta
        );
    };

    const cleanReceitaInputs = () => {
        setLoading(false);
        setSelectedFornecedor(null);
        setSelectedDataCompetencia(currentMonthDate);
        setDescricaoMovimentacao("");
        setValorMovimentacao(0);
        setValorOriginalMovimentacao(0);
        setValorPrevisao(0);
        setJurosPrevisao(0);
        setMultaPrevisao(0);
        setDescontoPrevisao(0);
        setBaixasAnteriores([]);
        setRateios([
            {
                categoria: null,
                valor_total: 0,
                porcentagem: 0,
                centro_custo: null,
            },
        ]);
        setSelectedCentroCusto(null);
        setCodigoReferencia("");
        setSelectedParcelamento(null);
        setSelectedFormaPagamento(null);
        setOpenParcelasModal(false);
        setParcelas([]);
        setSelectedDataVencimento(currentMonthDate);
        setSelectedDataPagamento(currentMonthDate);
        setSelectedConta(null);
        setObservacoes("");
        setAnexos([
            {
                forma_anexo: "Arquivo",
                arquivo: null,
                tipo_anexo: "Contrato",
                descricao: "",
            },
        ]);
    };

    const closeModal = () => {
        setOpen(false);
        dispatch(changeOpenLancamento(""));
    };

    return (
        <React.Fragment>
            <CreateOptionModal
                openModal={openCreateModal}
                setOpenModal={setOpenCreateModal}
                option={optionCreateModal}
                getInputsOptions={getInputsOptions}
                configRecorrencia={configRecorrencia}
                setConfigRecorrencia={setConfigRecorrencia}
            />
            <ParcelasModal
                openModal={openParcelasModal}
                setOpenModal={setOpenParcelasModal}
                formaPagamento={selectedFormaPagamento}
                valorPagamento={valorMovimentacao}
                condicaoPagamento={selectedParcelamento}
                parcelas={parcelas}
                setParcelas={setParcelas}
                selectedConta={selectedConta}
                setSelectedVencimento={setSelectedDataVencimento}
                descricao={descricaoMovimentacao}
                vencimento={selectedDataVencimento}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={() => closeModal()}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                        backgroundImage: "none",
                    },
                }}
                data-testid="movimentacao-container"
            >
                <AppBar
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        borderBottom: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
                        padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                        position: "relative",
                        boxShadow: "none",
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX,
                                fontSize: TITLE_SIZE,
                                fontWeight: "bold",
                                lineHeight: 1,
                                flex: 1,
                            }}
                        >
                            Editar lançamento
                        </Typography>
                        <IconButton
                            autoFocus
                            color="inherit"
                            onClick={() => closeModal()}
                            aria-label="close"
                            sx={{
                                color: !temaEscuro && BLACK_LABEL_UX,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ overflow: "auto", maxHeight: "100vh", marginBottom: "60px" }}>
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span>
                                    <p className="title-container-p" data-testid="informacoes-compra-title">
                                        Informações do lançamento
                                    </p>
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sx={{ mr: "2rem" }}>
                                        <FilterSingleOption
                                            filterOptions={clienteOptions}
                                            selectedOption={selectedFornecedor}
                                            setSelectedOption={setSelectedFornecedor}
                                            placeholder={tipoMovimentacao === "Receita" ? "Cliente" : "Fornecedor"}
                                            width={"350px"}
                                            canCreate
                                            setOpenCreateModal={setOpenCreateModal}
                                            setOptionCreateModal={setOptionCreateModal}
                                            testId="cliente-input"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterDayMonthYear
                                            isRequired
                                            disableMinDate
                                            style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                            handleSubmit={handleDayMonthYear}
                                            value={selectedDataCompetencia}
                                            setState={setSelectedDataCompetencia}
                                            size={"small"}
                                            width={"250px"}
                                            iconPosition={"end"}
                                            label={"Data da compra"}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => { }}
                                            setSearchValue={setDescricaoMovimentacao}
                                            searchValue={descricaoMovimentacao}
                                            label={"Descrição"}
                                            width={"575px"}
                                            isRequired
                                            testId="descricao-input"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputCurrency
                                            title={"Valor (R$)"}
                                            valor={valorMovimentacao}
                                            setValor={setValorMovimentacao}
                                            dataTestId="valor-input"
                                        />
                                    </Grid>
                                    <Grid item sx={{ mr: "2rem" }}>
                                        <FilterSingleOption
                                            filterOptions={centroCustoOptions}
                                            selectedOption={selectedCentroCusto}
                                            setSelectedOption={setSelectedCentroCusto}
                                            placeholder={"Centro de custo"}
                                            width={"350px"}
                                            canCreate
                                            setOpenCreateModal={setOpenCreateModal}
                                            setOptionCreateModal={setOptionCreateModal}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => { }}
                                            setSearchValue={setCodigoReferencia}
                                            searchValue={codigoReferencia}
                                            className="input-text"
                                            label={"Código de referência"}
                                        />
                                    </Grid>
                                    {valorEmAberto !== 0 && (
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Stack direction="column">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Typography fontSize={"15px"}>Saldo em aberto</Typography>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "baseline",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Typography
                                                            fontSize={"15px"}
                                                            fontWeight={"bold"}
                                                            marginRight={"5px"}
                                                        >
                                                            R$
                                                        </Typography>
                                                        <Typography fontSize={"20px"} fontWeight={"bold"}>
                                                            {
                                                                format(
                                                                    new Decimal(valorMovimentacao)
                                                                        .minus(valorPago)
                                                                        .toDecimalPlaces(2)
                                                                        .toNumber()
                                                                )
                                                            }
                                                        </Typography>
                                                    </div>
                                                </Stack>
                                                {valorPago !== 0 &&
                                                    <Stack direction="column" marginLeft={"20px"}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <Typography fontSize={"15px"}>Total Pago</Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "baseline",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <Typography
                                                                fontSize={"15px"}
                                                                fontWeight={"bold"}
                                                                marginRight={"5px"}
                                                                sx={{ color: GREEN_SUCCESS_UX }}
                                                            >
                                                                R$
                                                            </Typography>
                                                            <Typography
                                                                fontSize={"20px"}
                                                                fontWeight={"bold"}
                                                                sx={{ color: GREEN_SUCCESS_UX }}
                                                            >
                                                                {format(
                                                                    new Decimal(valorPago)
                                                                        .toDecimalPlaces(2)
                                                                        .toNumber()
                                                                )}
                                                            </Typography>
                                                        </div>
                                                    </Stack>
                                                }
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                    >
                        {valorAPagar === 0 ? (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ marginTop: 2 }} data-testid="condicao-pagamento-title">
                                    <span>
                                        <p className="title-container-p">Condição de pagamento</p>
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE,
                                            backgroundImage: temaEscuro && "none",
                                        }}
                                    >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Data</TableCell>
                                                    <TableCell align="center">Parcela</TableCell>
                                                    <TableCell align="center">Conta</TableCell>
                                                    <TableCell align="center">Valor (R$)</TableCell>
                                                    <TableCell align="center">Pago (R$)</TableCell>
                                                    <TableCell align="center">Em aberto (R$)</TableCell>
                                                    <TableCell align="center">Situação</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {infoParcelas.map((parcela) => {
                                                    return (
                                                        <TableRow key={parcela.id}>
                                                            <TableCell align="center">
                                                                {moment(parcela.data_vencimento).format("DD/MM/YYYY")}
                                                            </TableCell>
                                                            <TableCell align="center">{parcela.parcela}</TableCell>
                                                            <TableCell align="center">{parcela.conta?.label}</TableCell>
                                                            <TableCell align="center">
                                                                {manualCurrencyFormatter(infoParcelas.length === 1 ? valorMovimentacao : parcela.valor, 2)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {manualCurrencyFormatter(infoParcelas.length === 1 ? valorPago : parcela.valor_pago, 2)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {manualCurrencyFormatter(infoParcelas.length === 1 ? valorAPagar : parcela.valor_em_aberto, 2)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    style={{
                                                                        backgroundColor: temaEscuro
                                                                            ? DARK_GREEN_BG_UX
                                                                            : LIGHT_GREEN_BG_UX,
                                                                        color: temaEscuro
                                                                            ? DARK_GREEN_TEXT_UX
                                                                            : LIGHT_GREEN_TEXT_UX,
                                                                        borderRadius: "5px",
                                                                        padding: "3px 10px",
                                                                        fontSize: "15px",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    {infoParcelas.length === 1 ? "Pago" : parcela.situacao}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ backgroundColor: "transparent", marginBottom: "15px", marginTop: 3 }}
                                >
                                    <Tabs
                                        value={selectedTab}
                                        onChange={(e, value) => setSelectedTab(value)}
                                        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
                                        className="buttonTabs"
                                    >
                                        <Tab
                                            data-testid="observacoes-tab"
                                            value={"observacoes"}
                                            label="Observações"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor:
                                                    selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": {

                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                        <Tab
                                            data-testid="anexos-tab"
                                            value={"anexo"}
                                            label="Anexo"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": {

                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                        <Tab
                                            data-testid="categoria-tab"
                                            value={"categoria_info"}
                                            label="Informações de categoria"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": {
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    </Tabs>
                                </Grid>
                                {selectedTab === "observacoes" ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            data-testid="observacoes-textfield"
                                            label={"Observações"}
                                            id="input-observacoes-textfield"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            value={observacoes}
                                            onChange={(event) => {
                                                setObservacoes(event.target.value);
                                            }}
                                            sx={{ backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX }}
                                        />
                                    </Grid>
                                ) : selectedTab === "anexo" ? (
                                    <Anexos anexos={anexos} setAnexos={setAnexos} loading={loadingAnexos}/>
                                ) : (
                                    <RateiosTab
                                        rateios={rateios}
                                        setRateios={setRateios}
                                        categoriaOptions={categoriaOptions}
                                        diferencaRateios={diferencaRateios}
                                        valorTotal={valorMovimentacao}
                                        totalRateios={totalRateios}
                                    />
                                )}
                            </Grid>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ marginTop: 2 }} data-testid="condicao-pagamento-title">
                                    <span>
                                        <p className="title-container-p">Condição de pagamento</p>
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <React.Fragment>
                                            <Grid item>
                                                <FilterSingleOption
                                                    isRequired
                                                    disabled={valorPago > 0}
                                                    filterOptions={optionsParcelamento}
                                                    selectedOption={selectedParcelamento}
                                                    setSelectedOption={setSelectedParcelamento}
                                                    placeholder={"Parcelamento"}
                                                    width={"200px"}
                                                    testId="parcelamento-input"
                                                />
                                            </Grid>
                                            {
                                                selectedConta?.tipo === "Cartão de Crédito" ? (
                                                    <Grid item>
                                                        <FilterYear
                                                            isRequired
                                                            handleSubmit={handleDayMonthYear}
                                                            diaVencimento={selectedConta.dia_vencimento_cartao}
                                                            value={selectedDataVencimento}
                                                            setState={setSelectedDataVencimento}
                                                            label={"Vencimento"}
                                                            width={"250px"}
                                                        />
                                                    </Grid>
                                                ) : (
                                                    <Grid item>
                                                        <FilterDayMonthYear
                                                            isRequired
                                                            disableMinDate
                                                            disabled={valorPago > 0}
                                                            style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                                            handleSubmit={handleDayMonthYear}
                                                            value={selectedDataVencimento}
                                                            setState={setSelectedDataVencimento}
                                                            size={"small"}
                                                            width={"250px"}
                                                            iconPosition={"end"}
                                                            label={"Vencimento"}
                                                        />
                                                    </Grid>
                                                )
                                            }
                                        </React.Fragment>
                                        <Grid item>
                                            <FilterSingleOption
                                                filterOptions={formaPagamentoOptions}
                                                selectedOption={selectedFormaPagamento}
                                                setSelectedOption={setSelectedFormaPagamento}
                                                placeholder={"Forma de pagamento"}
                                                testId="forma-pagamento-input"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FilterSingleOption
                                                isRequired
                                                filterOptions={contasOptions}
                                                selectedOption={selectedConta}
                                                setSelectedOption={setSelectedConta}
                                                placeholder={
                                                    tipoMovimentacao === "Receita"
                                                        ? "Conta de recebimento"
                                                        : "Conta de pagamento"
                                                }
                                                testId="conta-input"
                                            />
                                        </Grid>

                                        {(selectedParcelamento === "1" || selectedParcelamento === "À vista") &&
                                            <Grid item>
                                                <Checkbox
                                                    checked={isPago}
                                                    onChange={() => setIsPago(!isPago)}
                                                />
                                                <a
                                                    style={{
                                                        ...styleText,
                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {tipoMovimentacao === "Receita" ? "Recebido" : "Pago"}
                                                </a>
                                                {!isPago && (
                                                    <>
                                                        <Checkbox
                                                            checked={isAgendado}
                                                            onChange={() => setIsAgendado(!isAgendado)}
                                                        />
                                                        <a
                                                            style={{
                                                                ...styleText,
                                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            Agendado
                                                        </a>
                                                    </>
                                                )}
                                            </Grid>
                                        }
                                        {selectedParcelamento !== "À vista" && (
                                            <Grid item xs={12}>
                                                <DefaultButton
                                                    testId={"editar-parcelas-button"}
                                                    title={"Editar parcelas"}
                                                    onClick={() => setOpenParcelasModal(true)}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {(selectedParcelamento === "1" || selectedParcelamento === "À vista") &&
                                    <>
                                        <Grid item xs={12} sx={{ marginTop: 2 }} data-testid="previsao-pagamento-title">
                                            <span>
                                                <p className="title-container-p">Previsão de pagamento</p>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <FilterDayMonthYear
                                                        isRequired
                                                        disableMinDate
                                                        disabled={valorPago > 0 && !isPago}
                                                        style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                                        handleSubmit={handleDayMonthYear}
                                                        value={selectedDataPagamento}
                                                        setState={setSelectedDataPagamento}
                                                        size={"small"}
                                                        width={"250px"}
                                                        iconPosition={"end"}
                                                        label={"Previsão de pagamento"}
                                                    />
                                                </Grid>
                                                {isPago &&
                                                    <Grid item>
                                                        <InputCurrency
                                                            max={valorMovimentacao}
                                                            title={"Valor (R$)"}
                                                            valor={valorPrevisao}
                                                            setValor={setValorPrevisao}
                                                            dataTestId="valor-previsao-input"
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <InputCurrency
                                                        disabled={valorPago > 0 && !isPago}
                                                        title={"Juros (R$)"}
                                                        valor={jurosPrevisao}
                                                        setValor={setJurosPrevisao}
                                                        dataTestId="valor-juros-input"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <InputCurrency
                                                        disabled={valorPago > 0 && !isPago}
                                                        title={"Multa (R$)"}
                                                        valor={multaPrevisao}
                                                        setValor={setMultaPrevisao}
                                                        dataTestId="valor-multa-input"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <InputCurrency
                                                        disabled={valorPago > 0 && !isPago}
                                                        title={"Desconto (R$)"}
                                                        valor={descontoPrevisao}
                                                        setValor={setDescontoPrevisao}
                                                        dataTestId="valor-desconto-input"
                                                    />
                                                </Grid>
                                                {!isPago &&
                                                    <Grid item>
                                                        <InputCurrency
                                                            disabled
                                                            title={"Valor a pagar (R$)"}
                                                            valor={valorAPagar}
                                                            setValor={null}
                                                            dataTestId="valor-pagar-input"
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                {(valorMovimentacao !== valorPago) &&
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Accordion sx={{ backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>Resumo da baixa</span>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ display: "flex" }}>
                                                <Grid item xs={12} sx={{ maxHeight: "30vh", mt: 3 }}>
                                                    <TableContainer>
                                                        <Table sx={{
                                                            ".MuiTableCell-root": {
                                                                padding: ".8rem 1rem",
                                                                fontWeight: "400",
                                                                textAlign: "center"
                                                            }
                                                        }}>
                                                            <TableHead sx={{ backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF }}>
                                                                <TableRow>
                                                                    <TableCell>Data</TableCell>
                                                                    <TableCell>Forma de pagamento</TableCell>
                                                                    <TableCell>Conta</TableCell>
                                                                    <TableCell>Valor R$</TableCell>
                                                                    <TableCell>Juros/Multa R$</TableCell>
                                                                    <TableCell>Desconto/Tarifas R$</TableCell>
                                                                    <TableCell>Situação</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    baixasAnteriores?.map((baixa) => {
                                                                        if (baixa)
                                                                            return (
                                                                                <TableRow key={baixa?.id}>
                                                                                    <TableCell>{baixa.data}</TableCell>
                                                                                    <TableCell>{baixa.forma_pagamento}</TableCell>
                                                                                    <TableCell>{baixa.conta.label}</TableCell>
                                                                                    <TableCell>{formatterCurrency(baixa.valor, 2)}</TableCell>
                                                                                    <TableCell>{formatterCurrency((baixa.multa + baixa.juros), 2)}</TableCell>
                                                                                    <TableCell>{formatterCurrency(baixa.desconto, 2)}</TableCell>
                                                                                    <TableCell>{checkSituacao(temaEscuro, tipoMovimentacao === "Receita" ? "Recebido" : "Pago")}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                    })
                                                                }
                                                                <TableRow>
                                                                    <TableCell>{moment(selectedDataPagamento).format("DD/MM/YYYY")}</TableCell>
                                                                    <TableCell>{selectedFormaPagamento}</TableCell>
                                                                    <TableCell>{selectedConta.label}</TableCell>
                                                                    <TableCell>{formatterCurrency(isPago ? valorPrevisao : valorAPagar, 2)}</TableCell>
                                                                    <TableCell>{formatterCurrency((multaPrevisao + jurosPrevisao), 2)}</TableCell>
                                                                    <TableCell>{formatterCurrency(descontoPrevisao, 2)}</TableCell>
                                                                    <TableCell>{checkSituacao(temaEscuro, isPago ? tipoMovimentacao === "Receita" ? "Recebido" : "Pago" : "Em Aberto")}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <div style={{ marginLeft: "46px" }}></div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ backgroundColor: "transparent", marginBottom: "15px", marginTop: 3 }}
                                >
                                    <Tabs
                                        value={selectedTab}
                                        onChange={(e, value) => setSelectedTab(value)}
                                        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
                                        className="buttonTabs"
                                    >
                                        <Tab
                                            data-testid="observacoes-tab"
                                            value={"observacoes"}
                                            label="Observações"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor:
                                                    selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": {

                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                        <Tab
                                            data-testid="anexos-tab"
                                            value={"anexo"}
                                            label="Anexo"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": {
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                        <Tab
                                            data-testid="categoria-tab"
                                            value={"categoria_info"}
                                            label="Informações de categoria"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": {
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    </Tabs>
                                </Grid>
                                {selectedTab === "observacoes" ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            data-testid="observacoes-textfield"
                                            label={"Observações"}
                                            id="input-observacoes-textfield"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            value={observacoes}
                                            onChange={(event) => {
                                                setObservacoes(event.target.value);
                                            }}
                                            sx={{ backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX }}
                                        />
                                    </Grid>
                                ) : selectedTab === "anexo" ? (
                                    <Anexos anexos={anexos} setAnexos={setAnexos} loading={loadingAnexos}/>
                                ) : (
                                    <RateiosTab
                                        rateios={rateios}
                                        setRateios={setRateios}
                                        categoriaOptions={categoriaOptions}
                                        diferencaRateios={diferencaRateios}
                                        valorTotal={valorMovimentacao}
                                        totalRateios={totalRateios}
                                    />
                                )}
                            </Grid>
                        )}
                    </Paper>
                </div>
                <AppBar
                    position="fixed"
                    sx={{
                        backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        borderTop: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
                        top: "auto",
                        bottom: 0,
                        padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    }}
                >
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <DefaultButton title={"Voltar"} onClick={() => setOpen(false)} />

                        <div style={{ display: "flex" }}>
                            <DefaultButton
                                title="Salvar"
                                testId="save-button"
                                onClick={() => postEditMovimentacao(true)}
                                disabled={checkDisabled()}
                                loading={loading}
                                borderRadius={{ borderRadius: "5px 0 0 5px" }}
                                variant="yellow"
                            />
                            <SaveOptions
                                disabled={checkDisabled()}
                                action={postEditMovimentacao}
                                cleanStates={cleanReceitaInputs}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
            </Dialog>
        </React.Fragment>
    );
};

export default EditarLancamento;
