import React, {useState, useEffect, useCallback} from "react";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import Anexos from "../../components/Tabs/Anexos";
import moment from "moment";
import DefaultButton from "../../components/Buttons/DefaultButton";
import { Checkbox, Skeleton, Tab, Tabs, TextField, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    RED_INFO,
    styleText,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    WHITE_ESCRITA_THEME,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    BLACK_LABEL_UX,
    BLACK_TABLE_PERFIL,
    GRAY_LABEL_UX_THEME,
    BLUE_THEME,
    handleDayMonthYear,
    BLACK_TABLE,
    BLUE_LIGHT_UX_THEME,
    BORDER_TABLE,
} from "../../shared/utils";
import InfoIcon from "@mui/icons-material/Info";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EdicaoLoteModal = ({openModal, setOpenModal, exmItems, getExtratoMovimentacoes, movimentacaoType, isEXM, setAnchorEl, selectedTab, canEditCategoria}) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoID = useSelector(state => state.StoreReducer.estabelecimentoId);
    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const [fornecedores, setFornecedores] = useState([]);
    const [selectedFornecedor, setSelectedFornecedor] = useState("");
    const [selectedDataCompetencia, setSelectedDataCompetencia] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [observacoes, setObservacoes] = useState("");
    const [categorias, setCategorias] = useState([]);
    const [selectedCategoria, setSelectedCategoria] = useState("");
    const [centroCustos, setCentroCustos] = useState([]);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState("");
    const [agendado, setAgendado] = useState(false);
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [selectedExtraInfoTab, setSelectedExtraInfoTab] = useState("observacoes");

    const dispatch = useDispatch();

    const clearInputs = () => {
        setSelectedFornecedor("");
        setSelectedDataCompetencia("");
        setDescricao("");
        setObservacoes("");
        setAnexos([
            {
                forma_anexo: "Arquivo",
                arquivo: null,
                tipo_anexo: "Contrato",
                descricao: "",
            },
        ]);
        setSelectedExtraInfoTab("observacoes");
        setSelectedCategoria("");
        setAgendado(false);
        setSelectedCentroCusto("");
    };

    useEffect(() => {
        if (openModal) {
            getFornecedores();
            getDataEdicaoLote();
        }
    }, [openModal]);

    const getFornecedores = useCallback(() => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoID,
            papel_pessoa: movimentacaoType === "Despesa" ? "Fornecedor" : "Cliente"
        };

        api.GetFornecedores(dataRequest).then(response => {
            setFornecedores(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao buscar fornecedores.", severity: "error"}));
        });
    }, [openModal]);

    const getDataEdicaoLote = useCallback(() => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoID
        };

        api.GetDataEdicaoLote(dataRequest).then(response => {
            setCategorias(response.data.categorias);
            setCentroCustos(response.data.centro_custos);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao buscar categorias/centros de custo.", severity: "error"}));
        });
    }, [openModal]);

    const sendEdicaoLote = () => {
        setLoading(true);
        let formData = new FormData();

        exmItems.forEach(item => {
            formData.append("selected_movimentacoes", item);
        });
        formData.append("estabelecimento_id", estabelecimentoID);
        formData.append("fornecedor", selectedFornecedor.value);
        formData.append("data_lancamento", selectedDataCompetencia && moment(selectedDataCompetencia).format("DD-MM-YYYY"));
        formData.append("descricao", descricao);
        formData.append("observacoes", observacoes);
        formData.append("categoria", selectedCategoria.value);
        formData.append("centro_custo", selectedCentroCusto.value);
        formData.append("tab", selectedTab);
        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        api.UpdateLoteAction(formData).then(response => {
            dispatch(showSnackMessage({message: response.data?.message, severity: "success"}));
            setLoading(false);
            setOpenModal(false);
            getExtratoMovimentacoes();
            clearInputs();
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao realizar edição em lote.", severity: "error"}));
            setLoading(false);
            setOpenModal(false);
            clearInputs();
        });
    };
    
    const edicaoLoteNotas = () => {
        setLoading(true);
        const dataRequest = {
            parcelas_id: exmItems,
            categoria_id: selectedCategoria.value,
            agendado: agendado
        };
        api.EdicaoLoteNotas(dataRequest).then(response => {
            setAnchorEl(null);
            setLoading(false);
            setOpenModal(false);
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
        }).catch(() => {
            setAnchorEl(null);
            setLoading(false);
            setOpenModal(false);
            dispatch(showSnackMessage({message: "Erro ao realizar edição em lote.", severity: "error"}));
        });
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                    backgroundImage: "none"
                },
            }}
            data-testid="movimentacao-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? BLUE_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        Editar
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                    data-testid="perfil-screen"
                >
                    {loading ? (
                        [...Array(5).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span><p className="title-container-p">{isEXM ? "Informações do lançamento" : "Informações da nota"}</p></span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", mt: "1rem", gap: "1rem", flexDirection: "column"}}>
                                {
                                    isEXM ? (
                                        <React.Fragment>
                                            <Grid container sx={{display: "flex", gap: 2}}>
                                                <FilterSingleOption
                                                    filterOptions={fornecedores}
                                                    selectedOption={selectedFornecedor}
                                                    setSelectedOption={setSelectedFornecedor}
                                                    placeholder={movimentacaoType === "Despesa" ? "Fornecedor" : "Cliente"}
                                                    width={"300px"}
                                                />
                                                <FilterDayMonthYear
                                                    handleSubmit={handleDayMonthYear}
                                                    value={selectedDataCompetencia}
                                                    setState={setSelectedDataCompetencia}
                                                    size={"small"}
                                                    width={"300px"}
                                                    iconPosition={"end"}
                                                    label={"Data de lançamento"}
                                                    isEditConta
                                                />
                                                <FilterSingleOption
                                                    filterOptions={categorias}
                                                    selectedOption={selectedCategoria}
                                                    setSelectedOption={setSelectedCategoria}
                                                    placeholder="Categoria"
                                                    width={"300px"}
                                                    disabled={!canEditCategoria}
                                                />
                                                <FilterSingleOption
                                                    filterOptions={centroCustos}
                                                    selectedOption={selectedCentroCusto}
                                                    setSelectedOption={setSelectedCentroCusto}
                                                    placeholder="Centro de custo"
                                                    width={"300px"}
                                                />
                                            </Grid>
                                            <Grid container sx={{display: "flex", gap: 2}}>
                                                <Grid item xs={12} sx={{ marginTop: 2 }}>
                                                    <span>
                                                        <p className="title-container-p">Descrição</p>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        data-testid="descricao-input"
                                                        label={"Descrição"}
                                                        id="input-observacoes-textfield"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        maxRows={6}
                                                        value={descricao}
                                                        onChange={(event) => {
                                                            setDescricao(event.target.value);
                                                        }}
                                                        sx={{
                                                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                            "& .MuiFormLabel-asterisk": {
                                                                color: RED_INFO,
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Grid container sx={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                                                <Grid item sx={{display: "flex", gap: 1}}> 
                                                    <Paper sx={{...paperStyle, width: "100%", backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: "1px solid", borderColor: BLUE_LIGHT_UX_THEME, borderWidth: "1px 1px 1px 6px", mb: 2}}>
                                                        <div style={{display: "flex", gap: "1rem", alignItems: "start", justifyContent: "space-between"}}>
                                                            <div style={{display: "flex", gap: "1rem", alignItems: "start"}}>
                                                                <InfoIcon sx={{color: BLUE_LIGHT_UX_THEME, ml: 1, fontSize: "26px"}} />
                                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                                    <span style={{fontWeight: "bold", fontSize: "15px", marginBottom: ".5rem"}}><p className="title-container-p">Atenção</p></span>
                                                                    <span style={{fontSize: "14px"}}><p className="item-container-label">os campos abaixo são referentes a todas as movimentações relacionadas a essa(s) nota(s).</p></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item sx={{display: "flex", gap: "2rem", alignItems: "center", mb: "1rem"}}>
                                                    <FilterSingleOption
                                                        filterOptions={categorias}
                                                        selectedOption={selectedCategoria}
                                                        setSelectedOption={setSelectedCategoria}
                                                        placeholder="Categoria"
                                                        width={"300px"}
                                                    />
                                                    <Grid sx={{display: "flex", alignItems: "center"}}>
                                                        <a 
                                                            style={{
                                                                ...styleText,
                                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                            }}
                                                        >
                                                            Agendado
                                                        </a>
                                                        <Checkbox
                                                            checked={agendado}
                                                            onChange={(event) => setAgendado(event.target.checked)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                {
                    isEXM && (
                        <Paper
                            sx={{
                                ...paperStyle,
                                backgroundColor: temaEscuro
                                    ? PAPER_PADDING_THEME
                                    : LINE_TABLE,
                                backgroundImage: temaEscuro && "none",
                                border: temaEscuro
                                    ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                    : `1px solid ${BORDER_PAPER}`,
                                margin: `20px ${
                                    isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px"}}>
                                    <Tabs
                                        value={selectedExtraInfoTab}
                                        onChange={(e, value) => setSelectedExtraInfoTab(value)}
                                        TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                        className="buttonTabs"
                                    >
                                        <Tab
                                            value={"observacoes"}
                                            label="Observações"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedExtraInfoTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                        <Tab
                                            value={"anexo"}
                                            label="Anexo"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedExtraInfoTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    </Tabs>
                                </Grid>
                                {
                                    selectedExtraInfoTab === "observacoes" ? (
                                        <Grid item xs={12}>
                                            <TextField
                                                data-testid="observacoes-textfield"
                                                label={"Observações"}
                                                id="input-observacoes-textfield"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                maxRows={6}
                                                value={observacoes}
                                                onChange={(event) => {
                                                    setObservacoes(event.target.value);
                                                }}
                                                sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                            />
                                        </Grid>
                                    ) : (
                                        <Anexos
                                            anexos={anexos}
                                            setAnexos={setAnexos}
                                        />
                                    )
                                }
                            </Grid>
                        </Paper>
                    )
                }
                <Grid container>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2, margin: `0px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`}}>
                        <DefaultButton
                            onClick={() => closeModal()}
                            title="Cancelar"
                        />
                        <DefaultButton
                            title={"Salvar"}
                            onClick={() => {
                                isEXM ? sendEdicaoLote() : edicaoLoteNotas();
                            }}
                            variant="yellow"
                            disabled={loading}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default React.memo(EdicaoLoteModal);