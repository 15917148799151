import axios from "axios";
import Cookies from "js-cookie";
import store from "./reducers/store";

const instance = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	headers: {
		"Content-Type": "application/json"
	}
});

const instanceFormData = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	headers: {
		"Content-Type": "multipart/form-data",
	}
});

instanceFormData.interceptors.request.use(config => {
	let token = Cookies.get("tk");
	if (token) {
		config.headers.Authorization = `Token ${token}`
			.replace(/(^)|($)/g, "");
	}
	const estabelecimentoId = store.getState().StoreReducer.estabelecimentoId;
	if (estabelecimentoId) {
		config.headers.Estabelecimento = estabelecimentoId;
	}
	const lojaId = store.getState().StoreReducer.storeId;
	if (lojaId) {
		config.headers.Loja = lojaId;
	}
	return config;
}, err => {
	return Promise.reject(err);
});

instance.interceptors.request.use(config => {
	let token = Cookies.get("tk");
	if (token) {
		config.headers.Authorization = `Token ${token}`
			.replace(/(^)|($)/g, "");
	}
	const estabelecimentoId = store.getState().StoreReducer.estabelecimentoId;
	if (estabelecimentoId) {
		config.headers.Estabelecimento = estabelecimentoId;
	}
	const lojaId = store.getState().StoreReducer.storeId;
	if (lojaId) {
		config.headers.Loja = lojaId;
	}
	return config;
}, err => {
	return Promise.reject(err);
});

const handleResponseError = (error) => {
	const isTokenExpired = (error.response.data.detail === "Token inválido." || error.response.data.detail === "As credenciais de autenticação não foram fornecidas.") && error.response.status === 401;
	if (isTokenExpired) {
		Cookies.remove("tk");
		window.location.reload();
	}
	return Promise.reject(error);
};

instance.interceptors.response.use(response => response, handleResponseError);
instanceFormData.interceptors.response.use(response => response, handleResponseError);

export default {
    // ======== AUTENTICATION ========
	GetLogin(data) {
		return instance.post("auth/login", data);
	},
	EnterWithLoja(data) {
		return instance.post("auth/loja_current", data);
	},
	GetLojaCurrent() {
		return instance.get("auth/loja_current");
	},
	SendResetPwdEmail(data){
		return instance.post("auth/email_pwd_code", data);
	},
	GetCheckCode(data){
		return instance.get("auth/email_pwd_code", {params: data});
	},
	GetResetPwd(data){
		return instance.put("auth/reset_pwd", data);
	},
	Logout(data) {
		return instance.delete("auth/logout", data);
	},
	PutChangePassword(data) {
		return instance.put("auth/change_pwd", data);
	},
	GetSession(){
		return instance.get("auth/active_session");
	},
	DeleteSession(data){
		return instance.delete("auth/active_session", {data:data});
	},
	PutUpdateDeviceToken(data){
		return instance.put("auth/update_device_token", data);
	},
	// ======== USER =========
	UserInfo(data){
		return instance.get("user/detail", {params: data});
	},
	UpdateUser(data){
		return instanceFormData.put("user/update_user", data);
	},
	GetNotifications(){
		return instance.get("user/notifications");
	},
	ReadNotification(data){
		return instance.patch("user/notifications", data);
	},
	GetAllUsers(){
		return instance.get("user/retrieve_users");
	},
	PutDisableUpdateNews(){
		return instance.put("user/disable_updates");
	},
	// ======== LOJA =========
	GetLojas(){
		return instance.get("loja/all");
	},
	GetEstabelecimentos(data){
		return instance.get("loja/estabelecimentos", {params: data});
	},
	GetCertificados(data){
		return instance.get("loja/upload_certificado", {params: data});
	},
	SendUploadCertificado(data){
		return instanceFormData.post("loja/upload_certificado", data);
	},
	GetLojasMigracao(data){
		return instance.get("loja/lojas_migracao", {params: data});
	},
	PostMigracao(data){
		return instance.post("loja/lojas_migracao", data);
	},
	GetContasByLoja(data){
		return instance.get("loja/contas", {params: data});
	},
	// ======== BOLETOS =========
	GetBancosBoletos(){
		return instance.get("boletos/banco/all");
	},
	GetBoletos(data){
		return instance.get("boletos/boletos", {params: data});
	},
	ChangeBoletosStatus(data){
		return instance.post("boletos/boletos", data);
	},
	ResetBoletosStatus(data){
		return instance.patch("boletos/boletos", data);
	},
	SendWhatsAppAuthenticationMessage(){
		return instance.post("boletos/codigo_verificacao");
	},
	VerifyWhatsAppCode(data){
		return instance.get("boletos/codigo_verificacao", {params: data});
	},
	UpdateBoletos(data){
		return instance.patch("boletos/update_boletos", data);
	},
	// ======== BASE DADOS =========
	GetLastUpdateDDA(data){
		return instance.get("base_dados/last_update_dda", {params: data});
	},
	GetDashboard(data){
		return instance.get("base_dados/dashboard", {params: data});
  },
	PostGerarCompra(data){
		return instanceFormData.post("base_dados/generate_compra", data);
	},
	GetFornecedores(data){
		return instance.get("base_dados/fornecedores", {params: data});
	},
	GetDataEdicaoLote(data){
		return instance.get("base_dados/data_edicao_lote", {params: data});
	},
	PostCreateFornecedores(data){
		return instance.post("base_dados/fornecedores", data);
	},
	GetCategorias(data){
		return instance.get("base_dados/categorias", {params: data});
	},
	PostCreateCategoria(data){
		return instance.post("base_dados/categorias", data);
	},
	GetProdutosServicos(data){
		return instance.get("base_dados/produtos_servicos", {params: data});
	},
	PostCreateProdutoServico(data){
		return instance.post("base_dados/produtos_servicos", data);
	},
	GetCentroCustos(data){
		return instance.get("base_dados/centro_custos", {params: data});
	},
	PostCreateCentroCustos(data){
		return instance.post("base_dados/centro_custos", data);
	},
	PostGenerateMovimentacao(data){
		return instanceFormData.post("base_dados/generate_movimentacao", data);
	},
	GetCadastroInfo(data){
		return instance.get("base_dados/cadastro_info", {params: data});
	},
	UpdateCadastros(data){
		return instance.put("base_dados/cadastro_info", data);
	},
	DeleteCadastros(data){
		return instance.delete("base_dados/cadastro_info", {data:data});
	},
	GetLatestRelease(){
		return instance.get("base_dados/latest_release");
	},
	GetAnexos(data){
		return instance.get("base_dados/anexos", {params: data});
	},
	PostAnexos(data){
		return instanceFormData.post("base_dados/anexos", data);
	},
	// ======== CONTA ==========
	GetContas(data){
		return instance.get("conta/contas", {params: data});
	},
	DeleteConta(data){
		return instance.delete("conta/contas", {data:data});
	},
	EditConta(data){
		return instance.put("conta/contas", data);
	},
	CreateConta(data){
		return instanceFormData.post("conta/contas", data);
	},
	RetrieveContas(data){
		return instance.get("conta/retrieve_contas", {params: data});
	},
	GetDetalharConta(data){
		return instance.get("conta/detalhar_conta", {params: data});
	},
	GetMovimentacoesConta(data){
		return instance.get("conta/movimentacoes_conta", {params: data});
	},
	GetConciliacaoConta(data){
		return instance.get("conta/conciliacao_conta", {params: data});
	},
	PostConciliarConta(data){
		return instance.post("conta/conciliacao_conta", data);
	},
	PatchContaPadrao(data){
		return instance.patch("conta/contas", data);
	},
	CheckContaCertificado(data){
		return instance.get("conta/integracao_conta", {params: data});
	},
	GetIgnorados(data){
		return instance.get("conta/ignorados", {params: data});
	},
	PutRestaurarIgnorados(data){
		return instance.put("conta/ignorados", data);
	},
	GetSaldoAtual(data){
		return instance.get("conta/saldo", {params: data});
	},
	GetValorPendente(data){
		return instance.get("conta/valor_pendente", {params: data});
	},
	GetConciliacoesPendentes(data){
		return instance.get("conta/conciliacoes_pendentes", {params: data});
	},
	PostDesfazerConciliacao(data){
		return instance.post("conta/desfazer_conciliacao", data);
	},
	// ======== NOTAS =========
	GetNotasRecebidos(data){
		return instance.get("compras/notas_recebidos", {params: data});
	},
	GetNotasCompras(data){
		return instance.get("compras/notas_compras", {params: data});
	},
	PostNotasRecebidos(data){
		return instance.post("compras/notas_recebidos", data);
	},
	PatchNotasRecebidos(data){
		return instance.patch("compras/notas_recebidos", data);
	},
	GetNotaByKey(data){
		return instance.get("compras/nota_by_id", {params: data});
	},
	ImportNotaByKey(data){
		return instance.post("compras/nota_by_id", data);
	},
	DeleteNotas(data){
		return instance.delete("compras/notas_recebidos", {data: data});
	},
	EdicaoLoteNotas(data){
		return instance.post("compras/edicao_lote_notas", data);
	},
	GetNotaDetails(data){
		return instance.get("compras/nota_detail", {params: data});
	},
	PutNotaDetails(data){
		return instance.put("compras/nota_detail", data);
	},
	GetNotasTotal(data){
		return instance.get("compras/notas_contador", {params: data});
	},
	SendToContador(data){
		return instance.post("compras/notas_contador", data);
	},

	// EXTRATO MOVIMENTACOES
	GetExtratoMovimentacoes(data){
		return instance.post("extrato/extrato_movimentacoes", data);
	},
	PostBuscarLancamentos(data){
		return instance.post("extrato/buscar_lancamentos", data);
	},
	PostTransferencia(data){
		return instanceFormData.post("extrato/nova_transferencia", data);
	},
    GetContasTransferencia(data){
		return instance.get("extrato/nova_transferencia", {params: data});
	},
	GetInfoMovimentacao(data){
		return instance.get("extrato/extrato_movimentacoes", {params: data});
	},
	EditarMovimentacao(data){
		return instanceFormData.put("extrato/extrato_movimentacoes", data);
	},
	GetPDFData(data){
		return instance.post("extrato/sheet_to_pdf", data);
	},
	ProcessPlanilha(data){
		return instanceFormData.post("extrato/process_sheet", data);
	},
	SavePlanilha(data){
		return instance.patch("extrato/process_sheet", data);
	},
	UpdateLoteAction(data){
		return instanceFormData.put("extrato/acoes_lote", data);
	},
	DeleteLoteAction(data){
		return instance.delete("extrato/acoes_lote", {data: data});
	},
	UpdateTypeMovimentacoes(data){
		return instance.post("extrato/acoes_lote", data);
	},
	sendExtratoOfx(data){
		return instanceFormData.post("extrato/ofx", data);
	},
	getOfxInfo(data){
		return instanceFormData.post("extrato/ofx/info", data);
	},
	correcaoSaldo(data){
		return instance.post("extrato/correcao_saldo", data);
	},
	saveSaldo(data){
		return instance.post("extrato/saldo", data);
	},
	ignoreLancamento(data){
		return instance.post("extrato/ignore_lancamento", data);
	},
	conciliarLancamentos(data){
		return instance.put("extrato/conciliar", data);
	},
	criarConciliarLancamento(data){
		return instance.post("extrato/conciliar", data);
	},
	getRevisarValores(data){
		return instance.get("extrato/revisar_valores", {params: data});
	},
	putRevisarValores(data){
		return instance.put("extrato/revisar_valores", data);
	},	
	postRevisarValores(data){
		return instance.post("extrato/revisar_valores", data);
	},
	GetInformarPagamento(data){
		return instance.get("extrato/informar_pagamento", {params: data});
	},
	PostInformarPagamento(data){
		return instance.post("extrato/informar_pagamento", data);
	},
	UpdateLancamentoRecorrente(data){
		return instanceFormData.put("extrato/lancamento_recorrencia", data);
	},
	DeleteLancamentoRecorrente(data){
		return instance.delete("extrato/lancamento_recorrencia", {data: data});
	},
	GetBaixaId(data){
		return instance.get("extrato/lancamento_recorrencia", {params: data});
	},
	PutChangePrevisao(data){
		return instance.put("extrato/informar_pagamento", data);
	},
	GetDetailsClonar(data){
		return instance.get("extrato/clonar_lancamento", {params: data});
	},
	GetMovimentacao(data){
		return instance.get("extrato/movimentacao", {params: data});
	},
	UpdateMovimentacao(data){
		return instanceFormData.put("extrato/movimentacao", data);
	},
	RecreateMovimentacao(data){
		return instanceFormData.post("extrato/movimentacao", data);
	},
	// HISTORICO
	GetLogs(data=null){
		return instance.get("change_logs/historico", {params: data});
	},
};