import React, { useState }  from "react";
import {YELLOW_BG_UX} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import {BORDER_TABLE, BORDER_PAPER} from "../../shared/utils";
import { Checkbox, Divider } from "@mui/material";
import DefaultButton from "../Buttons/DefaultButton";
import api from "../../axios";


const DeleteWarning = (props) => {
    const {setOpenModal, temaEscuro, dispatch, showSnackMessage, exmItems, tab, getExtratoMovimentacoes} = props;

    const [agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);

    const clearFields = () => {
        setAgreed(false);
        setOpenModal(false);
    };

    const deleteMovimentacoesLote = () => {
        setLoading(true);
        const dataRequest = {
            selected_movimentacoes: exmItems,
            tipo_extrato: tab,
        };

        api.DeleteLoteAction(dataRequest).then((response) => {
            getExtratoMovimentacoes();
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
            setLoading(false);
            clearFields();
            getExtratoMovimentacoes();
        }).catch(() => {
            dispatch(showSnackMessage({
                message: "Não foi possível deletar as movimentações selecionadas.",
                severity: "error"
            }));
            setLoading(false);
            clearFields();
        });
    };

    return(
        <React.Fragment>
            <Grid sx={{marginTop: 5, display: "flex", alignItems: "center"}}>
                <Checkbox
                    sx={{color: YELLOW_BG_UX,
                        "&.Mui-checked": {
                            color: YELLOW_BG_UX,
                        },
                    }}
                    onClick={() => setAgreed(!agreed)}
                />
                <span style={{fontSize: "14px"}}>
                    <p className="item-container-label">
                        Concordo com a exclusão e sei que os saldos entre VXMF e banco podem divergir.
                    </p>
                </span>
            </Grid>
            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, mb: "1rem", mt: "1.5rem"}} />
            <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                <DefaultButton
                    title="Cancelar"
                    onClick={() => clearFields()}
                />
                <DefaultButton
                    variant="yellow"
                    title="Confirmar"
                    onClick={() => deleteMovimentacoesLote()}
                    disabled={!agreed}
                    loading={loading}
                />
            </Grid>
        </React.Fragment>
    );                  
};

export default DeleteWarning;