import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { BLUE_THEME, BORDER_PAPER, BORDER_TABLE, getCurrentDate, handleDayMonthYear } from "../../shared/utils";
import FilterSingleOption from "../Filter/FilterSingleOption";
import DefaultButton from "../Buttons/DefaultButton";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";
import moment from "moment";

const EditContaVencimentoLote = ({ 
    setOpenModal,
    type,
    contas,
    exmItems,
    tab,
    getExtratoMovimentacoes,
    dispatch,
    temaEscuro,
}) => {
    const currentMonthDate = moment(getCurrentDate()).toDate();

    const [newConta, setNewConta] = useState("");
    const [dataPrevisao, setDataPrevisao] = useState(currentMonthDate);
    const [dataVencimento, setDataVencimento] = useState(currentMonthDate);

    const clearFields = () => {
        setNewConta("");
        setDataPrevisao(currentMonthDate);
        setDataVencimento(currentMonthDate);
        setOpenModal(false);
    };

    const handleEditEmLote = () => {
        let formData = new FormData();

        exmItems.forEach(item => formData.append("selected_movimentacoes", item));
        formData.append("tab", tab);

        if (type === "conta") {
            formData.append("conta", newConta.label);
        }
        else if (type === "vencimento" && tab === "movimentacoes") {
            formData.append("data_previsao", moment(dataPrevisao).format("DD-MM-YYYY"));
        }
        else if (type === "vencimento" && tab !== "movimentacoes") {
            formData.append("data_vencimento", moment(dataVencimento).format("DD-MM-YYYY"));
            formData.append("data_previsao", moment(dataPrevisao).format("DD-MM-YYYY"));
        }

        api.UpdateLoteAction(formData).then(() => {
            dispatch(showSnackMessage({message: "Parcela(s) alterada(s) com sucesso!", severity: "success"}));
            setOpenModal(false);
            getExtratoMovimentacoes();
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao alterar parcela(s)!", severity: "error"}));
        });
    };

    return (
            <React.Fragment>
                <Grid sx={{marginTop: 5}}>
                    {
                        type === "conta" ? (
                            <FilterSingleOption
                                required
                                placeholder="Conta"
                                filterOptions={contas}
                                setSelectedOption={setNewConta}
                                selectedOption={newConta}
                                width={"235px"}
                            />
                        ) : (
                            <Grid item sx={{display: "flex", gap: "1rem"}}>
                                {
                                    (tab !== "movimentacoes") && (tab !== "visao_competencia") && (
                                        <FilterDayMonthYear
                                            isRequired
                                            style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                            handleSubmit={handleDayMonthYear}
                                            value={dataVencimento}
                                            setState={setDataVencimento}
                                            size={"small"}
                                            width={"250px"}
                                            iconPosition={"end"}
                                            label={"Data de vencimento"}
                                            disableMinDate
                                        />
                                    )
                                }
                                <FilterDayMonthYear
                                    isRequired
                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                    handleSubmit={handleDayMonthYear}
                                    value={dataPrevisao}
                                    setState={setDataPrevisao}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data de previsão"}
                                    disableMinDate
                                />
                            </Grid>
                        )
                    }
                </Grid>
                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, mb: "1rem", mt: "3rem"}} />
                <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                    <DefaultButton
                        title="Cancelar"
                        onClick={() => clearFields()}
                    />
                    <DefaultButton
                        variant="yellow"
                        title="Confirmar"
                        onClick={() => handleEditEmLote()}
                    />
                </Grid>
            </React.Fragment>
    );
};

export default EditContaVencimentoLote;