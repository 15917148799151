import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    GRAY_LABEL_UX,
    styleText
} from "../../shared/utils";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import FilterSingleOption from "../Filter/FilterSingleOption";
import InputSearch from "../Input/InputSearch";


const FormCartaoCredito = (props) => {

    const {
        contaDetails,
        tipoConta,
        isBancoEdit,
        cartaoEmissores,
        inputCartaoEmissor,
        setInputCartaoEmissor,
        inputCartaoContaPagamento,
        setInputCartaoContaPagamento,
        contasPagamento,
        inputCartaoBandeira,
        setInputCartaoBandeira,
        bandeiras,
        inputUltimosDigitos,
        setInputUltimosDigitos,
        inputDiaVencimento,
        setInputDiaVencimento,
        inputDiaFechamento,
        setInputDiaFechamento,
        inputApelido,
        setInputApelido,
        styleTextField
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <React.Fragment>
            <FormControl size={"small"} sx={{width: "250px"}}>
                <FilterSingleOption
                    selectedOption={contaDetails.tipo}
                    setSelectedOption={() => {}}
                    filterOptions={tipoConta}
                    placeholder="Tipo"
                    disabled
                />
            </FormControl>
            <FormControl size={"small"} sx={{width: "250px"}}>
                <InputSearch
                    searchValue={inputApelido}
                    setSearchValue={setInputApelido}
                    label="Apelido"
                    functionProps={() => {}}
                    className="input-text"
                />
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <FilterSingleOption
                    selectedOption={inputCartaoEmissor}
                    setSelectedOption={setInputCartaoEmissor}
                    filterOptions={cartaoEmissores}
                    placeholder="Emissor"
                    disabled={isBancoEdit}
                />
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <FilterSingleOption
                    selectedOption={inputCartaoContaPagamento}
                    setSelectedOption={setInputCartaoContaPagamento}
                    filterOptions={contasPagamento}
                    placeholder="Conta padrão para o pagamento"
                    disabled={isBancoEdit}
                />
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <FilterSingleOption
                    selectedOption={inputCartaoBandeira}
                    setSelectedOption={setInputCartaoBandeira}
                    filterOptions={bandeiras}
                    placeholder="Bandeira"
                    disabled={isBancoEdit}
                />
            </FormControl>
            <TextField
                label="Últimos 4 números do cartão"
                id="outlined-size-small"
                defaultValue={contaDetails.cartao_ultimos_digitos}
                size="small"
                value={inputUltimosDigitos}
                onChange={(event) => {
                    setInputUltimosDigitos(event.target.value);
                }}
                sx={{...styleTextField}}
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                }}
                inputProps={{ maxLength: 4 }}
            />
            <Grid container sx={{display: "flex", gap: 4, marginTop: 2}}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Saldo Inicial</span>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", justfiyContent: "center", flexDirection: "row", gap: 2}}>
                    <TextField
                        label="Dia do Fechamento"
                        id="outlined-size-small"
                        defaultValue={contaDetails.cartao_dia_fechamento}
                        size="small"
                        value={inputDiaFechamento}
                        onChange={(event) => {
                            setInputDiaFechamento(event.target.value);
                        }}
                        sx={{...styleTextField}}
                        InputLabelProps={{
                            style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                        }}
                        inputProps={{ maxLength: 2 }}
                    />
                    <TextField
                        label="Dia de Vencimento"
                        id="outlined-size-small"
                        defaultValue={contaDetails.cartao_dia_vencimento}
                        size="small"
                        value={inputDiaVencimento}
                        onChange={(event) => {
                            setInputDiaVencimento(event.target.value);
                        }}
                        sx={{...styleTextField}}
                        InputLabelProps={{
                            style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                        }}
                        inputProps={{ maxLength: 2 }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormCartaoCredito);