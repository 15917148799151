import React, { useState } from "react";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import {
    BORDER_TABLE,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    BORDER_PAPER
} from "../../shared/utils";
import Divider from "@mui/material/Divider";
import DefaultButton from "../Buttons/DefaultButton";
import FilterSingleOption from "../Filter/FilterSingleOption";
import InputSearch from "../Input/InputSearch";


export default function EditarLoteConciliacao(props) {

    const {
        open,
        setOpen,
        categoriasOptions, 
        setCategoriaInput, 
        categoriaInput,
        itemsCheck,
        conciliacoesConta,
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [descricaoInput, setDescricaoInput] = useState("");

    const handleEditLoteChange = () => {
        conciliacoesConta.forEach((conciliacao) => {
            const match = itemsCheck.find((item) => item.extrato.id === conciliacao.extrato.id);
            if (match) {
                if (categoriaInput) {
                    conciliacao.categoria = categoriaInput;
                }
                if (descricaoInput) {
                    conciliacao.descricao = descricaoInput;
                }
            }
        });
        setOpen(false);
    };

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={() => setOpen(false)}
            transitionDuration={400}
            sx={{ "& .MuiDrawer-paper": {
                backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                backgroundImage: "none",
                display: "flex",
                gap: 2,
                padding: 3,
                width: "20%",
            } }}
        >
            <span><p className="title-container-p">Editar {itemsCheck.length} selecionado(s) </p></span>
            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
            <span><p className="item-container-label"style={{fontSize: "15px", margin: "0px 0px 20px 0px"}}>
                Preencha um ou mais campos abaixo como quiser. As informações que você adicionar serão aplicadas a todos os {itemsCheck.length} lançamentos <strong style={{fontSize: "16px"}}>(o que você deixar em branco não será alterado)</strong>. 
            </p></span>
            <Grid container sx={{display: "flex", justifyContent: "start", alignItems: "center", gap: 2}}>
                <Grid item xs={12}>
                    <InputSearch
                        functionProps={() => {}}
                        setSearchValue={setDescricaoInput}
                        searchValue={descricaoInput}
                        label={"Descrição"}
                        width={"20vw"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FilterSingleOption
                        placeholder={"Categoria"}
                        filterOptions={categoriasOptions}
                        selectedOption={categoriaInput}
                        setSelectedOption={setCategoriaInput}
                        width={"20vw"}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "end", height: "100%", paddingBottom: 6}}>
                <DefaultButton
                    title="Cancelar"
                    onClick={() => setOpen(false)}
                />
                <DefaultButton
                    title="Salvar"
                    onClick={() => handleEditLoteChange()}
                    variant="yellow"
                />
            </Grid>
        </Drawer>
    );
}