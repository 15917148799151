import React  from "react";
import {smallPaperStyle, GRAY_LABEL_UX} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK} from "../../shared/utils";
import Divider from "@mui/material/Divider";
import ModalTitle from "../Titles/ModalTitle";
import { Typography } from "@mui/material";
import DefaultButton from "../Buttons/DefaultButton";


const RecorrenciaConfirmationModal = (props) => {
    const {openModal, setOpenModal, editLancamento, updatedFields, setUpdatedFields} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const clearFields = () => {
        setUpdatedFields([]);
        setOpenModal(false);
    };

    return(
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="info-modal"
        >
            <Paper sx={{...smallPaperStyle, maxWidth: "60vw", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                <ModalTitle title="Edição de lançamento recorrente" setOpenModal={clearFields}/>
                <Grid container sx={{display: "flex", flexDirection: "column", gap: 1}}>
                    <Grid item>
                        <Typography sx={{mb: 2, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
                            Os seguintes campos foram alterados e serão refletidos em todos os lançamentos:
                        </Typography>
                        {
                            updatedFields.map((field, index) => {
                                return (
                                    <li key={index} style={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, marginBottom: 2}}>
                                        {field}
                                    </li>
                                );
                            })
                        }
                        <Typography sx={{mt: 4, mb: 4, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
                            Deseja prosseguir?
                        </Typography>
                    </Grid>
                    <Divider sx={{bgcolor: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, mb: 1}}/>
                    <Grid item sx={{display: "flex", justifyContent: "space-between"}}>
                        <DefaultButton
                            title="Cancelar"
                            onClick={clearFields}
                        />
                        <DefaultButton
                            variant="yellow"
                            title="Confirmar"
                            onClick={() => {
                                clearFields();
                                editLancamento();
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default RecorrenciaConfirmationModal;