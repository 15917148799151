import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
    formatterCurrency,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    WHITE_THEME_BLACK,
    PAPER_PADDING_THEME,
    RED_INFO,
    INFO_THEME,
    GRAY_BORDER_TABLE,
    COLOR_LABEL_INPUT,
    WHITE_TABLE,
    BORDER_TABLE,
    ITENS_PAINEL,
    YELLOW_BG_UX,
    DARK_YELLOW_TEXT_UX,
    DARK_YELLOW_BG_UX,
    LIGHT_YELLOW_TEXT_UX,
    LIGHT_YELLOW_BG_UX,
    manualCurrencyFormatter,
    LINE_TABLE,
    BORDER_BUTTON,
    BLUE_INFO_UX,
    BLUE_THEME,
    EXM_SUBTITLE_GRAY,
    WHITE_ESCRITA_THEME,
    DASH_KPI_COLOR,
    currencyConfig,
    styleTextStrong,
    isGroupVoxel
} from "../../shared/utils";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import { changeOpenAccordionSelected } from "../../actions/StoreActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import DataGrid from "../DataGrid/DataGrid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
    isAutogeneratedRow,
} from "@mui/x-data-grid-premium";
import OptionsDetalheConta from "../Buttons/OptionsDetalheConta";
import DesfazerConciliacao from "../Modal/DesfazerConciliacao";
import { showSnackMessage } from "../../actions/SnackActions";
import api from "../../axios";
import EditarContasPagarReceber from "../../containers/EXM/EditarMovimentacao/EditarContasPagarReceber";
import DefaultButton from "../Buttons/DefaultButton";
import IntlCurrencyInput from "react-intl-currency-input";


const MovimentacoesConta = (props) => {

    const dispatch = useDispatch();

    const {
        reload = false,
        conta,
        periodo,
        arrowCounter,
        customPeriodo,
        selectedContaTipo,
        bancoLogo,
        apelidoConta,
        saldoConta,
        setTotalFatura,
        isCartaoCredito,
        isContaCorrente,
        throwDispatchErrorMessage,
        getValorPendente,
        getSaldoAtual,
    } = props;

    const [openModalConciliacao, setOpenModalConciliacao] = useState(false);
    const [itemSelected, setItemSelected] = useState("");
    const [idMovimentacao, setIdMovimentacao] = useState("");
    const [tipoLancamentoMov, setTipoLancamentoMov] = useState("");
    const [openModalEditPagarReceber, setOpenModalEditPagarReceber] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detalhesData, setDetalhesData] = useState([]);
    const [lancamentosCount, setLancamentosCount] = useState({});
    const [lancamentos, setLancamentos] = useState({});
    const [lancamentosVxmf, setLancamentosVxmf] = useState({});
    const [hasConciliation, setHasConciliation] = useState(true);
    const [firstDivergence, setFirstDivergence] = useState(-1);

    const [openCorrigirSaldo, setOpenCorrigirSaldo] = useState(false);
    const [dataCorrecaoSaldo, setDataCorrecaoSaldo] = useState("");
    const [valorCorrecaoSaldo, setValorCorrecaoSaldo] = useState(0);
    const [optionCorrecaoSaldo, setOptionCorrecaoSaldo] = useState("mes");
    const [confirmCorrecaoSaldo, setConfirmCorrecaoSaldo] = useState(false);
    const [expandSaibaMaisCorrecaoSaldo, setExpandSaibaMaisCorrecaoSaldo] = useState(false);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const openAccordionSelected = useSelector(state => state.StoreReducer.openAccordionSelected);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isLeader = useSelector(state => state.AuthReducer.isLeader);
    const isApprover = useSelector(state => state.AuthReducer.isApprover);

    useEffect(() => {
        getMovimentacoesConta();
    }, [reload]);

    const getMovimentacoesConta = () => {
        if (!conta) {
            throwDispatchErrorMessage();
        }
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: conta.value,
            periodo: periodo,
            plus_minus: arrowCounter,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetMovimentacoesConta(dataRequest).then(response => {
            let data = response.data;

            setDetalhesData(data.detalhes_data);
            setFirstDivergence(data.detalhes_data.indexOf(data.detalhes_data.find((item) => item.total_vxmf !== item.total_banco)));
            if (selectedContaTipo === "Cartão de Crédito") {
                setTotalFatura(data.total_fatura);
            }
            if (selectedContaTipo === "Conta Corrente") {
                setHasConciliation(data.tem_conciliacao);
                const lancamentosCount = data.detalhes_data.reduce((acc, item) => {
                    item.detalhes.forEach(row => {
                        acc[row.lancamento_id] = (acc[row.lancamento_id] || 0) + 1;
                    });
                    return acc;
                }, {});
                setLancamentosCount(lancamentosCount);
                const lancamentos = data.detalhes_data.reduce((acc, item) => {
                    item.detalhes.forEach(row => {
                        acc[row.lancamento_id] = row;
                    });
                    return acc;
                }, {});
                setLancamentos(lancamentos);
                const lancamentosVxmf = data.detalhes_data.reduce((acc, item) => {
                    item.detalhes.forEach(row => {
                        acc[row.id] = row;
                    });
                    return acc;
                }, {});
                setLancamentosVxmf(lancamentosVxmf);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };


    const sendCorrecaoSaldo = () => {
        const dataRequest = {
            conta_id: conta.value,
            data: dataCorrecaoSaldo,
            valor: valorCorrecaoSaldo,
            tipo_correcao: optionCorrecaoSaldo,
        };
        api.correcaoSaldo(dataRequest).then(() => {
            getMovimentacoesConta();
            getValorPendente();
            getSaldoAtual();
            setOpenCorrigirSaldo(false);
            dispatch(showSnackMessage({ message: "Saldo corrigido com sucesso!", severity: "success" }));
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };


    const handleChange = (panel) => (event, isExpanded) => {
        dispatch(changeOpenAccordionSelected(isExpanded ? panel : ""));
    };

    const getBackgroundConciliacao = (conciliacao) => {
        if (conciliacao === "Manual" || conciliacao === "Automático") {
            return GRAY_LABEL_UX;
        } else if (conciliacao === "Não Conciliado") {
            return RED_INFO;
        }
    };

    const handleOpenModalEditMovimentacao = (row) => {
        const tabAux = row.tipo_movimentacao === "Despesa" ? "contas_pagar" : "contas_receber";
        setIdMovimentacao(isCartaoCredito ? row.row.parcela_id : row.parcela_id);
        setTipoLancamentoMov(tabAux);
        setOpenModalEditPagarReceber(true);
    };

    const handleOpenCorrecaoSaldo = (data, valor_vxmf) => {
        setDataCorrecaoSaldo(data);
        setValorCorrecaoSaldo(valor_vxmf);
        setOpenCorrigirSaldo(true);
    };

    const getLastDateOfMonth = (dateString) => {
        const [_, month, year] = dateString.split("/").map(Number); // eslint-disable-line no-unused-vars
        const lastDay = new Date(year, month, 0).getDate();
        if (month < 10) {
            return `${lastDay}/0${month}/${year}`;
        }
        return `${lastDay}/${month}/${year}`;
    };

    const columns = [
        {
            field: "descricao_vxmf",
            headerName: "Descrição",
            flex: 2,
            align: "left",
            renderCell: (params) => {
                const categorias = params.row.categorias;
                const descricao = params.row.descricao_vxmf;
                const fornecedor = params.row.fornecedor;
                let title = descricao;
                title += categorias ? `\nCategoria: ${categorias}` : "";
                title += fornecedor ? `\nFornecedor: ${fornecedor}` : "";

                if (isAutogeneratedRow(params.row)) {
                    const idsVxmf = params.rowNode.children;
                    return <span style={{ fontWeight: "bold" }}>
                        {`${idsVxmf.length} lançamentos`}
                    </span>;
                }

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                            justifyContent: "center",
                            height: "100%",
                        }}
                        title={title}
                    >
                        <Typography fontWeight="normal" fontSize="14px">
                            {descricao}
                        </Typography>
                        <Stack alignItems="center" direction="row" spacing={1}>
                            <Typography fontSize="12px">
                                <span
                                    style={{
                                        backgroundColor: GRAY_LABEL_UX,
                                        padding: "3px 10px",
                                        borderRadius: "3px",
                                        color: temaEscuro ? LINE_TABLE : WHITE_TABLE,
                                        fontWeight: "normal",
                                    }}
                                >
                                    {categorias?.length > 1 ? `${categorias.length} categorias` : categorias}
                                </span>
                            </Typography>
                            <Typography fontWeight="normal" fontSize="12px">
                                <span style={{ fontSize: "12px", color: BORDER_BUTTON }}>
                                    {fornecedor ? ` ${fornecedor}` : ""}
                                </span>
                            </Typography>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "valor_vxmf",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (isAutogeneratedRow(params.row)) {
                    const idsVxmf = params.rowNode.children;
                    const valorTotalVxmf = idsVxmf.reduce((acc, id) => acc + lancamentosVxmf[id].valor_vxmf, 0);
                    return <div style={{ borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10 }}>
                        <span style={{ color: valorTotalVxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px" }}>
                            {formatterCurrency(valorTotalVxmf, 2)}
                        </span>
                    </div>;
                }
                return (
                    <div style={{ borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10 }}>
                        <span style={{ color: params.row.valor_vxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px" }}>
                            {formatterCurrency(params.row.valor_vxmf, 2)}
                        </span>
                    </div>
                );
            },
        },
        {
            field: "descricao_banco",
            headerName: "Descrição",
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (isAutogeneratedRow(params.row)) {
                    const lancamento = lancamentos[params.rowNode.groupingKey];
                    return <span>
                        {lancamento.descricao_banco}
                    </span>;
                }
                if (lancamentosCount[params.row.lancamento_id] > 1) {
                    return "";
                }
                return (
                    <span>
                        {params.row.descricao_banco}
                    </span>
                );
            },
        },
        {
            field: "valor_banco",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (isAutogeneratedRow(params.row)) {
                    const lancamento = lancamentos[params.rowNode.groupingKey];
                    return <span
                        style={{ color: lancamento.valor_banco < 0 && RED_INFO, fontWeight: "bold", width: "10000px" }}
                    >
                        {formatterCurrency(lancamento.valor_banco, 2)}
                    </span>;
                }
                if (lancamentosCount[params.row.lancamento_id] > 1) {
                    return "";
                }
                return (
                    <span
                        style={{ color: params.row.valor_banco < 0 && RED_INFO, fontWeight: "bold", width: "10000px" }}
                    >
                        {formatterCurrency(params.row.valor_banco, 2)}
                    </span>
                );
            },
        },
        {
            field: "is_conciliado",
            headerName: "Conciliação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (isAutogeneratedRow(params.row)) {
                    const lancamento = lancamentos[params.rowNode.groupingKey];
                    return (
                        <span style={{ backgroundColor: getBackgroundConciliacao(lancamento.is_conciliado), padding: 8, borderRadius: "3px", color: temaEscuro ? WHITE_TABLE : GRAY_BG_UX }}>
                            {lancamento.is_conciliado}
                        </span>
                    );
                }
                if (lancamentosCount[params.row.lancamento_id] > 1) {
                    return "";
                }
                return (
                    <span style={{ backgroundColor: getBackgroundConciliacao(params.row.is_conciliado), padding: 8, borderRadius: "3px", color: temaEscuro ? WHITE_TABLE : GRAY_BG_UX }}>
                        {params.row.is_conciliado}
                    </span>
                );
            },
        },
        {
            field: "acoes",
            headerName: "",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (isAutogeneratedRow(params.row)) {
                    return "";
                }
                if (isGroupVoxel([isAdmin, isOperator, isLeader, isApprover])) {
                    return (
                        <OptionsDetalheConta
                            setOpenModalConciliacao={setOpenModalConciliacao}
                            row={params.row}
                            setItemSelected={setItemSelected}
                            handleOpenModalEditMovimentacao={handleOpenModalEditMovimentacao}
                        />
                    );
                }
            },
        },
    ];

    const columnsCartaoCredito = [
        { field: "descricao_vxmf", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "categorias",
            headerName: "Categoria",
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const categorias = params.value;
                return (
                    <span
                        style={{
                            backgroundColor: GRAY_LABEL_UX,
                            padding: 8,
                            borderRadius: "3px",
                            color: temaEscuro ? LINE_TABLE : WHITE_TABLE
                        }}
                        title={categorias.length > 1 ? categorias.join(", ") : categorias}
                    >
                        {categorias.length > 1 ? `${categorias.length} categorias` : categorias}
                    </span>
                );
            },
        },
        { field: "parcelas", headerName: "Parcela", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "valor_vxmf",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{ borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10 }}>
                        <span style={{ color: params.row.valor_vxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px" }}>
                            {formatterCurrency(params.row.valor_vxmf, 2)}
                        </span>
                    </div>
                );
            },
        },
    ];

    const columnGroupingModel = [
        {
            groupId: "Lançado no VXMF",
            children: [{ field: "descricao_vxmf" }, { field: "valor_vxmf" }],
            headerAlign: "center",
            renderHeaderGroup: (params) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <span style={{ color: temaEscuro ?? WHITE_TABLE, fontWeight: "bold" }}>{params.groupId}</span>
                </div>
            )
        },
        {
            groupId: "Movimentado no banco",
            headerAlign: "center",
            children: [{ field: "descricao_banco" }, { field: "valor_banco" }],
            renderHeaderGroup: (params) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <span style={{ color: temaEscuro ?? WHITE_TABLE, fontWeight: "bold" }}>{params.groupId}</span>
                </div>
            )
        },
    ];

    const desfazerConciliacao = (state) => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            id_mov: itemSelected.id,
            state: state
        };

        api.PostDesfazerConciliacao(dataRequest).then(() => {
            getMovimentacoesConta();
            getValorPendente();
            getSaldoAtual();
            setOpenModalConciliacao(false);
            dispatch(showSnackMessage({ message: "Operação realizado com sucesso!", severity: "success" }));
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    return (
        <React.Fragment>
            <DesfazerConciliacao
                open={openModalConciliacao}
                setOpen={setOpenModalConciliacao}
                itemSelected={itemSelected}
                desfazerConciliacao={desfazerConciliacao}
            />
            <EditarContasPagarReceber
                idMovimentacao={idMovimentacao}
                tipoLancamentoMov={tipoLancamentoMov}
                open={openModalEditPagarReceber}
                setOpen={setOpenModalEditPagarReceber}
                recalculatePagePrevious={getMovimentacoesConta}
            />
            <Drawer
                anchor={"right"}
                open={openCorrigirSaldo}
                onClose={() => setOpenCorrigirSaldo(false)}
            >
                <DialogTitle
                    sx={{ backgroundColor: temaEscuro && BLUE_THEME, fontSize: "30px" }}>Corrigir saldo do banco</DialogTitle>
                <DialogContent dividers
                    sx={{ backgroundColor: temaEscuro && BLUE_THEME, maxWidth: "600px" }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container sx={{ width: "250px" }}>
                                <Grid item sx={{ width: "250px", display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5, paddingBottom: 0 }}>
                                    <a style={{ color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "12px" }}>Saldo final em {dataCorrecaoSaldo}</a>
                                    <div className="divCurrencyInputNew" style={{ border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${EXM_SUBTITLE_GRAY}95` }}>
                                        <IntlCurrencyInput
                                            value={valorCorrecaoSaldo}
                                            currency="BRL"
                                            config={currencyConfig}
                                            onChange={(event, value) => setValorCorrecaoSaldo(value)}
                                            className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                            style={{ ...styleTextStrong, color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_COLOR, border: 0, height: "5.6px", fontSize: "16px", fontWeight: "600" }}
                                            data-testid={"saldo-input"}
                                            onBlur={() => { }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: "light" }}>
                                Selecione uma opção:
                            </Typography>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Radio
                                    checked={optionCorrecaoSaldo === "mes"}
                                    onChange={() => setOptionCorrecaoSaldo("mes")}
                                    value="mes"
                                    name="radio-button-correcao"
                                    inputProps={{ "aria-label": "A" }}
                                    sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX }}
                                />
                                <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "light" }}>
                                    Corrigir saldos de <b>{dataCorrecaoSaldo}</b> a <b>{getLastDateOfMonth(dataCorrecaoSaldo)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Radio
                                    checked={optionCorrecaoSaldo === "dia"}
                                    onChange={() => setOptionCorrecaoSaldo("dia")}
                                    value="dia"
                                    name="radio-button-correcao"
                                    inputProps={{ "aria-label": "B" }}
                                    sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX }}
                                />
                                <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "light" }}>
                                    Corrigir <b>somente o saldo do dia {dataCorrecaoSaldo}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: "40px", marginBottom: "20px" }}>
                                <Checkbox
                                    checked={confirmCorrecaoSaldo}
                                    onChange={() => setConfirmCorrecaoSaldo(!confirmCorrecaoSaldo)}
                                    sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX }}
                                />
                                <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "light" }}>
                                    Confirmo que o saldo final de {dataCorrecaoSaldo} é <b>{formatterCurrency(valorCorrecaoSaldo, 2)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: "20px" }}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }} onClick={() => setExpandSaibaMaisCorrecaoSaldo(!expandSaibaMaisCorrecaoSaldo)}>
                                        {expandSaibaMaisCorrecaoSaldo ?
                                            (<ExpandLessIcon fontSize="large" sx={{ color: YELLOW_BG_UX }} />)
                                            : (<ExpandMoreIcon fontSize="large" sx={{ color: YELLOW_BG_UX }} />)}
                                        <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontSize: "24px" }}>
                                            Saiba mais
                                        </Typography>
                                    </Box>
                                    {expandSaibaMaisCorrecaoSaldo && (
                                        <Box sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                                            <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontSize: "16px" }}>
                                                Por que o saldo pode aparecer errado?
                                            </Typography>
                                            <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontSize: "16px", fontWeight: "light" }}>
                                                O banco pode demorar para atualizar o saldo que é importado para o VXMF,
                                                mesmo que os lançamentos já apareçam no seu extrato bancário. Ou, podem atualizar
                                                o seu saldo com lançamentos que ainda não constam nas suas movimentações bancárias.
                                                Nos dois casos, o saldo do banco pode aparecer diferente do saldo da VXMF.
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{ backgroundColor: temaEscuro && BLUE_THEME, padding: 2 }}>
                    <Grid container spacing={2} display="flex" justifyContent="space-between">
                        <Grid item>
                            <DefaultButton title={"Cancelar"} onClick={() => setOpenCorrigirSaldo(false)} />
                        </Grid>
                        <Grid item>
                            <DefaultButton disabled={!confirmCorrecaoSaldo} title={"Corrigir saldo"} variant="yellow" onClick={sendCorrecaoSaldo} />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Drawer>
            <Grid container spacing={3} data-testid="container-movimentacoes">
                {!isCartaoCredito && !isContaCorrente && (
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <img src={bancoLogo} alt={"Logotipo do banco"} />
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <a style={{ fontSize: "18px", fontWeight: "bold" }}>{apelidoConta}</a>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <a style={{ fontSize: "12px" }}>Saldo atual no Menu financeiro: {formatterCurrency(saldoConta, 2)}</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {loading ? (
                    <Grid item xs={12}>
                        <Skeleton
                            variant="rectangular"
                            height={"500px"}
                            width="100%"
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <React.Fragment>
                            {detalhesData.map((item, index) => {
                                const shouldGroup = item.detalhes.some(row => lancamentosCount[row.lancamento_id] > 1);
                                return <Accordion
                                    key={item.data}
                                    expanded={isCartaoCredito || openAccordionSelected === item.data}
                                    onChange={handleChange(item.data)}
                                    sx={{
                                        marginBottom: 1,
                                        backgroundColor: "transparent",
                                        border: !isCartaoCredito && (item.total_vxmf !== item.total_banco) && hasConciliation ? `1px solid ${YELLOW_BG_UX}` : `1px solid ${temaEscuro ? COLOR_LABEL_INPUT : GRAY_BORDER_TABLE}`,
                                        borderRadius: "5px",
                                        boxShadow: "none"
                                    }}
                                    data-testid="movimentacao-accordion"
                                >
                                    <AccordionSummary
                                        expandIcon={!isCartaoCredito && <ExpandMoreIcon style={{ color: !temaEscuro && GRAY_BG_UX }} />}
                                        sx={{
                                            backgroundColor: temaEscuro ? PAPER_PADDING_THEME : "transparent",
                                            pointerEvents: isCartaoCredito ? "none" : "auto",
                                        }}
                                    >
                                        <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Grid item xs={5} sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 1 }}>
                                                <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX }}>{item.data}</Typography>
                                                <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX, width: "200px" }}>{item.dia_semana}</Typography>
                                                {!isCartaoCredito && (item.total_vxmf !== item.total_banco) && hasConciliation && (
                                                    <Typography sx={{
                                                        color: temaEscuro ? DARK_YELLOW_TEXT_UX : LIGHT_YELLOW_TEXT_UX,
                                                        backgroundColor: temaEscuro ? DARK_YELLOW_BG_UX : LIGHT_YELLOW_BG_UX,
                                                        fontSize: "12px",
                                                        padding: "2px",
                                                        borderRadius: "5px"
                                                    }}>
                                                        Saldo com diferença de {formatterCurrency(item.total_diferenca, 2)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            {item.total_vxmf !== item.total_banco ?
                                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight: 2, gap: 3 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end", gap: 1 }}>
                                                        <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX }}>
                                                            VXMF
                                                        </Typography>
                                                        <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold" }}>
                                                            {formatterCurrency(item.total_vxmf, 2)}
                                                        </Typography>
                                                    </div>
                                                    {!isCartaoCredito && hasConciliation && (
                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end", gap: 1 }}>
                                                            <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX }}>
                                                                Banco
                                                            </Typography>
                                                            <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold" }}>
                                                                {formatterCurrency(item.total_banco, 2)}
                                                            </Typography>
                                                        </div>
                                                    )
                                                    }
                                                </Grid>
                                                :
                                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight: 2, gap: 1 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end", gap: 1 }}>
                                                        <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX }}>
                                                            VXMF = Banco
                                                        </Typography>
                                                        <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold" }}>
                                                            {formatterCurrency(item.total_vxmf, 2)}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            padding: "0px 0px 0px 0px",
                                            backgroundColor: temaEscuro && PAPER_PADDING_THEME,
                                        }}
                                    >
                                        {
                                            isGroupVoxel([isAdmin, isOperator, isLeader, isApprover]) && (
                                                <Grid item xs={12} sx={{ padding: 2 }}>
                                                    {!isCartaoCredito && item.total_vxmf !== item.total_banco && index === firstDivergence && (
                                                        <div
                                                            style={{
                                                                border: `solid 1px ${YELLOW_BG_UX}`,
                                                                borderLeft: `7px solid ${YELLOW_BG_UX}`,
                                                                borderRadius: "4px",
                                                                padding: 10,
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <ErrorIcon
                                                                    sx={{
                                                                        color: YELLOW_BG_UX,
                                                                        marginRight: "5px",
                                                                    }}
                                                                />
                                                                <div>
                                                                    <Typography fontWeight="bold" fontSize="16px">
                                                                        Saldos diferentes
                                                                    </Typography>
                                                                    <Typography fontSize="16px">
                                                                        Existe diferença entre os saldos do banco e do VXMF.
                                                                        Se todos os lançamentos estiverem corretos,
                                                                        você ainda poderá{" "}
                                                                        <Link
                                                                            underline="hover"
                                                                            sx={{ color: BLUE_INFO_UX, cursor: "pointer" }}
                                                                            onClick={() => handleOpenCorrecaoSaldo(item.data, item.total_vxmf)}>
                                                                            corrigir manualmente o saldo
                                                                        </Link>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Grid>
                                            )
                                        }
                                        <div style={{ width: "100%" }}>
                                            {/* DataGrid para movimentações de cartão de crédito */}
                                            {isCartaoCredito &&
                                                <DataGrid
                                                    disableToolBar
                                                    disableFooter
                                                    columns={columnsCartaoCredito}
                                                    data={item.detalhes}
                                                    row={(row) => row.id}
                                                    columnGroupingModel={null}
                                                    columnGroupHeaderHeight={null}
                                                    rowHeight={60}
                                                    customRowClick={handleOpenModalEditMovimentacao}
                                                />
                                            }

                                            {/* DataGrid para movimentações de conta corrente */}
                                            {!isCartaoCredito && openAccordionSelected === item.data &&
                                                <DataGrid
                                                    disableRowSelectionOnClick
                                                    disableFooter
                                                    cellSelection={false}
                                                    treeData={shouldGroup}
                                                    getTreeDataPath={shouldGroup ? (row) => lancamentosCount[row.lancamento_id] > 1 ? [row.lancamento_id, row.id] : [row.id] : undefined}
                                                    groupingColDef={shouldGroup ? {
                                                        headerName: "",
                                                        valueFormatter: () => {
                                                            return "";
                                                        },
                                                        width: 50,
                                                    } : undefined}
                                                    columns={columns}
                                                    data={item.detalhes}
                                                    row={(row) => row.id}
                                                    columnGroupingModel={columnGroupingModel}
                                                    columnGroupHeaderHeight={36}
                                                    rowHeight={60}
                                                />
                                            }
                                        </div>
                                        {
                                            !isCartaoCredito && (
                                                <Grid container sx={{ padding: 2, marginTop: 2 }}>
                                                    <Grid item xs={6}>
                                                        <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX, fontSize: "18px", fontWeight: "bold" }}>
                                                            Totais do dia
                                                        </Typography>
                                                        <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX, fontSize: "14px", fontWeight: "bold" }}>
                                                            {item.data}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                            <React.Fragment>
                                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
                                                                    <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px" }}>
                                                                        VXMF (R$)
                                                                    </Typography>
                                                                    <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold" }}>
                                                                        {manualCurrencyFormatter(item.total_vxmf, 2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
                                                                    <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px" }}>
                                                                        Banco (R$)
                                                                    </Typography>
                                                                    <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold" }}>
                                                                        {manualCurrencyFormatter(item.total_banco, 2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
                                                                    <Typography sx={{ color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px" }}>
                                                                        Diferença (R$)
                                                                    </Typography>
                                                                    <Typography sx={{ color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold" }}>
                                                                        {manualCurrencyFormatter(item.total_diferenca, 2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </React.Fragment>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </AccordionDetails>
                                </Accordion>;
                            })}
                        </React.Fragment>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default MovimentacoesConta;