import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    styleText,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    BORDER_TABLE,
    BORDER_BUTTON,
    LINE_TABLE,
    styleTextStrong,
    RED_INFO,
    DASH_KPI_DATA_COLOR
} from "../../../../shared/utils";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import IntlCurrencyInput from "react-intl-currency-input";
import FilterDayMonthYear from "../../../../components/Filter/FilterDayMonthYear";
import moment from "moment";

const StepTwoOutraConta = (props) => {

    const {inputBanco, inputApelido, setInputSaldoFinal, inputSaldoFinal, handleDayMonthYear, selectedDate} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [lastDate, setLastDate] = useState("");
    
    useEffect(() => {
        if (selectedDate !== null){
            const lastDate = new Date(`${moment(selectedDate).year()}-${moment(selectedDate).month()}-${moment(selectedDate).day()}`);
    
            lastDate.setDate(lastDate.getDate() - 1);
    
            setLastDate(lastDate.toLocaleDateString("pt-BR"));
        }
    }, [selectedDate]);

    const currencyConfig = {
		locale: "pt-BR",
		formats: {
			number: {
				BRL: {
					style: "currency",
					currency: "BRL",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
			},
		},
	};

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                    <h3 style={{marginTop: 0, color: temaEscuro ? LINE_TABLE : GRAY_LABEL_UX, fontWeight: 700}}>
                    Configurando o saldo da sua conta</h3>
                </Grid>
                <Grid item xs={12} sx={{marginTop: 1}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}>Para começar a controlar suas movimentações, é necessário informar o saldo em sua conta bancária </span>
                </Grid>
                <Grid item xs={12} sx={{marginTop: 1}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: 600}}> Para realizar a gestão financeira corretamente, é necessário informar o saldo final do dia anterior ao início do controle financeiro no VXMF. É a partir deste valor que o VXMF vai calcular as movimentações - entradas e saídas - e manter o total em conta com o mesmo valor do banco. </span>
                </Grid>
                <Grid item xs={12} sx={{marginTop: 2, display: "flex", gap: 2, flexWrap: "wrap"}}>
                    <TextField
                        label="Banco"
                        variant="outlined"
                        size="small"
                        defaultValue={inputBanco}
                        disabled
                        sx={{width: "250px"}}
                    />
                    <TextField
                        label="Apelido"
                        variant="outlined"
                        size="small"
                        defaultValue={inputApelido}
                        disabled
                        sx={{width: "250px"}}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{marginTop: 2, display: "flex", alignItems: "start", gap: 2}}>
                <Grid item sx={{paddingTop: 2.4}}>
                    <FilterDayMonthYear
                        handleSubmit={handleDayMonthYear}
                        label="Data do saldo"
                        width="250px"
                        isConta
                    />
                </Grid>
                <Grid item sx={{width: "250px", display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <a style={{color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "12px"}}>Saldo final bancário em {lastDate} <strong style={{color: RED_INFO}}>*</strong></a>
                    <div className="divCurrencyInputNew" style={{border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${BORDER_BUTTON}`}}>
                        <IntlCurrencyInput
                            value={inputSaldoFinal}
                            currency="BRL"
                            config={currencyConfig}
                            onChange={(event, value) => setInputSaldoFinal(value)}
                            className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                            style={{ ...styleTextStrong, color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, border: 0, height: "5.6px", fontSize: "16px", fontWeight: "600" }}
                            data-testid="input-faturamento-bruto"
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>      
    );
};

export default React.memo(StepTwoOutraConta);