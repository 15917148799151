import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import IntlCurrencyInput from "react-intl-currency-input";
import api from "../../axios";
import DefaultButton from "../Buttons/DefaultButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useSelector, useDispatch } from "react-redux";
import { showSnackMessage } from "../../actions/SnackActions";
import {
    BORDER_BUTTON,
    GRAY_LABEL_UX,
    WHITE_ESCRITA_THEME,
    BORDER_TABLE,
    EXM_SUBTITLE_GRAY,
    DASH_KPI_DATA_COLOR,
    BLUE_THEME,
    styleTextStrong,
    manualCurrencyFormatter,
    currencyConfig,
    RED_ERROR_UX
} from "../../shared/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const STATES = { IMPORT: "import", LOADING: "loading", CONFIRM: "CONFIRM", SUCCESS: "success", ERROR: "error" };

const ImportOFXModal = ({ reload, setReload, openModalOFX, setOpenModalOFX, selectedContaId }) => {
    const dispatch = useDispatch();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const hiddenFileInput = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [confirmarSaldo, setConfirmarSaldo] = useState(false);
    const [state, setState] = useState(STATES.IMPORT);
    const [isSelected, setIsSelected] = useState(false);

    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const [saldo, setSaldo] = useState(0);
    const [arquivoVazio, setArquivoVazio] = useState(false);
    const [mostrarAvisoArquivoVazio, setMostrarAvisoArquivoVazio] = useState(true);

    const [numeroLancamentosImportados, setNumeroLancamentosImportados] = useState(0);
    const [numeroNovosLancamentos, setNumeroNovosLancamentos] = useState(0);

    const getOfxInfo = (extrato) => {
        setState(STATES.LOADING);
        const dataRequest = {
            conta_id: selectedContaId.value,
            extrato: extrato
        };
        api.getOfxInfo(dataRequest).then((response) => {
            setSaldo(response.data.saldo);
            setDataInicio(response.data.data_inicio);
            setDataFim(response.data.data_fim);
            setArquivoVazio(response.data.vazio);
            if (response.data.vazio) {
                setMostrarAvisoArquivoVazio(true);
                setState(STATES.IMPORT);
            } else {
                setState(STATES.CONFIRM);
            }
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Erro ao importar extrato", severity: "error" }));
            setState(STATES.ERROR);
        });
    };

    const sendExtratoOfx = (extrato) => {
        setState(STATES.LOADING);
        const dataRequest = {
            conta_id: selectedContaId.value,
            extrato: extrato,
            saldo: saldo,
            data_saldo: dataFim,
        };
        api.sendExtratoOfx(dataRequest).then((response) => {
            setNumeroLancamentosImportados(response.data.numero_lancamentos_importados);
            setNumeroNovosLancamentos(response.data.numero_novos_lancamentos);
            setReload(!reload);
            setState(STATES.SUCCESS);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Erro ao importar extrato", severity: "error" }));
            setState(STATES.ERROR);
        });
    };

    useEffect(() => {
        if (!openModalOFX) {
            setSelectedFile(null);
            setIsSelected(false);
            setState(STATES.IMPORT);
            setArquivoVazio(false);
            setConfirmarSaldo(false);
        }
    }, [openModalOFX]);

    const changeHandler = (event) => {
        let file = event.target.files[0];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (file && fileExtension === "ofx") {
            setSelectedFile(file);
            setIsSelected(true);
            setMostrarAvisoArquivoVazio(false);
        } else {
            dispatch(showSnackMessage({ message: "Tipo de arquivo inválido", severity: "error" }));
        }
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    return (
        <Dialog
            open={openModalOFX}
            onClose={() => setOpenModalOFX(false)}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="md"
            data-testid="info-modal"
        >
            <DialogTitle sx={{ m: 0, p: 2, backgroundColor: temaEscuro && BLUE_THEME }}>
                {state === STATES.IMPORT && "Importe seu extrato em formato OFX"}
                {state === STATES.CONFIRM && "Confirme o saldo do seu extrato OFX"}
                {state === STATES.SUCCESS && "Importação de extrato OFX concluída com sucesso!"}
                {state === STATES.ERROR && "Erro na importação do extrato OFX"}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenModalOFX(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent
                dividers
                sx={{
                    backgroundColor: temaEscuro && BLUE_THEME,
                    padding: 3,
                    minWidth: "500px",
                }}
            >
                {state === STATES.LOADING && <CircularProgress />}

                {state === STATES.CONFIRM && (
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <img src="/illustrations/confirm.webp" alt="Confirmação" style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>Nome do arquivo: <b>&quot;{selectedFile?.name}&quot;</b></Grid>
                                <Grid item xs={12}>Período importado: <b>{dataInicio} a {dataFim}</b></Grid>
                                <Grid item xs={12} sx={{ marginTop: "10px", marginBottom: "10px" }}><b>Confirma e informe o saldo final da conta</b></Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ width: "250px" }}>
                                        <Grid item sx={{ width: "250px", display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5, paddingBottom: 0 }}>
                                            <a style={{ color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, fontSize: "12px" }}>Saldo final em {dataFim}</a>
                                            <div className="divCurrencyInputNew" style={{ border: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${EXM_SUBTITLE_GRAY}95` }}>
                                                <IntlCurrencyInput
                                                    value={saldo}
                                                    currency="BRL"
                                                    config={currencyConfig}
                                                    onChange={(event, value) => setSaldo(value)}
                                                    className={temaEscuro ? "currencyInputBlack" : "currencyInput"}
                                                    style={{ ...styleTextStrong, color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, border: 0, height: "5.6px", fontSize: "16px", fontWeight: "600" }}
                                                    data-testid={"saldo-input"}
                                                    onBlur={() => { }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} display="flex" alignItems="center">
                                    <Checkbox
                                        checked={confirmarSaldo}
                                        onChange={(event) => setConfirmarSaldo(event.target.checked)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                    <span>Confirmar o valor de <b style={{color: saldo < 0 && RED_ERROR_UX}}>{manualCurrencyFormatter(saldo)}</b> no fim deste extrato</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {state === STATES.SUCCESS && (
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item>
                            <span>
                                {numeroNovosLancamentos === 0 && `✅ ${numeroLancamentosImportados} lançamentos importados, porém nenhum novo lançamento foi criado.`}
                                {numeroNovosLancamentos !== 0 && `✅ ${numeroLancamentosImportados} lançamentos criados.`}
                            </span>
                        </Grid>
                        <Grid item>
                            <span>
                                ✅ O saldo final informado foi de <strong>{manualCurrencyFormatter(saldo)}</strong> em <strong>{dataFim}</strong>.
                            </span>
                        </Grid>
                    </Grid>
                )}

                {state === STATES.ERROR && (
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item>
                            <ErrorOutlineIcon color="error" fontSize="large" />
                        </Grid>
                        <Grid item>
                            <span>Erro ao importar extrato. Tente novamente.</span>
                        </Grid>
                    </Grid>
                )}

                {state === STATES.IMPORT && (
                    <Grid container spacing={2} sx={{width: "100%"}} data-testid="importar-ofx">
                        <Grid item xs={12}>
                            <span>Insira o arquivo que será importado para a conta: <strong>{selectedContaId?.label}</strong>.</span>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center">
                        <Grid item>
                            <DefaultButton title="Anexar .ofx" onClick={handleClick} startIcon={<AttachFileOutlinedIcon />} />
                            <input type="file" accept=".ofx" ref={hiddenFileInput} onChange={changeHandler} style={{ display: "none" }} />
                        </Grid>
                        {isSelected && <Grid item><span>Nome do arquivo: {selectedFile?.name}</span></Grid>}
                        </Grid>
                        {arquivoVazio && mostrarAvisoArquivoVazio &&
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        border: `solid 1px ${RED_ERROR_UX}`,
                                        borderLeft: `7px solid ${RED_ERROR_UX}`,
                                        borderRadius: "4px",
                                        width: "100%",
                                        padding: 10,
                                        display: "flex",
                                        gap: "10px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <ErrorIcon
                                            sx={{
                                                color: RED_ERROR_UX,
                                            }}
                                        />
                                        <div style={{paddingLeft: "16px"}}>
                                            <Typography fontSize="16px" fontWeight="light">
                                                O arquivo selecionado está vazio. Por favor, selecione outro arquivo.
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <CloseIcon
                                            onClick={() => setMostrarAvisoArquivoVazio(false)} 
                                            fontSize="small" 
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </div>
                            </Grid>}
                    </Grid>
                )}


            </DialogContent>
            <DialogActions sx={{ backgroundColor: temaEscuro && BLUE_THEME }}>
                <Grid container justifyContent="space-between" sx={{ padding: 1 }}>
                    <Grid item data-testid="botao-cancelar">
                        <DefaultButton title="Cancelar" onClick={() => setOpenModalOFX(false)} />
                    </Grid>
                    {state === STATES.CONFIRM && (
                        <Grid item>
                            <DefaultButton title="Confirmar" disabled={!confirmarSaldo} onClick={() => sendExtratoOfx(selectedFile)} variant="yellow" />
                        </Grid>
                    )}
                    {state === STATES.IMPORT && (
                        <Grid item data-testid="botao-importar-ofx">
                            <DefaultButton title="Importar" onClick={() => getOfxInfo(selectedFile)} variant="yellow" />
                        </Grid>
                    )}
                    {state === STATES.SUCCESS && (
                        <Grid item>
                            <DefaultButton title="Conciliar minhas movimentações" onClick={() => setOpenModalOFX(false)} variant="yellow" />
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ImportOFXModal;