import React, { useState, useEffect } from "react";
import Header from "../../components/Titles/Header";
import Paper from "@mui/material/Paper";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    WHITE_ESCRITA_THEME,
    BORDER_TABLE,
    COLOR_CONTAS1,
    COLOR_CONTAS2,
    COLOR_CONTAS3,
    COLOR_CONTAS4,
    WHITE_THEME_BLACK,
    DRE_PRE_SALVA_GRAY,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import InputSearch from "../../components/Input/InputSearch";
import DefaultButton from "../../components/Buttons/DefaultButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid } from "@mui/material";
import SelectEstabelecimento from "../../components/SelectEstabelecimento/SelectEstabelecimento";
import DataGrid from "../../components/DataGrid/DataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import api from "../../axios";
import Skeleton from "@mui/material/Skeleton";
import {showSnackMessage} from "../../actions/SnackActions";
import DeleteContaModal from "../../components/Modal/DeleteContaModal";
import { Link, useNavigate } from "react-router-dom";
import CertificadoAlert from "../../components/Alert/CertificadoAlert";


const Contas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [contas, setContas] = useState([]);
    const [tipoConta, setTipoConta] = useState([]);
    const [bancos, setBancos] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [contasCorrente, setContasCorrente] = useState([]);
    const [cartaoEmissores, setCartaoEmissores] = useState([]);
    const [contasPagamento, setContasPagamento] = useState([]);
    const [bandeiras, setBandeiras] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [contaDetails, setContaDetails] = useState([]);
    const [contasSemCertificado, setContasSemCertificado] = useState([]);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);
    const isCommonUser = useSelector(state => state.AuthReducer.isCommonUser);

    useEffect(() => {
        if (estabelecimentoId !== 0) {
            getContas();
            if (!isCommonUser || isOperator) {
                checkContaCertificado();
            }
        }
    }, [estabelecimentoId]);

    const checkContaCertificado = () => {
		const dataRequest = {
			estabelecimento_id: estabelecimentoId
		};

		api.CheckContaCertificado(dataRequest).then((response) => {
			setContasSemCertificado(response.data);
		}).catch(() => {
			dispatch(showSnackMessage({message: "Erro ao buscar contas sem certificados", severity: "error"}));
		});
	};

    const getColorSituacaoIntegracao = (value) => {
        const isAutomaticOrDirect = value === "Automática" || value === "Direta";
    
        if (temaEscuro) {
            return isAutomaticOrDirect ? COLOR_CONTAS2 : WHITE_ESCRITA_THEME;
        } else {
            return isAutomaticOrDirect ? COLOR_CONTAS2 : LINE_TABLE;
        }
    };
    
    const getBackgroundColorSituacaoIntegracao = (value) => {
        const isAutomaticOrDirect = value === "Integrado";
    
        if (temaEscuro) {
            return isAutomaticOrDirect ? COLOR_CONTAS1 : BORDER_TABLE;
        } else {
            return isAutomaticOrDirect ? COLOR_CONTAS1 : DRE_PRE_SALVA_GRAY;
        }
    };

    const handleOpenModalDeleteConta = (conta) => {
        setContaDetails(conta);
        setOpenDeleteModal(true);
    };

    const columns = [
        { 
            field: "apelido", 
            headerName: "Apelido", 
            width: 200, 
            align: "center", 
            headerAlign: "center", 
            flex: 1 
        },
        { 
            field: "banco_formatted", 
            headerName: "Banco", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1 
        },
        { 
            field: "tipo", 
            headerName: "Tipo", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1 
        },
        { 
            field: "status_cobranca", 
            headerName: "Status", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1,
            renderCell: (params) => {
                if (params.value == null) {
                    return (
                        <span> - </span>
                    );
                }
            }
        },
        { 
            field: "conciliacoes", 
            headerName: "Conciliações", 
            width: 150, 
            align: "center", 
            headerAlign: "center", 
            flex: 1,
            renderCell: (params) => {
                if (params.value !== null && params.value !== "") {
                    if (params.value === "Pendente") {
                        return (
                            <span 
                                style={{
                                    backgroundColor: COLOR_CONTAS3,
                                    padding: 8,
                                    paddingInline: 19,
                                    borderRadius: "80px",
                                    color: COLOR_CONTAS4,
                                    fontWeight: 600 
                                }}
                            >
                                {params.value}
                            </span>
                        );
                    }
                } else {
                    return (
                        <span> - </span>
                    );
                }
            }
        },
        { 
            field: "situacao_integracao", 
            headerName: "Integração", 
            width: 200, 
            align: "center", 
            headerAlign: "center", 
            flex: 1,
            renderCell: (params) => {
                const situacao = params.value;
                if (situacao === "Sem integração" || situacao === "Integrado") {
                    const color = getColorSituacaoIntegracao(situacao);
                    const backgroundColor = getBackgroundColorSituacaoIntegracao(situacao);
                    return (
                        <span 
                            style={{
                                backgroundColor: backgroundColor,
                                padding: 8,
                                paddingInline: 19,
                                borderRadius: "80px",
                                color: color,
                                fontWeight: 600
                            }}
                        >
                            {situacao}
                        </span>
                    );
                } else {
                    return (
                        <span> - </span>
                    );
                }
            }
        },
        {
            field: "acoes",
            headerName: "Ações",
            width: 100,
            align: "center",
            headerAlign: "center",
            flex: 0.5,
            renderCell: (params) => (
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{height: "100%"}}
                    className="action-button"
                >
                    <DeleteIcon
                        sx={{
                            color: temaEscuro ? WHITE_THEME_BLACK : BORDER_TABLE,
                            cursor: (isAdmin || isOperator || isAprover) ? "pointer" : "not-allowed",
                            transition: "opacity 0.3s ease",
                            "&:hover": {
                                opacity: 0.6,
                            },
                            opacity: isAdmin || isOperator || isAprover ? 1 : 0.6,
                        }}
                        onClick={() => {
                            if (isAdmin || isOperator || isAprover) {
                                handleOpenModalDeleteConta(params.row);
                            }
                        }}
                        data-testid="delete-conta"
                    />
                </Box>
            ),
        },
    ];

    const getContas = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            search: searchValue
        };
        api.GetContas(dataRequest).then(response => {
            let dataResponse = response.data;
            setLoading(false);
            setContas(dataResponse.contas);
            setTipoConta(dataResponse.tipo_contas);
            setBancos(dataResponse.bancos);
            setModalidades(dataResponse.modalidades);
            setCartaoEmissores(dataResponse.cartao_emissores);
            setContasPagamento(dataResponse.cartao_contas_pagamento);
            setBandeiras(dataResponse.bandeiras);
            setContasCorrente(dataResponse.contas_corrente);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const deleteConta = () => {
        setOpenDeleteModal(false);
        setLoading(true);
        const dataRequest = {
            conta_id: contaDetails.id,
            estabelecimento_id: estabelecimentoId
        };
        api.DeleteConta(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Conta deletada com sucesso!", severity: "success" }));
            getContas();
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };


    const handleRowClick = (row, event) => {

        const isActionButton = event.target.closest(".action-button");

        if (!isActionButton) {
            let selectedConta = {label: row.row.apelido, value: row.row.id};
            navigate("/detalhamento_conta", { state: {
                selectedConta: selectedConta,
                contas: contas,
                tipoContaSelected: row.row.tipo,
                rowDetails: row.row,
                tipoConta: tipoConta,
                bancos: bancos,
                modalidades: modalidades,
                cartaoEmissores: cartaoEmissores,
                contasPagamento: contasPagamento,
                bandeiras: bandeiras,
                contasCorrente: contasCorrente
            }});
        }
    };

    return (
        <React.Fragment>
            <DeleteContaModal 
                openDeleteModal={openDeleteModal} 
                setOpenDeleteModal={setOpenDeleteModal}
                deleteConta={deleteConta}
            />
            <div className="main" data-testid="contas-container">
                <Header title="Contas" dataTestId="header-contas" />
                {
                    contasSemCertificado.length > 0 && (
                        <CertificadoAlert
                            contasSemCertificado={contasSemCertificado}
                        />
                    )
                }
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                        backgroundImage: temaEscuro && "none", 
                        border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`}}
                    data-testid="perfil-screen"
                >
                    {loading ? (
                        [...Array(15).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        estabelecimentoId === 0 ? (
                            <SelectEstabelecimento/>
                        ) : (
                            <Grid container spacing={2.7} sx={{display: "flex", justifyContent: "space-between"}}>
                                <Grid item>
                                    <InputSearch
                                        functionProps={getContas}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        className="input-text"
                                    />
                                </Grid>
                                {
                                    (isAdmin || isOperator || isAprover) && (
                                        <Grid item sx={{display: "flex", gap: 2}}>
                                            <Link to="/nova_conta">
                                                <DefaultButton
                                                    startIcon={<AddCircleOutlineIcon />}
                                                    title="Nova Conta"
                                                    testId="nova-conta"
                                                /> 
                                            </Link>
                                            {/* <DefaultButton
                                                startIcon={<SettingsIcon />}
                                                title="Configurar Conciliação Automática"
                                                testId="configurar-conciliacao-conta"
                                            /> */}
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sx={{maxHeight: "80vh"}}>
                                    <DataGrid
                                        columns={columns}
                                        data={contas}
                                        rows={(row) => row.id}
                                        NoneFooter
                                        customRowClick={(row, event) => {
                                            if (isAdmin || isOperator || isAprover) {
                                                handleRowClick(row, event);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    )}
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default Contas;