import React, {useState, useEffect} from "react";
import Paper from "@mui/material/Paper";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    WHITE_ESCRITA_THEME,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    BLACK_LABEL_UX,
    BLACK_TABLE_PERFIL,
    GRAY_LABEL_UX_THEME,
    BLUE_THEME
} from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import api from "../../../axios";
import {showSnackMessage} from "../../../actions/SnackActions";
import InputSearch from "../../../components/Input/InputSearch";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import Anexos from "../../../components/Tabs/Anexos";
import moment from "moment";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import { Skeleton } from "@mui/material";
import InputCurrency from "../../../components/Input/InputCurrency";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditarTransferencia = (props) => {

    const { idMovimentacao, open, setOpen, recalculatePagePrevious, tipoLancamentoMov } = props;

    const dispatch = useDispatch();
    const id_movimentacao = idMovimentacao;
    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isAprover);

    const [loading, setLoading] = useState(false);
    const [contaOrigem, setContaOrigem] = useState("");
    const [contaDestino, setContaDestino] = useState("");
    const [descricao, setDescricao] = useState("");
    const [selectedDataTransferencia, setSelectedDataTransferencia] = useState("");
    const [valor, setValor] = useState(0);
    const [inputError, setInputError] = useState(false);
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [contaOptions, setContaOptions] = useState([]);

    const [loadingAnexos, setLoadingAnexos] = useState(true);
    const viewOnly = !(isAdmin || isOperator || isAprover);

    useEffect(() => {
        if (open) {
            getInfoTransferencia();
            getAnexos();
        }
    }, [open]);

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            lancamento_id: id_movimentacao,
            tab: tipoLancamentoMov,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        });
    };

    useEffect(() => {
        if (contaOrigem !== null && contaDestino !== null) {
            setDescricao(`Origem: ${contaOrigem} / Destino: ${contaDestino}`);
        }
    }, [contaOrigem, contaDestino]);

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}/${mes}/${ano}`);
    };

    const formatDateIfNeeded = (date) => {
        return typeof(date) !== "object" ? date : moment(date).format("DD/MM/YYYY");
    };

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);
    
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
    
            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });
    
            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
            
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const getInfoTransferencia = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            lancamento_id: id_movimentacao,
            state: "transferencias"
        };
        api.GetInfoMovimentacao(dataRequest).then((response) => {
            const data = response.data;
            setContaDestino(data.conta_destino);
            setContaOrigem(data.conta_origem);
            setContaOptions(data.contas);
            setDescricao(data.movimentacao.descricao);
            setSelectedDataTransferencia(moment(data.movimentacao.data_competencia, "YYYY-MM-DD").toDate());
            setValor(data.movimentacao.valor_total);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleEditTransferencia = () => {
        if (!contaOrigem || !contaDestino || !selectedDataTransferencia || !valor) {
            setInputError(true);
            return;
        }
        setLoading(true);
        setLoadingAnexos(true);
        let formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("movimentacao_id", id_movimentacao);
        formData.append("conta_origem", contaOrigem);
        formData.append("conta_destino", contaDestino);
        formData.append("descricao_movimentacao", descricao);
        formData.append("data_compra", formatDateIfNeeded(selectedDataTransferencia));
        formData.append("valor_movimentacao", valor);
        formData.append("anexos", JSON.stringify(anexos));

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        api.EditarMovimentacao(formData).then(() => {
            setLoading(false);
            setLoadingAnexos(false);

            dispatch(showSnackMessage({message: "Transferência editada com sucesso!", severity: "success"}));
            closeModal();
            recalculatePagePrevious();
        }).catch(() => {
            setLoading(false);
            setLoadingAnexos(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const closeModal = () => {
        setInputError(false);
        setOpen(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                    backgroundImage: "none"
                },
            }}
            data-testid="movimentacao-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? BLUE_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        Editar transferência
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }} data-testid="exm-container-edit-transferencia">
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                    data-testid="perfil-screen"
                >
                    {loading ? (
                        [...Array(5).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span><p className="title-container-p">Informações da transferência</p></span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                <FilterSingleOption
                                    filterOptions={contaOptions}
                                    selectedOption={contaOrigem}
                                    setSelectedOption={setContaOrigem}
                                    placeholder="Conta de origem"
                                    width={"250px"}
                                    inputError={inputError}
                                    disabled={viewOnly}
                                />
                                <FilterSingleOption
                                    filterOptions={contaOptions}
                                    selectedOption={contaDestino}
                                    setSelectedOption={setContaDestino}
                                    placeholder="Conta de destino"
                                    width={"250px"}
                                    inputError={inputError}
                                    disabled={viewOnly}
                                />
                                <InputSearch
                                    functionProps={() => {}}
                                    setSearchValue={setDescricao}
                                    searchValue={descricao}
                                    label={"Descrição"}
                                    width={"250px"}
                                    testId={"input-descricao"}
                                    disabled={viewOnly}
                                />
                                <FilterDayMonthYear
                                    handleSubmit={handleDayMonthYear}
                                    value={selectedDataTransferencia}
                                    setState={setSelectedDataTransferencia}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data da transferência"}
                                    isEditConta
                                    inputError={inputError}
                                    disabled={viewOnly}
                                    disableText={viewOnly}
                                />
                                <InputCurrency
                                    valor={valor}
                                    setValor={setValor}
                                    title={"Valor"}
                                    data-id={"input-faturamento-bruto"}
                                    disabled={viewOnly}
                                />
                                <Grid item xs={12} sx={{marginTop: 2}}>
                                    <Anexos
                                        loading={loadingAnexos}
                                        anexos={anexos}
                                        setAnexos={setAnexos}
                                        isTransferencia
                                        disabled={viewOnly}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                <Grid container>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2, margin: `0px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`}}>
                        <DefaultButton
                            onClick={() => closeModal()}
                            title="Cancelar"
                        />
                        <DefaultButton
                            title={"Salvar"}
                            onClick={() => handleEditTransferencia()}
                            variant="yellow"
                            disabled={loading || viewOnly}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default React.memo(EditarTransferencia);