import React, { useState }  from "react";
import Grid from "@mui/material/Grid";
import {BORDER_TABLE, BORDER_PAPER} from "../../shared/utils";
import { Divider } from "@mui/material";
import DefaultButton from "../Buttons/DefaultButton";
import api from "../../axios";


const UpdateWarning = (props) => {
    const {setOpenModal, temaEscuro, dispatch, showSnackMessage, exmItems, tab, getExtratoMovimentacoes, type} = props;

    const [loading, setLoading] = useState(false);

    const updateMovimentacaoType = () => {
        setLoading(true);

        let requestType = "";

        if (type === "agendado") {
            requestType = "Agendado";
        }
        else if (type === "pago") {
            requestType = "Pago";
        }
        else if (type === "em_aberto") {
            requestType = "Em Aberto";
        }
        else {
            requestType = "Não Agendado";
        }

        const dataRequest = {
            selected_movimentacoes: exmItems,
            type: requestType,
            tab: tab,
        };

        api.UpdateTypeMovimentacoes(dataRequest).then(response => {
            getExtratoMovimentacoes();
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
            setLoading(false);
            setOpenModal(false);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Não foi possível atualizar as movimentações selecionadas.", severity: "error"}));
            setLoading(false);
        });
    };

    const getInfoText = () => {
        if (type === "agendado") {
            return "Ao realizar esta ação, todas as parcelas selecionadas serão marcadas como agendadas, considerando a data de previsão de cada uma das parcelas.";
        }
        else if (type === "nao_agendado") {
            return "Ao realizar esta ação serão desmarcados os agendamentos das parcelas selecionadas.";
        }
        else if (type === "pago") {
            return "Ao realizar esta ação, será feita a baixa do valor dos registros selecionados, na conta previamente parametrizada para os mesmos. As baixas são efetivadas somente para aqueles com situação Em Aberto, que não estejam marcados como perda ou desconsiderados.";
        }
        else {
            return "Deseja definir os registros como em aberto?";
        }
    };

    return(
        <React.Fragment>
            <Grid sx={{marginTop: 5, display: "flex", flexDirection: "column", gap: "2rem"}}>
                <span>Você selecionou {exmItems.length} registro(s).</span>
                <span>{getInfoText()}</span>
            </Grid>
            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, mb: "1rem", mt: "1.5rem"}} />
            <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                <DefaultButton
                    title="Cancelar"
                    onClick={() => setOpenModal(false)}
                />
                <DefaultButton
                    variant="yellow"
                    title="Confirmar"
                    onClick={() => updateMovimentacaoType()}
                    loading={loading}
                />
            </Grid>
        </React.Fragment>
    );                  
};

export default UpdateWarning;