import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditLancamentoRecorrenteModal from "../../components/Modal/EditLancamentoRecorrenteModal";
import { changeOpenLancamento } from "../../actions/StoreActions";
import { useDispatch } from "react-redux";
import WarningModal from "../Modal/WarningModal";

const SelectActions = (
    {
        params,
        recorrenciaAction,
        selectedChoice,
        setSelectedChoice,
        setSelectedLancamentoRecorrente,
        allowClonar=false,
        selectedTab,
        getExtratoMovimentacoes,
        setOpenModalDesfazerConciliacao,
        setDesconciliarItem,
        updateMovimentacaoType,
        handleEditMovimentacao,
        setSelectedItem,
        setOpenInformarPagamentoModal,
    }
) => {

    const dispatch = useDispatch();

    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);

    const [openEditLancamentoRecorrenteModal, setOpenEditLancamentoRecorrenteModal] = useState(false);
    const [recorrenciaActionType, setRecorrenciaActionType] = useState(null);
    const [openWarningModal, setOpenWarningModal] = useState(false);

    const [action, setAction] = useState(null);

    const handleChange = (event) => {
        setAction(event.target.value);
    };

    const handleOpenModalEditLancamentoRecorrente = () => {
        if (params.recorrencia !== null) {
            setOpenEditLancamentoRecorrenteModal(true);
        }
    };

    const handleClonarLancamento = (params) => {
        let changeOpenData = {
            id: params.id,
            tab: selectedTab,
        };

        if (selectedTab === "movimentacoes") {
            changeOpenData.tipo = params.valor_total > 0 ? "receita" : "despesa";
        } else if (selectedTab === "contas_pagar") {
            changeOpenData.tipo = "despesa";
        } else if (selectedTab === "contas_receber") {
            changeOpenData.tipo = "receita";
        } else {
            changeOpenData.tipo = params.valor > 0 ? "receita" : "despesa";
        }
    
        dispatch(changeOpenLancamento(changeOpenData));
    };

    const handleOpenDesfazerConciliacao = () => {
        setDesconciliarItem({...params, "descricao_vxmf": params.descricao});
        setOpenModalDesfazerConciliacao(true);
    };

    const handleOpenInformarPagamento = () => {
        setSelectedItem(params.id);
        setOpenInformarPagamentoModal(true);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{height: "100%"}}
        >
            {openEditLancamentoRecorrenteModal && <EditLancamentoRecorrenteModal
                openModal={openEditLancamentoRecorrenteModal}
                setOpenModal={setOpenEditLancamentoRecorrenteModal}
                setAction={setAction}
                lancamento={params}
                recorrenciaAction={recorrenciaAction}
                selectedChoice={selectedChoice}
                setSelectedChoice={setSelectedChoice}
                setSelectedLancamentoRecorrente={setSelectedLancamentoRecorrente}
                type={recorrenciaActionType}
                setType={setRecorrenciaActionType}
                tab={selectedTab}
                getExtratoMovimentacoes={getExtratoMovimentacoes}
            />}
            <WarningModal
                openModal={openWarningModal}
                setOpenModal={setOpenWarningModal}
                type={"excluir"}
                exmItems={[params.id]}
                tab={selectedTab}
                getExtratoMovimentacoes={getExtratoMovimentacoes}
            />
            <FormControl sx={{width: "100%"}}>
                <InputLabel>Ações</InputLabel>
                <Select
                    disabled={!(isAdmin || isOperator || isAprover)}
                    value={action}
                    label="Ações"
                    onChange={handleChange}
                    sx={{width: "100%"}}
                >
                    {
                        (params.situacao !== "Transferido" && !(params.recorrencia_total > 0)) && (
                            <MenuItem value={"edit_lancamento"} onClick={() => {
                                if (isAdmin || isOperator || isAprover) {
                                    handleEditMovimentacao(params);
                                }
                            }}>
                                Editar lançamento
                            </MenuItem>
                        )
                    }
                    {
                        params.recorrencia_total > 0 && (
                            <MenuItem value={"edit_lancamento"} onClick={() => {
                                if ((isAdmin || isOperator || isAprover) && params.recorrencia_total > 0) {
                                    handleOpenModalEditLancamentoRecorrente();
                                    setRecorrenciaActionType("edit");
                                }
                            }}
                            disabled={!(isAdmin || isOperator || isAprover) && params.recorrencia_total === 0}
                            >
                                Editar lançamento
                            </MenuItem>
                        )
                    }
                    {
                        params.situacao !== "Transferido" && (params?.previsao || !(params?.valor_em_aberto === 0)) && (
                            <MenuItem value={"informar_pagamento"} onClick={() => {
                                if (isAdmin || isOperator || isAprover) {
                                    handleOpenInformarPagamento();
                                }
                            }}>
                                Informar pagamento
                            </MenuItem>
                        )
                    }
                    {
                        selectedTab === "movimentacoes" && params.conciliado  && (
                            <MenuItem value={"desfazer_conciliacao"} onClick={() => {
                                if (isAdmin || isOperator || isAprover) {
                                    handleOpenDesfazerConciliacao();
                                }
                            }}>
                                Desfazer conciliação
                            </MenuItem>
                        )
                    }
                    {
                        ["Pago", "Recebido", "Pago Parcial"].includes(params.situacao) && (
                            <MenuItem value={"voltar_em_aberto"} onClick={() => {
                                if (isAdmin || isOperator || isAprover) {
                                    updateMovimentacaoType(params.id);
                                }
                            }}>
                                Voltar para em aberto
                            </MenuItem>
                        )
                    }
                    {
                        allowClonar && (
                            <MenuItem
                                onClick={() => handleClonarLancamento(params)}
                            >
                                Clonar lançamento
                            </MenuItem>
                        )
                    }
                    {
                        (!params.item_compra && params.recorrencia_total === 0) && (
                            <MenuItem
                                value={"delete"}
                                onClick={() => {
                                    if (isAdmin || isOperator || isAprover) {
                                        setOpenWarningModal(true);
                                    }
                                }}
                            >
                                Excluir lançamento
                            </MenuItem>
                        )
                    }
                    {
                        (!params.item_compra && params.recorrencia_total > 0) && (
                            <MenuItem
                                value={"delete"}
                                onClick={() => {
                                    if ((isAdmin || isOperator || isAprover) && params.recorrencia_total > 0) {
                                        handleOpenModalEditLancamentoRecorrente();
                                        setRecorrenciaActionType("delete");
                                    }
                                }}
                            >
                                Excluir lançamento
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectActions;