import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeNotaId, changeStore } from "../../actions/StoreActions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER, BORDER_TABLE, formatterPercentage, formatterThousand, GRAY_BG_UX, GRAY_LABEL_UX,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle, styleText, WHITE_ESCRITA_THEME, WHITE_THEME_BLACK, YELLOW_BG_UX, WHITE_TABLE, ROW_MAIN, getCurrentDate,
    GRAY_PDF,
    RED_INFO,
    TITLE_REUNIAO,
    GRAY_LABEL_UX_THEME,
    BLACK_LABEL_UX,
    BLACK_TABLE,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    handleDayMonthYear,
    formaPagamentoOptions,
    BLACK_TABLE_PERFIL,

} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputSearch from "../../components/Input/InputSearch";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import InputNumber from "../../components/Input/InputNumber";
import api from "../../axios";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DefaultButton from "../../components/Buttons/DefaultButton";
import ParcelasModal from "../../components/Modal/ParcelasModal";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import moment from "moment";
import CreateOptionModal from "../../components/Modal/CreateOptionModal";
import { showSnackMessage } from "../../actions/SnackActions";
import Divider from "@mui/material/Divider";
import InputCurrency from "../../components/Input/InputCurrency";
import Anexos from "../../components/Tabs/Anexos";
import SaveOptions from "../../components/Buttons/SaveOptions";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { changeOpenLancamento } from "../../actions/StoreActions";
import Decimal from "decimal.js";
import { Tooltip } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GerarCompra = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const openLancamento = useSelector(state => state.StoreReducer.openLancamento);
    const notaId = useSelector(state => state.StoreReducer.notaId);

    const isLargeScreen = useMediaQuery("(min-width:1440px)");
    const [open, setOpen] = useState(openLancamento === "compra");
    const [loading, setLoading] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [fornecedoresOptions, setFornecedoresOptions] = useState([]);
    const [produtoOptions, setProdutoOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const formaRecorrenciaOptions = ["Ano/anos", "Mês/meses", "Semana/semanas", "Dia/dias"];
    const recorrenciaTerminoOptions = ["Em um período específico", "Após alguns ocorrências"];

    const parcelaOptions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    const currentMonthDate = moment(getCurrentDate()).toDate();

    const [recorrenciaVencimentoOptions, setRecorrenciaVencimentoOptions] = useState(["Segunda-feira", "Terça-feira", "Quarta-feria", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"]);

    const [selectedFornecedor, setSelectedFornecedor] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [selectedDataCompra, setSelectedDataCompra] = useState(currentMonthDate);
    const [repetirCompra, setRepetirCompra] = useState(false);
    const [cadastrarProduto, setCadastrarProduto] = useState(false);
    const [qtdRecorrenciaCompra, setQtdRecorrenciaCompra] = useState(1);
    const [formaRecorrenciaCompra, setFormaRecorrenciaCompra] = useState("Mês/meses");
    const [selectedDataCompraRecorrencia, setSelectedDataCompraRecorrencia] = useState(currentMonthDate);
    const [selectedRecorrenciaVencimento, setSelectedRecorrenciaVencimento] = useState(null);
    const [selectedDataRecorrenciaTermino, setSelectedDataRecorrenciaTermino] = useState(currentMonthDate);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [totalProdutos, setTotalProdutos] = useState(0);
    const [numeroRecorrenciaCompra, setNumeroRecorrenciaCompra] = useState(1);
    const [desconto, setDesconto] = useState(0);
    const [descontoInput, setDescontoInput] = useState(0);
    const [frete, setFrete] = useState(0);
    const [seguro, setSeguro] = useState(0);
    const [impostos, setImpostos] = useState(0);
    const [custosAdicionais, setCustosAdicionais] = useState(0);
    const [selectedParcela, setSelectedParcela] = useState("1");
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [parcelas, setParcelas] = useState([]);
    const [parcelaDataVencimento, setParcelaDataVencimento] = useState(currentMonthDate);
    const [descricao, setDescricao] = useState("");
    const [selectedRecorrenciaTermino, setSelectedRecorrenciaTermino] = useState("Em um período específico");
    const [items, setItems] = useState([
        {
            produto_servico: "",
            detalhes_item: "",
            quantidade: 0,
            valor_unitario: 0,
        },
    ]);
    const [observacoes, setObservacoes] = useState("");

    const [selectedReaisPercent, setSelectedReaisPercent] = useState("reais");
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");
    const [intervaloParcelas, setIntervaloParcelas] = useState(0);
    const [contasOptions, setContasOptions] = useState([]);
    const [selectedConta, setSelectedConta] = useState(null);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        if (notaId !== null) {
            getCompraInfoByNotaId();
        }
    }, [notaId]);

    useEffect(() => {
        if (locationId) {
            const query = new URLSearchParams(location.search);
            setLocationId(parseInt(query.get("estabelecimento_id")));
            if (locationId !== 0 && locationId != null) {
                dispatch(changeStore(locationId));
            }
        }
    }, [locationId]);

    useEffect(() => {
        if (repetirCompra) {
            setSelectedParcela("1");
        }
    }, [repetirCompra]);

    useEffect(() => {
        if (formaRecorrenciaCompra === "Semana/semanas") {
            setRecorrenciaVencimentoOptions(["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"]);
        } else if (formaRecorrenciaCompra === "Mês/meses") {
            const options = [];
            for (let i = 1; i <= 30; i++) {
                options.push(`${i} Dia útil do mês`);
            }
            options.push("Último dia útil");
            setRecorrenciaVencimentoOptions(options);
        } else if (formaRecorrenciaCompra === "Ano/anos" || formaRecorrenciaCompra === "Dia/dias") {
            setSelectedRecorrenciaVencimento("1 Dia útil do mês");
        }
    }, [formaRecorrenciaCompra]);

    useEffect(() => {
        if (items) {
            setTotalProdutos(new Decimal(items.reduce((acc, item) => acc + (item.valor_unitario * item.quantidade), 0)).toDecimalPlaces(2).toNumber());
        }
    }, [items]);

    useEffect(() => {
        if (selectedReaisPercent === "reais") {
            setDesconto(descontoInput);
        } else {
            setDesconto((descontoInput / 100) * totalProdutos);
        }
    }, [selectedReaisPercent, descontoInput]);

    useEffect(() => {
        setCustosAdicionais(new Decimal(frete).plus(seguro).plus(impostos).toDecimalPlaces(2).toNumber());
    }, [frete, seguro, impostos]);

    useEffect(() => {
        if (selectedParcela > 0) {
            handleParcelas();
        }
    }, [totalProdutos, desconto, custosAdicionais, selectedParcela, parcelaDataVencimento, intervaloParcelas]);

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoId]);

    const closeModal = () => {
        setOpen(false);
        dispatch(changeOpenLancamento(""));
    };

    const styles = {
        selectedButton: {
            backgroundColor: YELLOW_BG_UX,
            color: BLUE_THEME,
            "&.MuiButtonBase-root:hover": {
                bgcolor: YELLOW_BG_UX,
            }
        },
        unselectedButton: {
            backgroundColor: temaEscuro ? BLUE_THEME : BORDER_PAPER,
            color: temaEscuro ? WHITE_ESCRITA_THEME : BLUE_THEME,
            "&.MuiButtonBase-root:hover": {
                bgcolor: TITLE_REUNIAO,
            }
        }
    };

    const getCompraInfoByNotaId = () => {
        setLoading(true);

        const dataRequest = {
            nota_id: notaId
        };

        api.GetNotaDetails(dataRequest).then(response => {
            let dataResponse = response.data;

            setSelectedFornecedor(dataResponse.fornecedor);
            setCodigoReferencia(dataResponse.codigo_referencia);
            setDescricao(`Compra de produto ${dataResponse.codigo_referencia}`);
            setObservacoes(dataResponse.observacoes);
            setSelectedDataCompra(moment(dataResponse.data_compra, "DD/MM/YYYY").toDate());
            setItems(dataResponse.itens);
            setCadastrarProduto(dataResponse.itens.length > 0);
            setFrete(dataResponse.valor_frete);
            setDesconto(dataResponse.valor_desconto);
            setDescontoInput(dataResponse.valor_desconto);
            setSeguro(dataResponse.valor_seguro);
            setImpostos(dataResponse.valor_impostos);
            setSelectedFormaPagamento(dataResponse.forma_pagamento);
            setParcelas(dataResponse.parcelas);
            setIntervaloParcelas(dataResponse.intervalo_parcelas);
            setSelectedParcela(dataResponse.parcelas.length);
            if (dataResponse.parcelas.length > 0) {
                setParcelaDataVencimento(moment(dataResponse.parcelas[0].data_vencimento, "DD/MM/YYYY").toDate());
            }
            setLoading(false);
        }).catch(({response}) => {
            setLoading(false);
            dispatch(showSnackMessage({ message: response?.data?.message ? response.data.message : "Não conseguimos preencher o formulário com os dados da nota dessa vez! Preencha-os manualmente ou tente novamente mais tarde", severity: "error" }));
        });
    };

    const getInputsOptions = () => {
        if (estabelecimentoId !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                papel_pessoa: "Fornecedor",
            };
            api.GetFornecedores(dataRequest).then(response => {
                setFornecedoresOptions(response.data);
            });

            api.GetCategorias({ ...dataRequest, "tipo": "Despesa" }).then(response => {
                setCategoriaOptions(response.data);
            });
            api.GetProdutosServicos(dataRequest).then(response => {
                setProdutoOptions(response.data);
            });
            api.RetrieveContas(dataRequest).then(response => {
                setContasOptions(response.data);
            });
            setLoading(false);
        }
    };

    const postSaveCompra = (redirect = true) => {
        setLoading(true);
        for (let parcela of parcelas) {
            parcela.data_vencimento = moment(parcela.data_vencimento).format("DD-MM-YYYY");
            if (parcela.data_vencimento === "Data inválida") {
                setLoading(false);
                dispatch(showSnackMessage({ message: "A data da parcela é inválida, por favor recarregue a página e tente novamente!", severity: "info" }));
                return;
            }
        }
        let formData = new FormData();
        if (notaId) {
            formData.append("nota_id", notaId);
        }
        formData.append("fornecedor_id", selectedFornecedor?.value);
        formData.append("categoria_id", selectedCategoria.value);
        formData.append("conta_id", selectedConta.value);
        formData.append("data_compra", moment(selectedDataCompra).format("DD-MM-YYYY"));
        formData.append("data_vencimento", moment(parcelaDataVencimento).format("DD-MM-YYYY"));
        formData.append("valor_desconto", desconto);
        formData.append("valor_frete", frete);
        formData.append("valor_impostos", impostos);
        formData.append("valor_seguro", seguro);
        formData.append("valor_total", totalProdutos - desconto + custosAdicionais);
        formData.append("forma_pagamento", selectedFormaPagamento);
        formData.append("condicao_pagamento", selectedParcela);
        formData.append("parcelas", JSON.stringify(parcelas));
        formData.append("descricao", descricao);
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("observacoes", observacoes);
        if (repetirCompra) {
            const recorrenciaData = {
                qtd_recorrencia: qtdRecorrenciaCompra,
                forma_recorrencia: formaRecorrenciaCompra,
                data_compra_recorrencia: moment(selectedDataCompraRecorrencia).format(),
                vencimento_recorrencia: selectedRecorrenciaVencimento,
            };
            if (selectedRecorrenciaTermino === "Após alguns ocorrências") {
                recorrenciaData.numero_recorrencia = numeroRecorrenciaCompra;
            } else if (selectedRecorrenciaTermino === "Em um período específico") {
                recorrenciaData.data_termino_recorrencia = selectedDataRecorrenciaTermino;
            }
            formData.append("recorrencia", JSON.stringify(recorrenciaData));
        }
        if (cadastrarProduto) {
            formData.append("itens", JSON.stringify(items));
        }
        if (anexos[0].arquivo != null) {
            formData.append("quantidade_anexo", anexos.length);
            // formData.append("anexos", JSON.stringify(anexos));
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
        api.PostGerarCompra(formData).then(() => {
            if (redirect) {
                closeModal();
            } else {
                handleParcelas();
                setSelectedParcela(selectedParcela);
            }
            setLoading(false);
            dispatch(showSnackMessage({ message: "Compra gerada com sucesso!", severity: "success" }));
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    const handleRepetirCompra = (event) => {
        setRepetirCompra(event.target.checked);
    };

    const handleParcelas = (skipIndex = false, skipRow = {}, alteredField = "") => {
        let _parcelas = [];
        let valorTotal = 0;
        let percentTotal = 0;
        const valorTotalOriginal = new Decimal(totalProdutos).minus(desconto).plus(custosAdicionais).toDecimalPlaces(2).toNumber();
        let _totalValue = valorTotalOriginal;
        let parcelasQuantidade = selectedParcela;
        for (let i = 0; i < selectedParcela; i++) {
            if ((skipIndex || typeof skipIndex === "number") && i === skipIndex) {
                if (alteredField === "valor") {
                    if (skipRow.valor >= valorTotalOriginal || skipRow.valor === 0) {
                        skipRow.error = true;
                        return;
                    }
                    _totalValue = valorTotalOriginal - skipRow.valor;
                    skipRow.percentual = parseFloat(formatterPercentage((skipRow.valor * 100) / valorTotalOriginal, 2));
                    percentTotal += skipRow.percentual;
                    skipRow.error = false;
                } else {
                    if (skipRow.percentual >= 100 || skipRow.percentual === 0) {
                        skipRow.error = true;
                        return;
                    }
                    percentTotal += parseFloat(skipRow.percentual);
                    skipRow.valor = parseFloat(formatterThousand(valorTotalOriginal * (skipRow.percentual / 100), true));
                    _totalValue -= skipRow.valor;
                    skipRow.error = false;
                }
                parcelasQuantidade = selectedParcela - 1;
                _parcelas.push(skipRow);
                continue;
            }
            let valorParcela = 0;
            let percentParcela = 0;
            if (i === selectedParcela - 1) {
                valorParcela = formatterThousand(_totalValue - valorTotal, true);
                percentParcela = formatterPercentage(100 - percentTotal, 2);
            } else {
                valorParcela = formatterThousand(_totalValue / parseFloat(parcelasQuantidade), true);
                percentParcela = formatterPercentage((valorParcela * 100) / valorTotalOriginal, 2);
            }
            // Calculando a data de vencimento com intervalo de dias
            let parcelaData = null;
            if (intervaloParcelas === 0 || intervaloParcelas === "") {
                parcelaData = moment(parcelaDataVencimento).add(i, "months");
            } else {
                parcelaData = moment(parcelaDataVencimento).add(i * intervaloParcelas, "days");
            }
            _parcelas.push({
                "valor": parseFloat(valorParcela),
                "percentual": parseFloat(percentParcela),
                "data_vencimento": parcelaData.toDate(),
                "descricao": ""
            });
            valorTotal += parseFloat(valorParcela);
            percentTotal += parseFloat(percentParcela);
        }
        setParcelas(_parcelas);
    };

    const handleRemoveRow = (idx) => {
        items.splice(idx, 1);
        setItems([...items]);
    };

    const handleCloseDialog = () => {
        dispatch(changeOpenLancamento(""));
    };

    const checkDisabled = () => {
        return (
            loading ||
            (!selectedCategoria) ||
            !selectedDataCompra ||
            (repetirCompra && selectedFormaPagamento) ||
            !selectedParcela ||
            !descricao ||
            !selectedConta ||
            !selectedFornecedor ||
            (cadastrarProduto && items[0].produto_servico === "" && items[0].quantidade === 1 && items[0].valor_unitario === null) ||
            (repetirCompra && (
                qtdRecorrenciaCompra === null ||
                formaRecorrenciaCompra === null ||
                selectedDataCompraRecorrencia === null ||
                selectedRecorrenciaVencimento === null ||
                selectedDataRecorrenciaTermino === null)
            ) ||
            items.some(item => item.detalhes_item && item.detalhes_item.length > 250)
        );
    };

    const cleanCompraInputs = () => {
        setSelectedFornecedor(null);
        setCodigoReferencia("");
        setSelectedDataCompra(currentMonthDate);
        setRepetirCompra(false);
        setQtdRecorrenciaCompra(null);
        setFormaRecorrenciaCompra(null);
        setSelectedDataCompraRecorrencia(currentMonthDate);
        setSelectedRecorrenciaVencimento(null);
        setSelectedDataRecorrenciaTermino(currentMonthDate);
        setSelectedCategoria(null);
        setItems([
            {
                produto_servico: "",
                detalhes_item: "",
                quantidade: 1,
                valor_unitario: null,
                total: null
            },
        ]);
        setObservacoes("");
        setFrete(0);
        setDesconto(0);
        setImpostos(0);
        setSeguro(0);
        setSelectedParcela(null);
        setSelectedFormaPagamento(null);
        setParcelas([]);
        setParcelaDataVencimento(currentMonthDate);
        setDescricao("");
        setContasOptions([]);
        dispatch(changeNotaId(null));
    };

    return (
        <React.Fragment>
            <ParcelasModal
                openModal={openParcelasModal}
                setOpenModal={setOpenParcelasModal}
                formaPagamento={selectedFormaPagamento}
                valorPagamento={new Decimal(totalProdutos).minus(desconto).plus(custosAdicionais).toDecimalPlaces(2).toNumber()}
                condicaoPagamento={selectedParcela}
                parcelas={parcelas}
                setParcelas={setParcelas}
                handleParcelas={handleParcelas}
                intervaloParcelas={intervaloParcelas}
                setSelectedVencimento={setParcelaDataVencimento}
            />
            <CreateOptionModal
                openModal={openCreateModal}
                setOpenModal={setOpenCreateModal}
                option={optionCreateModal}
                getInputsOptions={getInputsOptions}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={() => closeModal()}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                        backgroundImage: "none"
                    },
                }}
                data-testid="gerar-compra-container"
            >
                <AppBar
                    sx={{
                        backgroundColor: temaEscuro
                            ? BLUE_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        borderBottom: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                        position: "relative",
                        boxShadow: "none"
                    }}
                >
                    <Toolbar>
                        <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1 }}>
                            Nova Compra
                        </Typography>
                        <IconButton
                            autoFocus
                            color="inherit"
                            onClick={() => closeModal()}
                            aria-label="close"
                            sx={{
                                color: !temaEscuro && BLACK_LABEL_UX
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ overflow: "auto", maxHeight: "100vh", marginBottom: "60px" }}>
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro
                                ? PAPER_PADDING_THEME
                                : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro
                                ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                    >
                        {
                            loading ? (
                                <Skeleton variant="rectangular" width={"100%"} height={"80vh"} />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <span>
                                            <p className="title-container-p">Informações da compra</p>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item sx={{ mr: "2rem" }}>
                                                <FilterSingleOption
                                                    isRequired
                                                    filterOptions={fornecedoresOptions}
                                                    selectedOption={selectedFornecedor}
                                                    setSelectedOption={setSelectedFornecedor}
                                                    placeholder="Fornecedor"
                                                    canCreate
                                                    setOpenCreateModal={setOpenCreateModal}
                                                    setOptionCreateModal={setOptionCreateModal}
                                                    testId="fornecedor-input"
                                                />
                                            </Grid>
                                            <Grid item sx={{ mr: "2rem" }}>
                                                <InputSearch
                                                    functionProps={() => { }}
                                                    setSearchValue={setCodigoReferencia}
                                                    searchValue={codigoReferencia}
                                                    className="input-text"
                                                    label={"Código de referência"}
                                                    testId="codigo-refencia-input"
                                                />
                                            </Grid>
                                            <Grid item sx={{ mr: "2rem" }}>
                                                <FilterSingleOption
                                                    isRequired
                                                    filterOptions={categoriaOptions}
                                                    selectedOption={selectedCategoria}
                                                    setSelectedOption={setSelectedCategoria}
                                                    placeholder="Categoria de despesa"
                                                    canCreate
                                                    setOpenCreateModal={setOpenCreateModal}
                                                    setOptionCreateModal={setOptionCreateModal}
                                                    testId="categoria-input"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FilterDayMonthYear
                                                    isRequired
                                                    gerarCompra
                                                    style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                                    handleSubmit={handleDayMonthYear}
                                                    value={selectedDataCompra}
                                                    setState={setSelectedDataCompra}
                                                    size={"small"}
                                                    width={"250px"}
                                                    iconPosition={"end"}
                                                    label={"Data da compra"}
                                                    testId="data-compra-input"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Checkbox
                                                    disabled={notaId !== null}
                                                    checked={repetirCompra}
                                                    icon={<CircleOutlinedIcon />}
                                                    checkedIcon={<CircleIcon />}
                                                    onChange={handleRepetirCompra}
                                                />
                                                <a style={{
                                                    ...styleText,
                                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                }}>Repetir compra</a>
                                            </Grid>
                                            <Grid item>
                                                <Checkbox
                                                    checked={cadastrarProduto}
                                                    icon={<CircleOutlinedIcon />}
                                                    checkedIcon={<CircleIcon />}
                                                    onChange={(e) => setCadastrarProduto(e.target.checked)}
                                                />
                                                <a style={{
                                                    ...styleText,
                                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                }}>Cadastrar produto/serviço</a>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        repetirCompra && (
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <a>Configurações da recorrência</a>
                                                </Grid>
                                                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                                    <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InputNumber
                                                                isRequired
                                                                value={qtdRecorrenciaCompra}
                                                                setValue={setQtdRecorrenciaCompra}
                                                                label="Repetir compra a cada"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                isRequired
                                                                filterOptions={formaRecorrenciaOptions}
                                                                selectedOption={formaRecorrenciaCompra}
                                                                setSelectedOption={setFormaRecorrenciaCompra}
                                                                placeholder="Forma de recorrência"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterDayMonthYear
                                                                style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                                                handleSubmit={handleDayMonthYear}
                                                                value={selectedDataCompraRecorrencia}
                                                                setState={setSelectedDataCompraRecorrencia}
                                                                disableMinDate
                                                                size={"small"}
                                                                width={"250px"}
                                                                iconPosition={"end"}
                                                                label={"Data de criação da compra"}
                                                                isRequired
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                isRequired
                                                                filterOptions={recorrenciaVencimentoOptions}
                                                                selectedOption={selectedRecorrenciaVencimento}
                                                                setSelectedOption={setSelectedRecorrenciaVencimento}
                                                                placeholder={"Vencimento"}
                                                                disabled={["Ano/anos", "Dia/dias"].includes(formaRecorrenciaCompra) ? true : false}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                isRequired
                                                                filterOptions={recorrenciaTerminoOptions}
                                                                selectedOption={selectedRecorrenciaTermino}
                                                                setSelectedOption={setSelectedRecorrenciaTermino}
                                                                placeholder={"Término da recorrência"}
                                                            />
                                                        </Grid>
                                                        {
                                                            selectedRecorrenciaTermino === "Em um período específico" && (
                                                                <Grid item>
                                                                    <FilterDayMonthYear
                                                                        style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                                                        handleSubmit={handleDayMonthYear}
                                                                        value={selectedDataRecorrenciaTermino}
                                                                        setState={setSelectedDataRecorrenciaTermino}
                                                                        size={"small"}
                                                                        width={"250px"}
                                                                        iconPosition={"end"}
                                                                        label={"Data de término"}
                                                                        isRequired
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                        {
                                                            selectedRecorrenciaTermino === "Após alguns ocorrências" && (
                                                                <Grid item>
                                                                    <InputNumber
                                                                        value={numeroRecorrenciaCompra}
                                                                        setValue={setNumeroRecorrenciaCompra}
                                                                        label="Número de ocorrências"
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                    {cadastrarProduto && (
                                        <React.Fragment>
                                            <Grid item xs={12} sx={{ marginTop: 2 }}>
                                                <span>
                                                    <p className="title-container-p">Itens da compra</p>
                                                </span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer className={"table-container"}>
                                                    <Table>
                                                        <TableHead style={{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF }}>
                                                            <TableRow>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{
                                                                        ...{ zIndex: 3 },
                                                                        ...{ fontWeight: "bold" },
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF },
                                                                        ...{ color: temaEscuro && LINE_TABLE },
                                                                        ...{ padding: 5 }
                                                                    }}
                                                                >
                                                                    Produto/Serviço *
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{
                                                                        ...{ zIndex: 3 },
                                                                        ...{ fontWeight: "bold" },
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF },
                                                                        ...{ color: temaEscuro && LINE_TABLE },
                                                                        ...{ padding: 5 }
                                                                    }}
                                                                >
                                                                    Detalhes do item
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{
                                                                        ...{ zIndex: 3 },
                                                                        ...{ fontWeight: "bold" },
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF },
                                                                        ...{ color: temaEscuro && LINE_TABLE },
                                                                        ...{ padding: 5 }
                                                                    }}
                                                                >
                                                                    Quantidade *
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{
                                                                        ...{ zIndex: 3 },
                                                                        ...{ fontWeight: "bold" },
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF },
                                                                        ...{ color: temaEscuro && LINE_TABLE },
                                                                        ...{ padding: 5 }
                                                                    }}
                                                                >
                                                                    Valor unitário *
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{
                                                                        ...{ zIndex: 3 },
                                                                        ...{ fontWeight: "bold" },
                                                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF },
                                                                        ...{ color: temaEscuro && LINE_TABLE },
                                                                        ...{ padding: 5 }
                                                                    }}
                                                                >
                                                                    Total *
                                                                </TableCell>
                                                                {
                                                                    items.length > 1 && (
                                                                        <TableCell />
                                                                    )
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                items.map((item, idx) => {
                                                                    return (
                                                                        <TableRow key={idx} style={{ backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE }}>
                                                                            <TableCell align={"center"}>
                                                                                <FilterSingleOption
                                                                                    filterOptions={produtoOptions}
                                                                                    selectedOption={item.produto_servico}
                                                                                    setSelectedOption={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                        if (idx_aux === idx) {
                                                                                            item_aux.produto_servico = value;
                                                                                        }
                                                                                        return item_aux;
                                                                                    })))}
                                                                                    placeholder="Produto/Serviço"
                                                                                    canCreate
                                                                                    setOpenCreateModal={setOpenCreateModal}
                                                                                    setOptionCreateModal={setOptionCreateModal}
                                                                                    justifyContent={"center"}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Tooltip
                                                                                    title={item.detalhes_item.length > 250 && "Máximo de 250 caracteres!"}
                                                                                    open={item.detalhes_item.length > 250}
                                                                                    arrow
                                                                                >
                                                                                    <span>
                                                                                        <InputSearch
                                                                                            functionProps={() => {}}
                                                                                            setSearchValue={(value) => {
                                                                                                setItems(items.map((item_aux, idx_aux) => {
                                                                                                    if (idx_aux === idx) {
                                                                                                        item_aux.detalhes_item = value;
                                                                                                    }
                                                                                                    return item_aux;
                                                                                                }));
                                                                                            }}
                                                                                            searchValue={item.detalhes_item}
                                                                                            className="input-text"
                                                                                            padding={0.5}
                                                                                            width="200px"
                                                                                            label="Detalhes"
                                                                                            error={item.detalhes_item.length > 250}
                                                                                        />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                            <TableCell align={"center"}>
                                                                                <InputNumber
                                                                                    value={item.quantidade}
                                                                                    setValue={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                        if (idx_aux === idx) {
                                                                                            item_aux.quantidade = parseFloat(value);
                                                                                        }
                                                                                        return item_aux;
                                                                                    })))}
                                                                                    width={"100px"}
                                                                                    padding={0.5}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align={"center"}>
                                                                                <InputCurrency
                                                                                    valor={item.valor_unitario}
                                                                                    setValor={(value) => (setItems(items.map((item_aux, idx_aux) => {
                                                                                        if (idx_aux === idx) {
                                                                                            item_aux.valor_unitario = parseFloat(value);
                                                                                        }
                                                                                        return item_aux;
                                                                                    })))}
                                                                                    width={"125px"}
                                                                                    ml={"15%"}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align={"center"}>
                                                                                <InputCurrency
                                                                                    disabled
                                                                                    valor={new Decimal(item.quantidade).times(item.valor_unitario).toDecimalPlaces(2).toNumber()}
                                                                                    setValor={null}
                                                                                    width={"150px"}
                                                                                    padding={0.5}
                                                                                    ml={"15%"}
                                                                                />

                                                                            </TableCell>
                                                                            {
                                                                                items.length > 1 && (
                                                                                    <TableCell
                                                                                        align={"center"}
                                                                                        style={{ padding: 0, cursor: "pointer" }}
                                                                                        onClick={() => handleRemoveRow(idx)}
                                                                                    >
                                                                                        <a>X</a>
                                                                                    </TableCell>
                                                                                )
                                                                            }
                                                                        </TableRow>
                                                                    );
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DefaultButton
                                                    title={"+ Adicionar nova linha"}
                                                    size={"small"}
                                                    onClick={() => {
                                                        setItems([...items, {
                                                            produto_servico: "",
                                                            detalhes_item: "",
                                                            quantidade: 1,
                                                            valor_unitario: null,
                                                            total: null
                                                        }]);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <Grid container spacing={1}
                                                            sx={{ display: "flex" }}>
                                                            <Grid item>
                                                                <ButtonGroup variant="contained"
                                                                    aria-label="Basic button group">
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={selectedReaisPercent === "reais" ? styles.selectedButton : styles.unselectedButton}
                                                                        onClick={() => setSelectedReaisPercent("reais")}
                                                                    >
                                                                        R$
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={selectedReaisPercent === "percent" ? styles.selectedButton : styles.unselectedButton}
                                                                        onClick={() => setSelectedReaisPercent("percent")}
                                                                    >
                                                                        %
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Grid>
                                                            <Grid item>
                                                                {selectedReaisPercent === "reais" ? (
                                                                    <InputCurrency
                                                                        width="250px"
                                                                        valor={descontoInput}
                                                                        setValor={setDescontoInput}
                                                                        title={"Desconto"}
                                                                        required={false}
                                                                    />
                                                                ) : (
                                                                    <InputNumber
                                                                        width="250px"
                                                                        label={"Desconto"}
                                                                        value={descontoInput}
                                                                        setValue={setDescontoInput}
                                                                    />
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{ margin: 1 }}>
                                                        <Divider orientation="vertical" sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InputCurrency
                                                                    width="250px"
                                                                    valor={seguro}
                                                                    setValor={setSeguro}
                                                                    title={"Seguro (R$)"}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{ margin: 1 }}>
                                                        <Divider orientation="vertical" sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InputCurrency
                                                                    width="250px"
                                                                    valor={impostos}
                                                                    setValor={setImpostos}
                                                                    title={"Impostos (R$)"}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{ margin: 1 }}>
                                                        <Divider orientation="vertical" sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <InputCurrency
                                                                    width="250px"
                                                                    valor={frete}
                                                                    setValor={setFrete}
                                                                    title={"Frete (R$)"}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    ...paperStyle,
                                                    backgroundColor: temaEscuro && BLUE_THEME,
                                                    borderRadius: 2
                                                }}>
                                                    <Grid container spacing={0.5} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Grid item xs={3}>
                                                            <a>Total da compra</a>
                                                        </Grid>
                                                        <Grid item sx={{ display: "flex" }}>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                        sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>Total dos produtos (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                        sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>{totalProdutos}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ ml: 3.5 }}>
                                                                <b>-</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>Descontos (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>
                                                                            {formatterThousand(desconto)}
                                                                        </a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ ml: 3.5 }}>
                                                                <b>+</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                        sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>Custos adicionais (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                        sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>{formatterThousand(custosAdicionais)}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sx={{ ml: 3.5 }}>
                                                                <b>=</b>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container rowSpacing={1}>
                                                                    <Grid item xs={12}
                                                                        sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>Total (R$)</a>
                                                                    </Grid>
                                                                    <Grid item xs={12}
                                                                        sx={{ display: "flex", justifyContent: "end" }}>
                                                                        <a>{new Decimal(totalProdutos).minus(desconto).plus(custosAdicionais).toDecimalPlaces(2).toNumber()}</a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                                        <span>
                                            <p className="title-container-p">Informações de pagamento</p>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <FilterSingleOption
                                                    testId="forma-pagamento-input"
                                                    isRequired={repetirCompra}
                                                    filterOptions={formaPagamentoOptions}
                                                    selectedOption={selectedFormaPagamento}
                                                    setSelectedOption={setSelectedFormaPagamento}
                                                    placeholder="Forma de pagamento"
                                                    disabled={repetirCompra}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <InputCurrency
                                                    valor={new Decimal(totalProdutos).minus(desconto).plus(custosAdicionais).toDecimalPlaces(2).toNumber()}
                                                    setValor={setTotalProdutos}
                                                    title={"Valor a pagar (R$)"}
                                                    disabled={cadastrarProduto}
                                                    dataTestId="valor-pagar-input"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FilterSingleOption
                                                    isRequired={!repetirCompra}
                                                    filterOptions={parcelaOptions}
                                                    selectedOption={selectedParcela}
                                                    setSelectedOption={setSelectedParcela}
                                                    placeholder="Condição de pagamento"
                                                    disabled={repetirCompra}
                                                    testId="condicao-pagamento-input"
                                                />
                                            </Grid>
                                            {
                                                parcelas.length > 1 && (
                                                    <Grid item>
                                                        <InputNumber
                                                            value={intervaloParcelas}
                                                            setValue={setIntervaloParcelas}
                                                            width={"250px"}
                                                            label="Intervalo entre parcelas (dias)"
                                                        />
                                                    </Grid>
                                                )
                                            }
                                            <Grid item>
                                                <FilterDayMonthYear
                                                    isRequired={!repetirCompra}
                                                    style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                                                    handleSubmit={handleDayMonthYear}
                                                    size={"small"}
                                                    width={"250px"}
                                                    iconPosition={"end"}
                                                    label={"Vencimento"}
                                                    setState={(data) => {
                                                        setParcelaDataVencimento(data);
                                                    }}
                                                    value={parcelaDataVencimento}
                                                    disabled={repetirCompra}
                                                    gerarCompra
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FilterSingleOption
                                                    isRequired
                                                    filterOptions={contasOptions}
                                                    selectedOption={selectedConta}
                                                    setSelectedOption={setSelectedConta}
                                                    placeholder={"Conta de pagamento"}
                                                    testId="conta-pagamento-input"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <DefaultButton
                                                    title={"Editar parcelas"}
                                                    onClick={() => setOpenParcelasModal(true)}
                                                    disabled={selectedParcela <= 1 || repetirCompra}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                                        <span>
                                            <p className="title-container-p">Descrição</p>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{ backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            data-testid="descricao-input"
                                            required
                                            label={"Descrição"}
                                            id="input-observacoes-textfield"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            value={descricao}
                                            onChange={(event) => {
                                                setDescricao(event.target.value);
                                            }}
                                            sx={{
                                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                "& .MuiFormLabel-asterisk": {
                                                    color: RED_INFO,
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ backgroundColor: "transparent", marginBottom: "15px", marginTop: 3 }}>
                                        <Tabs
                                            value={selectedTab}
                                            onChange={(e, value) => setSelectedTab(value)}
                                            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
                                            className="buttonTabs"
                                        >
                                            <Tab
                                                value={"observacoes"}
                                                label="Observações"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                    backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                        color: BLACK_LABEL_UX,
                                                    },
                                                }}
                                                data-testid="observacoes-tab"
                                            />
                                            <Tab
                                                value={"anexo"}
                                                label="Anexo"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                    backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                        color: BLACK_LABEL_UX,
                                                    },
                                                }}
                                            />
                                        </Tabs>
                                    </Grid>
                                    {selectedTab === "observacoes" ? (
                                        <Grid item xs={12}>
                                            <TextField
                                                label={"Observações"}
                                                id="input-observacoes-textfield"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                maxRows={6}
                                                value={observacoes}
                                                onChange={(event) => {
                                                    setObservacoes(event.target.value);
                                                }}
                                                sx={{ backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX }}
                                            />
                                        </Grid>
                                    ) : (
                                        <Anexos
                                            anexos={anexos}
                                            setAnexos={setAnexos}
                                        />
                                    )}

                                </Grid>
                            )
                        }
                    </Paper>
                </div>
                <AppBar
                    position="fixed"
                    sx={{
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        borderTop: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        top: "auto",
                        bottom: 0,
                        padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    }}
                >
                    <Toolbar
                        sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <DefaultButton
                            title="voltar"
                            testId="close-dialog"
                            onClick={handleCloseDialog}
                        />
                        <Grid item sx={{ display: "flex" }}>
                            <DefaultButton
                                title={"Salvar"}
                                onClick={() => postSaveCompra()}
                                disabled={checkDisabled()}
                                testId="save-button"
                                borderRadius={{ borderRadius: "5px 0 0 5px" }}

                                variant="yellow"
                            />
                            <SaveOptions
                                disabled={checkDisabled()}
                                action={postSaveCompra}
                                cleanStates={cleanCompraInputs}
                            />
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Dialog>
        </React.Fragment>
    );
};

export default GerarCompra;